const Languages = [
    {value : "Tamil", label : "Tamil"},
    {value : "Telugu", label : "Telugu"},
    {value : "Malayalam", label : "Malayalam"},
    {value : "Kannada", label : "Kannada"},
    {value : "Arabic", label : "Arabic"},
    {value : "English", label : "English"},
    {value : "French", label : "French"},
]

export default Languages