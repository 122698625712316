import React from 'react'

const UserCardOuter = ({children}) => {
  return (
    <div className="h-full rounded-lg bg-white shadow">
          <div className="px-4 py-5 h-full flex flex-wrap justify-around overflow-y-scroll sm:p-6 bg-gray-50">  
            {children}
          </div>
    </div>
  )
}

export default UserCardOuter