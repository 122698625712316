import React, { useState } from 'react'
import DropdownSelect from '../DropdownSelect'
import CurrencyInput from '../../CurrencyInput'
import axios from 'axios'

const EditMeetingConf = (props) => {
  const hours =  [
    {id: "1",value: "1",label:"1 Hour"},
    {id: "2",value: "2",label:"2 Hour"},
    {id: "3",value: "3",label:"3 Hour"},
  ]
  const mins =  [    
    {id: "1",value: "0" ,label: "0 Minutes"},
    {id: "2",value: "15",label:"15 Minutes"},
    {id: "3",value: "30",label:"30 Minutes"},
    {id: "4",value: "45",label:"45 Minutes"},
  ]
  const [selectedHours, setSelectedHours] = useState(hours[0])
  const [selectedMinutes, setSelectedMinutes] = useState(mins[0])
  const [currencyValue, setCurrencyValue] = useState(0)

  const handleSave = async () => {
    const meetingName = document.getElementById('project-name').value
    const description = document.getElementById('description').value
    const questions = document.getElementById('questions').value
    
    let currMeetingDetails = props.meetingDtls ? Object.values(props.meetingDtls).filter(
      meeting => meeting.MeetingName === props.currentEditForm
    )[0] : null;

    if (!meetingName.trim()) {
      alert('Meeting name is required')
      return
    }
    
    if (!description.trim()) {
      alert('Description is required')
      return
    }

    if (!questions.trim()) {
      alert('Questions are required')
      return
    }

    try {
      let type = currMeetingDetails?.ROWID ? "edit" : "add"
      const meetingData = {
        meetingName: meetingName,
        description: description,
        questions: questions,
        duration: {
          hours: selectedHours.value,
          minutes: selectedMinutes.value
        },
        amount: currencyValue === 0 ? (currMeetingDetails?.Amount || 0) : currencyValue,
        type: type,
        ROWID: currMeetingDetails?.ROWID || ""
      }

      const response = await axios.get('/server/MentorActions/saveMeetingConf', { params: meetingData })
      if (response.status === 200) {
        props.setMeetingDtls(null)
        props.setIsOpen(false)
      }
    } catch (error) {
      console.error('Error saving meeting configuration:', error)
      alert('Failed to save meeting configuration')
    }
  }



  let currMeetingDetails = props.meetingDtls ? Object.values(props.meetingDtls).filter(
    meeting => meeting.MeetingName === props.currentEditForm
  )[0] : null;
console.log("printing Meeting Details......", props.meetingDtls)
  debugger//mmmmm
  return (
    props.meetingDtls ?
    <div className="space-y-6">
        <p className="flex h-10 items-center justify-center bg-indigo-600 px-4 text-sm font-medium text-white sm:px-6 lg:px-8">
          Meeting Configuration
        </p>
        <div>
            <label htmlFor="project-name" className="block text-sm/6 font-medium text-gray-900">
                Meeting Name
            </label>
            <div className="mt-2">
                <input
                defaultValue={(currMeetingDetails?.MeetingName) || ""}
                id="project-name"
                name="project-name"
                type="text"
                className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-sky-600 sm:text-sm/6"
                />
            </div>
        </div>
        <div>
            <label htmlFor="description" className="block text-sm/6 font-medium text-gray-900">
                Description
            </label>
            <div className="mt-2">
                <textarea
                id="description"
                name="description"
                rows={3}
                className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-sky-600 sm:text-sm/6"
                defaultValue={currMeetingDetails?.Description || ""}
                />
            </div>
        </div>
        <div>
            <label className="block text-sm/6 font-medium text-gray-900">
                Questions to be asked
            </label>
            <div className="mt-2">
                <input
                id="questions"
                name="questions"
                type="text"
                defaultValue={currMeetingDetails?.Questions || ""}
                className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-sky-600 sm:text-sm/6"
                placeholder="Eg: What's stopping you from confidently moving forward in your chosen field?"
                />
            </div>
        </div>
        <div>
            <label className="block text-sm/6 font-medium text-gray-900">
                Durations
            </label>
            <div className="flex mt-2 ">
                <div className=" w-36">
                    <DropdownSelect 
                      options={hours} 
                      defaultValue={{ id: "2", label: "1 Hour", value: 1 }} 
                      onChangeCallback={(newVal) => setSelectedHours(newVal)}
                    />
                </div>
                <div className="ml-2 w-36">
                    <DropdownSelect 
                      options={mins} 
                      defaultValue={{ id: "1", label: "0 Minutes", value: 0 }} 
                      onChangeCallback={(newVal) => setSelectedMinutes(newVal)}
                    />
                </div>
            </div>
        </div>
        <div>
            <label className="block text-sm/6 font-medium text-gray-900">
                Currency
            </label>
            <div className="flex mt-2 ">
                <CurrencyInput 
                  onChange={(value) => {
                    debugger;
                    setCurrencyValue(value);
                  }}
                  defaultValue={currMeetingDetails?.Amount}
                />
            </div>
        </div>
        <div className="mt-6 flex items-center justify-end gap-x-6">
            <button type="button" onClick={handleSave}
              className="inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
              Save
            </button>
        </div>
    </div>
    : <></>
  )
}

export default EditMeetingConf
