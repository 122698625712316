import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import HomeFooter from './HomeFooter';
function TermsAndConditions() {
  const [activeSection, setActiveSection] = useState('introduction');

  // Define sections and their IDs with bullets - Fixed IDs to match exactly
  const sections = [
    { id: 'introduction', label: 'Introduction' },
    { id: 'definitions', label: '• Definitions' },
    { id: 'service', label: '• Our Service' },
    { id: 'accounts', label: '• Accounts and Registration' },
    { id: 'payment', label: '• Payment and Fees' },
    { id: 'responsibilities', label: '• Your Responsibilities' },
    { id: 'data', label: '• User Data' },
    { id: 'intellectual-property', label: '• Intellectual Property Rights' },
    { id: 'third-party', label: '• Third-Party Services' }
  ];

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const visibleSection = entries.find((entry) => entry.isIntersecting);
        if (visibleSection) {
          setActiveSection(visibleSection.target.id);
        }
      },
      {
        rootMargin: '-10% 0px -80% 0px',
        threshold: 0.1
      }
    );

    const sectionElements = document.querySelectorAll('section[id]');
    sectionElements.forEach((element) => observer.observe(element));

    return () => {
      sectionElements.forEach((element) => observer.unobserve(element));
    };
  }, []);

  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      const offset = 100;
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
  };

  return (
    <div className="h-screen flex flex-col overflow-y-scroll scrollbar-hide">
    <div className="bg-gray-50 z-0 h-full flex flex-col ">
      {/* Navigation Sidebar */}
      <div className="fixed inset-y-0 left-0 w-64 bg-white border-r border-gray-200 overflow-y-auto">
        <nav className="p-6 space-y-2">
          <Link to="/app/privacy" className="block text-gray-600 hover:text-indigo-600">
            Privacy Policy
          </Link>
          <div className="text-indigo-600 font-medium">Terms of Service</div>
          
          {/* Section Links */}
          <div className="mt-6 space-y-3">
            {sections.map((section) => (
              <button
                key={section.id}
                onClick={() => console.log(section.id)}
                className={`block w-full text-left text-sm py-1 px-2 rounded transition-colors duration-200 ${
                  activeSection === section.id
                    ? 'text-indigo-600 font-medium bg-indigo-50'
                    : 'text-gray-600 hover:text-indigo-600 hover:bg-gray-50'
                }`}
              >
                {section.label}
              </button>
            ))}
          </div>
        </nav>
      </div>

      {/* Main Content + Footer Container */}
      <div className="h-full overflow-y-scroll scrollbar-hide ml-64">
        <div className="px-8 py-12 max-w-4xl">
          <h1 className="text-4xl font-bold text-gray-900">Terms and Conditions</h1>
          <p className="mt-4 text-gray-600">Last updated: {new Date().toLocaleDateString()}</p>

          <div className="mt-8 space-y-8">
            <section id="introduction" className="prose max-w-none min-h-[100px]">
              <p className="text-lg text-gray-700">
                Welcome to [Your Website Name]. These Terms and Conditions ("Terms") govern your use of our website and services. By accessing or using our services, you agree to be bound by these Terms. Please read them carefully.
              </p>
            </section>

            <section id="definitions" className="prose max-w-none min-h-[100px]">
              <h3 className="mt-3 text-lg/6 font-semibold text-gray-900 group-hover:text-gray-600">Definitions</h3>
              <ul className="list-disc pl-6 text-gray-600 space-y-2 mt-5 text-sm/6">
                <li><strong>"We," "Us," "Our":</strong> Refers to [Your Website Name] and its operators.</li>
                <li><strong>"Service(s)":</strong> Refers to the services provided by [Your Website Name], including access to the website, resources, and tools.</li>
                <li><strong>"User," "You," "Your":</strong> Refers to any individual or entity accessing or using our services.</li>
                <li><strong>"Content":</strong> Refers to all materials, information, and data provided on the website or through the services.</li>
              </ul>
              
            </section>

            <section id="service" className="prose max-w-none min-h-[100px]">
              <h3 className="mt-3 text-lg/6 font-semibold text-gray-900 group-hover:text-gray-600">Our Service</h3>
              <p className="text-gray-600">
                [Your Website Name] provides [describe your services, e.g., educational content, expert consultations, document sharing, etc.]. We reserve the right to modify, suspend, or discontinue any part of our services without prior notice.
              </p>
            </section>

            <section id="accounts" className="prose max-w-none min-h-[100px]">
              <h3 className="mt-3 text-lg/6 font-semibold text-gray-900 group-hover:text-gray-600">Accounts and Registration</h3>
              <ul className="list-disc pl-6 space-y-2 mt-5 text-sm/6 text-gray-600">
                <li>To access certain features, you may need to create an account.</li>
                <li>You are responsible for maintaining the confidentiality of your account credentials and for all activities under your account.</li>
                <li>We reserve the right to terminate accounts that violate these Terms or engage in unauthorized activities.</li>
              </ul>
            </section>

            <section id="payment" className="prose max-w-none min-h-[100px]">
                <h3 className="mt-3 text-lg/6 font-semibold text-gray-900 group-hover:text-gray-600">Payment and Fees</h3>
              <ul className="list-disc pl-6 text-gray-600 space-y-2 mt-5 text-sm/6">
                <li>Certain services may require payment. Fees and pricing details are available on our website.</li>
                <li>Payments are non-refundable unless explicitly stated otherwise.</li>
                <li>We reserve the right to modify pricing at any time, with notice provided before any changes take effect.</li>
              </ul>
            </section>

            <section id="responsibilities" className="prose max-w-none min-h-[100px]">
              <h3 className="mt-3 text-lg/6 font-semibold text-gray-900 group-hover:text-gray-600">Your Responsibilities</h3>
              <p className="text-gray-700">As a user, you agree to:</p>
              <ul className="list-disc pl-6 text-gray-600 space-y-2 mt-5 text-sm/6 ">
                <li>Provide accurate and up-to-date information during registration and use of the services.</li>
                <li>Use the services in compliance with applicable laws and these Terms.</li>
                <li>Avoid engaging in activities that disrupt or harm the website or other users.</li>
              </ul>
            </section>

            <section id="data" className="prose max-w-none min-h-[100px]">
              <h3 className="mt-3 text-lg/6 font-semibold text-gray-900 group-hover:text-gray-600">User Data</h3>
              <ul className="list-disc pl-6 text-gray-600 space-y-2 mt-5 text-sm/6 ">
                <li>We respect your privacy and handle your data as described in our Privacy Policy.</li>
                <li>You retain ownership of any content or data you upload to our services. However, by uploading, you grant us a limited license to use, store, and process this data for the purposes of providing our services.</li>
              </ul>
            </section>

            <section id="intellectual-property" className="prose max-w-none min-h-[100px]">
              <h3 className="mt-3 text-lg/6 font-semibold text-gray-900 group-hover:text-gray-600">Intellectual Property Rights</h3>
              <ul className="list-disc pl-6 space-y-2 mt-5 text-sm/6 text-gray-600">
                <li>All intellectual property rights related to the website and services belong to [Your Website Name].</li>
                <li>You may not reproduce, distribute, or create derivative works from our content without prior written consent.</li>
                <li>User-generated content remains the property of the respective users, subject to the limited license granted to us.</li>
              </ul>
            </section>

            <section id="third-party" className="prose max-w-none min-h-[100px]">
              <h3 className="mt-3 text-lg/6 font-semibold text-gray-900 group-hover:text-gray-600">Third-Party Services</h3>
              <ul className="list-disc pl-6 space-y-2 mt-5 text-sm/6 text-gray-600">
                <li>Our website may include links to third-party websites or services. We are not responsible for their content, policies, or practices.</li>
                <li>Your interactions with third-party services are governed by their respective terms and conditions.</li>
              </ul>
              <p className="mt-6 text-gray-700">
                By using our services, you acknowledge that you have read, understood, and agree to these Terms. If you have any questions or concerns, please contact us at [Your Contact Information].
              </p>
            </section>
          </div>

        </div>
     
      </div>
    </div>
        <div className="z-10 mt-8">
            <HomeFooter />
        </div>
    </div>
  );
}

export default TermsAndConditions; 
