import React from 'react'

const PhoneInputWithCode = (props) => {

    const onChangeCallback = (e) =>{
        props.onChangeCallback(e.currentTarget.value)
    }

  return (
    <div>
        <div className="relative mt-2 rounded-md shadow-sm">
            <div className="absolute inset-y-0 left-0 flex items-center">
                <label htmlFor="country" className="sr-only">
                    Country
                </label>
                <div 
                className="h-full item-center rounded-md border-0 bg-transparent text-center p-2 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm">
                    {props.PhnCode!=""?<>{props.PhnCode}</>: <>&nbsp;&nbsp;&nbsp;--</>}
                </div>
            </div>
            <input 
                id="phone-number" 
                name="phone-number" 
                type="number"
                onChange={(e)=>{onChangeCallback(e)}} 
                placeholder="Phone Number"
                className="block w-full  rounded-md border-0 py-1.5 pl-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"/>
        </div>
  </div>
  )
}

export default PhoneInputWithCode