'use client'

import { useState } from 'react'
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
} from '@headlessui/react'
import {
  Bars3Icon,
  BellIcon,
  CalendarIcon,
  ChartPieIcon,
  Cog6ToothIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  HomeIcon,
  UsersIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import { ChevronDownIcon, MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import MainContent from './MainContent'
import { useNavigate } from 'react-router-dom'
import { AiOutlineSchedule } from 'react-icons/ai'

const navigation = [
  { name: 'Dashboard', path:"dashboard", href: '/app/home/dashboard', icon: HomeIcon, current: true },
  { name: 'Bookings', path:"mentorBookings", href: '/app/mentor/mentorBookings', icon: AiOutlineSchedule, current: false },
  { name: 'Documents', path:"documents", href: '/app/mentor/documents', icon: DocumentDuplicateIcon, current: false },
  { name: 'Profile', path:"mentorProfile", href: '/app/mentor/mentorProfile', icon: ChartPieIcon, current: false },
]
const userNavigation = [
  { name: 'Your profile', href: '#' },
  { name: 'Sign out', href: '#' },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export function MentorLanding(props) {
  const nav = useNavigate()
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const needSearchBar = false;
  const getCurrentPage = (path)=>{
    if(path.includes("upcomingSessions")){
        return "upcomingSessions"
    }else if(path.includes("mentorProfile")){
        return "mentorProfile"
    }else if(path.includes("documents")){
        return "documents"
    }else if(path.includes("dashboard")){
        return "dashboard"
    }else if(path.includes("mentorBookings")){
        return "mentorBookings"
    }
  }

  const path = getCurrentPage(window.location.pathname)
  console.log("path", path)
  return (
    <>
      <div>
        <Dialog open={sidebarOpen} onClose={setSidebarOpen} className="relative  lg:hidden">
          <DialogBackdrop transition className="fixed inset-0 bg-gray-900/80 transition-opacity duration-300 ease-linear data-[closed]:opacity-0"/>
          <div className="fixed inset-0 flex">
            <DialogPanel transition className="relative mr-16 flex w-full max-w-xs flex-1 transform transition duration-300 ease-in-out data-[closed]:-translate-x-full">
              <TransitionChild>
                <div className="absolute left-full top-0 flex w-16 justify-center pt-5 duration-300 ease-in-out data-[closed]:opacity-0">
                  <button type="button" onClick={() => setSidebarOpen(false)} className="-m-2.5 p-2.5">
                    <span className="sr-only">Close sidebar</span>
                    <XMarkIcon aria-hidden="true" className="h-6 w-6 text-white" />
                  </button>
                </div>
              </TransitionChild>
              {/* Sidebar component, swap this element with another sidebar if you like */}
              <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4">
                <div className="flex h-16 shrink-0 items-center">
                  <img alt="Your Company" src={process.env.PUBLIC_URL+"/Assets/logo1.png"} className="h-8 w-auto"/>
                </div>
                <nav className="flex flex-1 flex-col">
                  <ul role="list" className="flex flex-1 flex-col gap-y-7">
                    <li>
                      <ul role="list" className="-mx-2 space-y-1">
                        {navigation.map((item) => (
                          <li key={item.name}>
                            <a href={item.href}  className={classNames(item.path==path? 'bg-gray-50 text-indigo-600': 'text-gray-700 hover:bg-gray-50 hover:text-indigo-600','group flex gap-x-3 rounded-md p-2 text-sm/6 font-semibold',)}>
                          <item.icon aria-hidden="true" className={classNames(item.path==path ? 'text-indigo-600' : 'text-gray-400 group-hover:text-indigo-600','h-6 w-6 shrink-0',)}/>
                          {item.name}
                        </a>
                          </li>
                        ))}
                      </ul>
                    </li>
                    <li className="mt-auto">
                      <a href="#" className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm/6 font-semibold text-gray-700 hover:bg-gray-50 hover:text-indigo-600">
                        <Cog6ToothIcon aria-hidden="true" className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-indigo-600"/>
                        Settings
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </DialogPanel>
          </div>
        </Dialog>

        {/* Static sidebar for desktop */}
        {/* removed lg:z-50  */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-72 lg:flex-col"> 
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6 mt-10 pb-4">
            <div className="flex px-11 py-4 h-24 shrink-0 items-center">
              <img
                alt="Your Company"
                src={process.env.PUBLIC_URL+"/Assets/MM.jpg"}
                className="h-24 w-auto"
              />
            </div>
            <nav className="flex flex-1 flex-col">
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul role="list" className="-mx-2 space-y-1">
                    {navigation.map((item) => (
                      <li key={item.name}>
                        <a  onClick={()=>{nav(item.href)}} className={classNames(item.path==path? 'bg-gray-50 text-indigo-600': 'text-gray-700 hover:bg-gray-50 hover:text-indigo-600','group flex gap-x-3 rounded-md p-2 text-sm/6 font-semibold',)}>
                          <item.icon aria-hidden="true" className={classNames(item.path==path ? 'text-indigo-600' : 'text-gray-400 group-hover:text-indigo-600','h-6 w-6 shrink-0',)}/>
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </li>
                <li className="mt-auto">
                  <a href="#" className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm/6 font-semibold text-gray-700 hover:bg-gray-50 hover:text-indigo-600">
                    <Cog6ToothIcon aria-hidden="true" className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-indigo-600"/>
                    Settings
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className="lg:pl-72">
          <div className="sticky top-0  flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
            <button type="button" onClick={() => setSidebarOpen(true)} className="-m-2.5 p-2.5 text-gray-700 lg:hidden">
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon aria-hidden="true" className="h-6 w-6" />
            </button>

            {/* Separator */}
            <div aria-hidden="true" className="h-6 w-px bg-gray-200 lg:hidden" />

            <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
               <form action="#" method="GET" className="relative flex flex-1">
                {needSearchBar==true ?<><label htmlFor="search-field" className="sr-only">
                  Search
                </label>
                <MagnifyingGlassIcon aria-hidden="true" className="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-400"/>
                <input id="search-field" name="search" type="search" placeholder="Search..." className="block h-full w-full border-0 py-0 pl-8 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"/></>:<></>} 
              </form> 
              <div className="flex ml-96 items-center gap-x-4 lg:gap-x-6">
                <button type="button" className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500">
                  <span className="sr-only">View notifications</span>
                  <BellIcon aria-hidden="true" className="h-6 w-6" />
                </button>

                {/* Separator */}
                <div aria-hidden="true" className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-200" />

                {/* Profile dropdown */}
                <Menu as="div" className="relative">
                  <MenuButton className="-m-1.5 flex items-center p-1.5">
                    <span className="sr-only">Open user menu</span>
                    <img alt="" src={process.env.PUBLIC_URL+"/Assets/propic3.jpeg"} className="h-8 w-8 rounded-full bg-gray-50"/>
                    <span className="hidden lg:flex lg:items-center">
                      <span aria-hidden="true" className="ml-4 text-sm/6 font-semibold text-gray-900">
                        Tom Cook
                      </span>
                      <ChevronDownIcon aria-hidden="true" className="ml-2 h-5 w-5 text-gray-400" />
                    </span>
                  </MenuButton>
                  <MenuItems transition className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in">
                    {userNavigation.map((item) => (
                      <MenuItem key={item.name}>
                        <a onClick={()=>{item.name=="Sign out"?window.catalyst.auth.signOut(window.origin):nav(item.href)}} className="block px-3 py-1 text-sm/6 text-gray-900 data-[focus]:bg-gray-50 data-[focus]:outline-none">
                          {item.name}
                        </a>
                      </MenuItem>
                    ))}
                  </MenuItems>
                </Menu>
              </div>
            </div>
          </div>
          <MainContent path={path}></MainContent>
        </div>
      </div>
    </>
  )
}

export default MentorLanding