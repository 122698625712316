import React from 'react'
import { useState } from 'react'
import { PlusIcon } from '@heroicons/react/20/solid'
import Subtab from '../Mentor/Subtab'
import BasicCard from '../BasicCard'
import MeetingDocuments from '../User/Documents/MeetingDocuments'
import SharedDocuments from '../User/Documents/SharedDocuments'
import MyDocuments from '../User/Documents/MyDocument'

const Documents = () => {
  const tabs = [
    { name: 'My Documents', type: 'MyDocuments', current: true },
    { name: 'Shared Documents', type: "SharedDocuments", current: false },
    { name: 'Meeting Documents', type: "MeetingDocuments", current: false },
  ]
  const [subTab, setSubTab] = useState("MyDocuments")

  const tabComponents = {
    MyDocuments: <MyDocuments />,
    SharedDocuments: <SharedDocuments />,
    MeetingDocuments: <MeetingDocuments />
  }

  return (
    <BasicCard>
      <Subtab subTab={subTab} tabs={tabs} setSubTab={setSubTab}>
        {tabComponents[subTab]}
      </Subtab>
    </BasicCard>
  )
}

export default Documents