import { create } from 'zustand';
import axios from 'axios';

const useUserStore = create((set) => ({
  userDetails: null,
  isLoading: false,
  error: null,

  fetchUserDetails: async () => {
    set({ isLoading: true });
    try {
      const response = await axios.get('/server/userActions/getAllUserDetails');
      set({ userDetails: response.data, error: null });
    } catch (error) {
      set({ error: error.message });
      console.error('Error fetching user details:', error);
    } finally {
      set({ isLoading: false });
    }
  },

  updateUserDetails: async (updatedDetails) => {
    set({ isLoading: true });
    try {
      // Validation
      if (!updatedDetails.FirstName?.trim() || !updatedDetails.LastName?.trim()) {
        throw new Error('First name and last name are required');
      }

      if (!updatedDetails.Email?.trim() || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(updatedDetails.Email)) {
        throw new Error('Please enter a valid email address');
      }

      if (!updatedDetails.Phone?.trim()) {
        throw new Error('Phone number is required');
      }

      const response = await axios.post('/server/userActions/updateUserDetails', updatedDetails);
      set({ userDetails: response.data, error: null });
      return true;
    } catch (error) {
      set({ error: error.message });
      throw error;
    } finally {
      set({ isLoading: false });
    }
  }
}));

export default useUserStore; 