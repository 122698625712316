import React, { useState, useEffect } from 'react'
import OuterCard from '../OuterCard'
import TimezonePicker from 'react-timezone';
import Hello from './Hello';
import { Radio, RadioGroup } from '@headlessui/react';
import TimePicker from '../TimePicker';
import axios, * as others from 'axios';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

var timeval = {
  "MON": [{ "time": '10:00 AM', "isChecked": true},{ "time": '11:00 AM', "isChecked": true},{ "time": '12:00 PM', "isChecked": true},{ "time": '1:00 PM', "isChecked": true},{ "time": '2:00 PM', "isChecked": true},{ "time": '3:00 PM', "isChecked": true},{ "time": '4:00 PM', "isChecked": true},{ "time": '5:00 PM', "isChecked": true},{ "time": '6:00 PM', "isChecked": true},{ "time": '7:00 PM', "isChecked": true},{ "time": '8:00 PM', "isChecked": true}],
  "TUE": [{ "time": '10:00 AM', "isChecked": true},{ "time": '11:00 AM', "isChecked": true},{ "time": '12:00 PM', "isChecked": true},{ "time": '1:00 PM', "isChecked": true},{ "time": '2:00 PM', "isChecked": true},{ "time": '3:00 PM', "isChecked": true},{ "time": '4:00 PM', "isChecked": true},{ "time": '5:00 PM', "isChecked": true},{ "time": '6:00 PM', "isChecked": true},{ "time": '7:00 PM', "isChecked": true},{ "time": '8:00 PM', "isChecked": true}],
  "WED": [{ "time": '10:00 AM', "isChecked": true},{ "time": '11:00 AM', "isChecked": true},{ "time": '12:00 PM', "isChecked": true},{ "time": '1:00 PM', "isChecked": true},{ "time": '2:00 PM', "isChecked": true},{ "time": '3:00 PM', "isChecked": true},{ "time": '4:00 PM', "isChecked": true},{ "time": '5:00 PM', "isChecked": true},{ "time": '6:00 PM', "isChecked": true},{ "time": '7:00 PM', "isChecked": true},{ "time": '8:00 PM', "isChecked": true}],
  "THU": [{ "time": '10:00 AM', "isChecked": true},{ "time": '11:00 AM', "isChecked": true},{ "time": '12:00 PM', "isChecked": true},{ "time": '1:00 PM', "isChecked": true},{ "time": '2:00 PM', "isChecked": true},{ "time": '3:00 PM', "isChecked": true},{ "time": '4:00 PM', "isChecked": true},{ "time": '5:00 PM', "isChecked": true},{ "time": '6:00 PM', "isChecked": true},{ "time": '7:00 PM', "isChecked": true},{ "time": '8:00 PM', "isChecked": true}],
  "FRI": [{ "time": '10:00 AM', "isChecked": true},{ "time": '11:00 AM', "isChecked": true},{ "time": '12:00 PM', "isChecked": true},{ "time": '1:00 PM', "isChecked": true},{ "time": '2:00 PM', "isChecked": true},{ "time": '3:00 PM', "isChecked": true},{ "time": '4:00 PM', "isChecked": true},{ "time": '5:00 PM', "isChecked": true},{ "time": '6:00 PM', "isChecked": true},{ "time": '7:00 PM', "isChecked": true},{ "time": '8:00 PM', "isChecked": true}],
  "SAT": [{ "time": '10:00 AM', "isChecked": true},{ "time": '11:00 AM', "isChecked": true},{ "time": '12:00 PM', "isChecked": true},{ "time": '1:00 PM', "isChecked": true},{ "time": '2:00 PM', "isChecked": true},{ "time": '3:00 PM', "isChecked": true},{ "time": '4:00 PM', "isChecked": true},{ "time": '5:00 PM', "isChecked": true},{ "time": '6:00 PM', "isChecked": true},{ "time": '7:00 PM', "isChecked": true},{ "time": '8:00 PM', "isChecked": true}],
  "SUN": [{ "time": '10:00 AM', "isChecked": true},{ "time": '11:00 AM', "isChecked": true},{ "time": '12:00 PM', "isChecked": true},{ "time": '1:00 PM', "isChecked": true},{ "time": '2:00 PM', "isChecked": true},{ "time": '3:00 PM', "isChecked": true},{ "time": '4:00 PM', "isChecked": true},{ "time": '5:00 PM', "isChecked": true},{ "time": '6:00 PM', "isChecked": true},{ "time": '7:00 PM', "isChecked": true},{ "time": '8:00 PM', "isChecked": true}]
}

const MentorScheduleTime = () => {

    const next7Days = [ 'MON',  'TUE',  'WED',  'THU',  'FRI',  'SAT',  'SUN']
    const [TimeZone, setTimezone] = useState()
    const [flag, setFlag] = useState(0)
    const [selectedDate, setSelectedDate] = useState(next7Days[0])
    const [timeValues, setTimeVal] = useState(undefined)
    const element =[]
//JSON.parse(JSON.stringify(timeval))

    const saveAvailableTime = () => {
      axios.post("/server/MentorActions/saveMentorAvailability",{  headers: {
        'Content-Type': 'application/json',
      }, data: timeValues}).then(resp=>{
        console.log(resp.data)
      }) 
    }
    const getAvailability = async() => {
      debugger  
      await axios.get("/server/MentorActions/getMentorAvail",{params:{isMentor:true}}).then(resp=>{
        setTimeVal(resp.data)
      })
    }
    if(timeValues!=undefined){
      Object.keys(timeValues[selectedDate]).forEach(function(key) {
        element.push(
            <div
                value={timeValues[selectedDate][key].time}
                onClick={(e)=>{  
                    timeValues[selectedDate][key].isChecked=!timeValues[selectedDate][key].isChecked
                    setTimeVal(timeValues)
                    setFlag(!flag)
                    
                }}
                className = {classNames(timeValues[selectedDate][key].isChecked==true? 'group relative flex cursor-pointer rounded-lg border ring-indigo-600  ring-2 p-4 shadow-sm focus:outline-none data-[focus]:border-indigo-600 data-[focus]:ring-2 data-[focus]:ring-indigo-600': 
                            'group relative flex cursor-pointer rounded-lg border border-gray-300 bg-white p-4 shadow-sm focus:outline-none data-[focus]:border-indigo-600 data-[focus]:ring-2 data-[focus]:ring-indigo-600')}
            >   
                <span className="flex flex-1">
                    <span className="flex flex-col">
                    <span className="block text-sm font-medium text-gray-900">{timeValues[selectedDate][key].time}</span>
                    </span>
                </span>
                
                <span
                    aria-hidden="true"
                    className="pointer-events-none absolute -inset-px rounded-lg border-2 border-transparent group-data-[focus]:border group-data-[checked]:border-indigo-600"
                />
            </div>
        )
      })
    }else{
      getAvailability()
    }
    
    
    return (
    element.length!=0?<div className='h-96'>
      <OuterCard>
      <div className="pt-6 m-8 sm:flex">
          <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Timezone</dt>
          <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
            <div className="w-96 bg-red-300 ">
                <TimezonePicker
                    value={TimeZone}
                    onChange={timezone => setTimezone(timezone)}
                    className= {" bg-red-300 z-30 w-96"}
                    inputProps={{
                    placeholder: 'Select Timezone...',
                    name: 'timezone',
                    }}
                /> 
            </div>
          </dd>
      </div>
      <div className="pt-6 m-8 sm:flex z-10 ">
          <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Availability</dt>
          <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
            <div className=" text-gray-900">
            <RadioGroup
              value={selectedDate}
              onChange={(val)=>{ setSelectedDate(val)}}
              className="mt-2 grid grid-cols-7 gap-y-7 sm:grid-cols-7 sm:gap-x-7"
            >
                {next7Days.map((day) => (
                  <Radio
                    value={day}
                    className="group relative flex cursor-pointer rounded-lg border border-gray-300 bg-white p-4 shadow-sm focus:outline-none data-[focus]:border-indigo-600 data-[focus]:ring-2 data-[focus]:ring-indigo-600">
                    <span className="flex flex-1">
                      <span className="flex flex-col">
                        <span className="block text-sm font-medium text-gray-900">{day}</span>
                        {/* <span className="mt-1 flex items-center text-sm text-gray-500">{}</span> */}
                      </span>
                    </span>
                    <span
                      aria-hidden="true"
                      className="pointer-events-none absolute -inset-px rounded-lg border-2 border-transparent group-data-[focus]:border group-data-[checked]:border-indigo-600"
                    />
                  </Radio>
                ))}
            </RadioGroup>
            </div>
          </dd>
      </div>
      <div className="pt-6 m-8 sm:flex z-10">
          <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6"></dt>
          <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
            <div className=" text-gray-900">
            <RadioGroup
                  value={selectedDate}
                  onChange={setSelectedDate}
                  className="mt-2 grid grid-cols-1 gap-y-6 sm:grid-cols-7 sm:gap-x-2"
          >
          {element} 
          </RadioGroup>
            </div>
          </dd>
      </div>
      <div className="mt-16 flex items-center justify-end gap-x-6">
              <button
                  type="button"
                  onClick={()=>{saveAvailableTime()}}
                  className="inline-flex items-center  rounded-md bg-indigo-600 px-2 py-2.5 text-sm font-semibold
                  text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                  Save
              </button>
      </div>
      {/* <TimePicker></TimePicker> */}
      </OuterCard>
    </div>:<></>
  )
}

export default MentorScheduleTime