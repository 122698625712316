import React, { useEffect, useState } from 'react'
import { Radio, RadioGroup } from '@headlessui/react'
import { CheckCircleIcon, InformationCircleIcon, ClockIcon, CalendarIcon, CheckIcon, XMarkIcon, QuestionMarkCircleIcon } from '@heroicons/react/20/solid'
import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import axios from 'axios'
import { 
  AcademicCapIcon,
  UserGroupIcon,
  ChatBubbleBottomCenterTextIcon,
  LightBulbIcon,
  PresentationChartLineIcon,
  PuzzlePieceIcon,
  RocketLaunchIcon,
  SparklesIcon,
  StarIcon,
  TrophyIcon
} from '@heroicons/react/24/outline'

const mailingLists = [
  { id: 1, title: '1 on 1 Session', description: ' A personalized, private meeting for tailored guidance and support on specific goals or challenges.', users: '621 users' },
  { id: 2, title: 'MockInterview Session', description: 'A simulated interview to practice answering questions with real-time feedback to improve performance.', users: '1200 users' },
  { id: 3, title: 'Mentorship', description: 'Regular, goal-oriented sessions with a mentor to provide consistent guidance, feedback, and accountability.', users: '2740 users' },
]

const sessionIcons = [
  AcademicCapIcon,
  UserGroupIcon,
  ChatBubbleBottomCenterTextIcon,
  LightBulbIcon,
  PresentationChartLineIcon,
  PuzzlePieceIcon,
  RocketLaunchIcon,
  SparklesIcon,
  StarIcon,
  TrophyIcon
]

const CheckBox = (props) => {
    const [selectedMeeting, setSelectedMeeting] = useState(null)
    const [meetingDetails, setMeetingDetails] = useState(null)
    const [meetingIcons, setMeetingIcons] = useState({})
    const [isOpen, setIsOpen] = useState(false)
    const [selectedDetailMeeting, setSelectedDetailMeeting] = useState(null)

    useEffect(() => {
        const fetchMeetingDetails = async () => {
            try {
                const { data } = await axios.get(`/server/MentorActions/getAllMeetingDtls`, {
                    params: {
                        mentorId: props.selectedMentor
                    }
                });
                setMeetingDetails(data);
            } catch (error) {
                console.error('Error fetching meeting details:', error);
            }
        };

        if (props.selectedMentor) {
            fetchMeetingDetails();
        }
    }, [props.selectedMentor]);

    useEffect(() => {
        if (meetingDetails) {
            const icons = {}
            const used = new Set()
            
            Object.values(meetingDetails).forEach(meeting => {
                let availableIcons = sessionIcons.filter(icon => !used.has(icon))
                if (availableIcons.length === 0) {
                    used.clear()
                    availableIcons = sessionIcons
                }
                const randomIcon = availableIcons[Math.floor(Math.random() * availableIcons.length)]
                used.add(randomIcon)
                icons[meeting.ROWID] = randomIcon
            })
            
            setMeetingIcons(icons)
        }
    }, [meetingDetails])

    const onchange = (e) => {
      setSelectedMeeting(e)
      props.callDetails.current["Amount"] = e.Amount
      props.callDetails.current["Duration"] = e.MeetingHour + " Hour " + e.MeetingMinute + " Minutes"
      props.callDetails.current["MeetingName"] = e.MeetingName
      props.callDetails.current["Questions"] = e.Questions
      props.callDetails.current["MeetingId"] = e.ROWID
    }

    const openDetails = (meeting, e) => {
        e.preventDefault()
        e.stopPropagation()
        setSelectedDetailMeeting(meeting)
        setIsOpen(true)
    }

    const getSessionIncludes = (meetingType) => {
        const includes = {
            '1 on 1 Session': [
                'Personalized attention',
                'Custom learning path',
                'Direct feedback',
                'Follow-up resources'
            ],
            'MockInterview Session': [
                'Industry-specific questions',
                'Real-time feedback',
                'Performance evaluation',
                'Improvement strategies'
            ],
            'Mentorship': [
                'Regular check-ins',
                'Goal setting and tracking',
                'Career guidance',
                'Network opportunities'
            ]
        }
        return includes[meetingType] || []
    }

    const getSessionFAQs = (meetingType) => {
        const faqs = {
            '1 on 1 Session': [
                {
                    question: 'How should I prepare?',
                    answer: 'List your specific goals and challenges before the session. Have relevant materials ready to share.'
                },
                {
                    question: 'Can I record the session?',
                    answer: 'Yes, with prior permission from the mentor. Recordings are for personal use only.'
                }
            ],
            'MockInterview Session': [
                {
                    question: 'What interview format will be used?',
                    answer: 'The format will match typical industry interviews, including technical and behavioral questions.'
                },
                {
                    question: 'Will I get written feedback?',
                    answer: "Yes, you'll receive a detailed evaluation report after the session."
                }
            ],
            'Mentorship': [
                {
                    question: 'How often will we meet?',
                    answer: 'Meeting frequency is flexible and can be weekly, bi-weekly, or monthly based on your needs.'
                },
                {
                    question: 'What is the cancellation policy?',
                    answer: 'Please provide 24 hours notice for any cancellations or reschedules.'
                }
            ]
        }
        return faqs[meetingType] || []
    }

    return (
        <>
            <fieldset>
                <legend className="text-lg font-semibold text-indigo-600 mb-4">Choose Your Session Type</legend>
                <RadioGroup
                    value={selectedMeeting}
                    onChange={onchange}
                    className="mt-2 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-6"
                >
                    {meetingDetails && Object.values(meetingDetails).map(meeting => (
                        <Radio
                            key={meeting.id}
                            value={meeting}
                            className="group relative flex cursor-pointer rounded-xl border border-gray-200 bg-white p-6 shadow-md hover:shadow-lg transition-all duration-200 focus:outline-none data-[focus]:border-indigo-600 data-[focus]:ring-2 data-[focus]:ring-indigo-600"
                        >
                            <div className="flex flex-col w-full">
                                <div className="flex items-start space-x-4">
                                    <div className="shrink-0">
                                        {meetingIcons[meeting.ROWID] && React.createElement(meetingIcons[meeting.ROWID], {
                                            className: "w-12 h-12 text-indigo-600",
                                        })}
                                    </div>
                                    
                                    <div className="flex-1 min-w-0">
                                        <div className="flex justify-between items-start">
                                            <h3 className="text-lg font-semibold text-gray-900 mb-1">
                                                {meeting.MeetingName.trim()}
                                            </h3>
                                            <button
                                                onClick={(e) => openDetails(meeting, e)}
                                                className="ml-2 text-indigo-600 hover:text-indigo-800"
                                            >
                                                <InformationCircleIcon className="h-5 w-5" />
                                            </button>
                                        </div>
                                        <p className="text-sm text-gray-600 line-clamp-3">
                                            {meeting.Description.trim()}
                                        </p>
                                    </div>
                                </div>

                                <div className="mt-4 flex items-center justify-between border-t border-gray-100 pt-4">
                                    <span className="text-lg font-bold text-indigo-600">
                                        ₹{meeting.Amount.trim()}
                                    </span>
                                    <span className="text-sm text-gray-500">
                                        {meeting.MeetingHour}h {meeting.MeetingMinute}m
                                    </span>
                                </div>
                            </div>

                            <span
                                aria-hidden="true"
                                className="pointer-events-none absolute -inset-px rounded-xl border-2 border-transparent group-data-[focus]:border group-data-[checked]:border-indigo-600 group-hover:border-indigo-100"
                            />
                        </Radio>
                    ))}
                </RadioGroup>
            </fieldset>

            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={() => setIsOpen(false)}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <div className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                    {selectedDetailMeeting && (
                                        <>
                                            <Dialog.Title
                                                as="h3"
                                                className="text-xl font-semibold leading-6 text-gray-900 mb-4 flex items-center justify-between"
                                            >
                                                <span>{selectedDetailMeeting.MeetingName.trim()}</span>
                                                <span className="text-lg font-bold text-indigo-600">
                                                    ₹{selectedDetailMeeting.Amount.trim()}
                                                </span>
                                            </Dialog.Title>
                                            
                                            <div className="mt-4 space-y-6">
                                                {/* Description Section */}
                                                <div>
                                                    <h4 className="font-medium text-gray-700">About this session</h4>
                                                    <p className="mt-1 text-gray-600">
                                                        {selectedDetailMeeting.Description.trim()}
                                                    </p>
                                                </div>

                                                {/* Session Details */}
                                                <div className="grid grid-cols-2 gap-4 bg-gray-50 p-4 rounded-lg">
                                                    <div className="flex items-center space-x-2">
                                                        <ClockIcon className="h-5 w-5 text-indigo-600" />
                                                        <span className="text-gray-600">
                                                            {selectedDetailMeeting.MeetingHour}h {selectedDetailMeeting.MeetingMinute}m
                                                        </span>
                                                    </div>
                                                    <div className="flex items-center space-x-2">
                                                        <CalendarIcon className="h-5 w-5 text-indigo-600" />
                                                        <span className="text-gray-600">Flexible scheduling</span>
                                                    </div>
                                                </div>

                                                {/* What's Included */}
                                                <div>
                                                    <h4 className="font-medium text-gray-700 mb-3">What's Included</h4>
                                                    <div className="grid grid-cols-1 gap-2">
                                                        {getSessionIncludes(selectedDetailMeeting.MeetingName).map((item, index) => (
                                                            <div key={index} className="flex items-center space-x-2">
                                                                <CheckIcon className="h-5 w-5 text-green-500" />
                                                                <span className="text-gray-600">{item}</span>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>

                                                {/* FAQs */}
                                                <div>
                                                    <h4 className="font-medium text-gray-700 mb-3">Frequently Asked Questions</h4>
                                                    <div className="space-y-4">
                                                        {getSessionFAQs(selectedDetailMeeting.MeetingName).map((faq, index) => (
                                                            <div key={index} className="border-b border-gray-200 pb-4">
                                                                <h5 className="font-medium text-gray-900 flex items-center space-x-2">
                                                                    <QuestionMarkCircleIcon className="h-5 w-5 text-indigo-600" />
                                                                    <span>{faq.question}</span>
                                                                </h5>
                                                                <p className="mt-1 text-gray-600 ml-7">{faq.answer}</p>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>

                                                {/* Pre-session Questions */}
                                                {selectedDetailMeeting.Questions && (
                                                    <div>
                                                        <h4 className="font-medium text-gray-700">Pre-session Questions</h4>
                                                        <p className="mt-1 text-gray-600">
                                                            {selectedDetailMeeting.Questions}
                                                        </p>
                                                    </div>
                                                )}
                                            </div>

                                            <div className="mt-6 flex justify-end space-x-3">
                                                <button
                                                    type="button"
                                                    className="inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus-visible:ring-2 focus-visible:ring-indigo-500 focus-visible:ring-offset-2"
                                                    onClick={() => setIsOpen(false)}
                                                >
                                                    Close
                                                </button>
                                                <button
                                                    type="button"
                                                    className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-indigo-500 focus-visible:ring-offset-2"
                                                    onClick={() => {
                                                        onchange(selectedDetailMeeting)
                                                        setIsOpen(false)
                                                    }}
                                                >
                                                    Select this session
                                                </button>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}

export default CheckBox
