import React from 'react'

const PopularTopics = () => {

const comments = [
    {
      id: 1,
      name: 'Introduction to AI',
      imageUrl:
        'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
      content:
        'Learn the fundamentals of artificial intelligence including machine learning, neural networks, and deep learning. ' +
        'Perfect for beginners looking to start their AI journey.',
      date: '1d ago',
      dateTime: '2023-03-04T15:54Z',
    },
    {
      id: 2,
      name: 'Explore AI agents',
      imageUrl:
        'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
      content:
        'Discover how AI agents can automate tasks and make intelligent decisions. ' + 
        'Learn about reinforcement learning, natural language processing, and autonomous systems.',
      date: '2d ago',
      dateTime: '2023-03-03T14:02Z',
    },
    {
      id: 3,
      name: 'Interview Preparation',
      imageUrl:
        'https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
      content:
        'Master technical interviews with comprehensive preparation strategies and practice problems. ' +
        'Learn data structures, algorithms, and system design principles.',
      date: '2d ago',
      dateTime: '2023-03-03T13:23Z',
    },
    {
      id: 4,
      name: 'AI in Healthcare',
      imageUrl:
        'https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
      content:
        'Explore how AI is revolutionizing healthcare through medical imaging, diagnosis, and patient care. ' +
        'Learn about applications in drug discovery and personalized medicine. ' +
        'Explicabo nihil laborum. Saepe facilis consequuntur in eaque. Consequatur perspiciatis quam. Sed est illo quia. Culpa vitae placeat vitae. Repudiandae sunt exercitationem nihil nisi facilis placeat minima eveniet.',
      date: '1d ago',
      dateTime: '2023-03-04T15:54Z',
    }
    
  ]

const handleItemClick = (id) => {
  console.log(`Item with id ${id} clicked`);
};

  return (
    <ul role="list" className="divide-y divide-gray-100">
      {comments.map((comment) => (
        <li 
          key={comment.id} 
          className="flex gap-x-4 py-5 hover:bg-gray-200 cursor-pointer" 
          onClick={() => handleItemClick(comment.id)}
        >
          <img alt="" src={comment.imageUrl} className="size-12 flex-none rounded-full bg-gray-50" />
          <div className="flex-auto">
            <div className="flex items-baseline justify-between gap-x-4">
              <p className="text-sm/6 font-semibold text-gray-900">{comment.name}</p>
              {/* <p className="flex-none text-xs text-gray-600">
                <time dateTime={comment.dateTime}>{comment.date}</time>
              </p> */}
            </div>
            <p className="mt-1 line-clamp-2 text-sm/6 text-gray-600">{comment.content}</p>
          </div>
        </li>
      ))}
    </ul>
  )
}

export default PopularTopics
