'use client'

import { useState, useEffect } from 'react'
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
} from '@headlessui/react'
import {
  Bars3Icon,
  BellIcon,
  CalendarIcon,
  ChartPieIcon,
  Cog6ToothIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  HomeIcon,
  UsersIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import { ChevronDownIcon, MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import { AiOutlineSchedule } from "react-icons/ai";
import { MdOutlineTravelExplore } from "react-icons/md";
import { CgProfile } from "react-icons/cg";
import axios from 'axios';
import NotificationDrawer from './NotificationDrawer';
import SearchBar from './Components/SearchBar'

import MainContent from './Components/MainContent'
import { useNavigate } from 'react-router-dom'

const navigation = [
  { name: 'Dashboard', path:"dashboard", href: '/app/home/dashboard', icon: HomeIcon, current: true },
  { name: 'Explore Mentors', path:"exploreMentors", href: '/app/user/exploreMentors', icon: MdOutlineTravelExplore, current: false },
  { name: 'Bookings', path:"bookings", href: '/app/user/bookings', icon: AiOutlineSchedule, current: false },
  { name: 'Documents', path:"documents", href: '/app/user/documents', icon: DocumentDuplicateIcon, current: false },
  { name: 'Profile', path:"userProfile", href: '/app/user/userProfile', icon: CgProfile, current: false },

]
const userNavigation = [
  { name: 'Your profile', href: '#' },
  { name: 'Sign out' },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const handleSearchResults = (results) => {
  // Handle the search results here
  console.log('Search results:', results)
  // You might want to update some state or pass this to another component
}

export function LandingPage(props) {
  const nav = useNavigate()
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [notificationDrawerOpen, setNotificationDrawerOpen] = useState(false)
  const [needSearchBar, setNeedSearchBar] = useState(false)

  const getCurrentPage = (path)=>{
    if(path.includes("exploreMentors")){
        return "exploreMentors"
    }else if(path.includes("userProfile")){
        return "userProfile"
    }else if(path.includes("documents")){
        return "documents"
    }else if(path.includes("dashboard")){
        return "dashboard"
    }else if(path.includes("bookings")){
      return "bookings"
    }
  }

  const path = getCurrentPage(window.location.pathname)
  
  useEffect(() => {
    setNeedSearchBar(path === "exploreMentors")
  }, [path])

  console.log("path", path)
  return (
    <>
      <div className={notificationDrawerOpen ? 'blur-background' : ''}>
        {/* Notification Drawer */}

        <NotificationDrawer 
          isOpen={notificationDrawerOpen} 
          onClose={() => {
            console.log("NotificationDrawer closed");
            setNotificationDrawerOpen(false);
          }} 
        />

        <Dialog open={sidebarOpen} onClose={setSidebarOpen} className="relative  lg:hidden">
          <DialogBackdrop transition className="fixed inset-0 bg-gray-900/80 transition-opacity duration-300 ease-linear data-[closed]:opacity-0"/>
          <div className="fixed inset-0 flex">
            <DialogPanel transition className="relative mr-16 flex w-full max-w-xs flex-1 transform transition duration-300 ease-in-out data-[closed]:-translate-x-full">
              <TransitionChild>
                <div className="absolute left-full top-0 flex w-16 justify-center pt-5 duration-300 ease-in-out data-[closed]:opacity-0">
                  <button type="button" onClick={() => setSidebarOpen(false)} className="-m-2.5 p-2.5">
                    <span className="sr-only">Close sidebar</span>
                    <XMarkIcon aria-hidden="true" className="h-6 w-6 text-white" />
                  </button>
                </div>
              </TransitionChild>
              {/* Sidebar component, swap this element with another sidebar if you like */}
              <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4">
                <div className="flex h-16 shrink-0 items-center">
                  <img alt="Your Company" src={"/home/user/Downloads/MeetMasters_LOGO.jpg"} className="h-8 w-auto"/>
                </div>
                <nav className="flex flex-1 flex-col">
                  <ul role="list" className="flex flex-1 flex-col gap-y-7">
                    <li>
                      <ul role="list" className="-mx-2 space-y-1">
                        {navigation.map((item) => (
                          <li key={item.name}>
                            <a href={item.href}  className={classNames(item.path==path? 'bg-gray-50 text-indigo-600': 'text-gray-700 hover:bg-gray-50 hover:text-indigo-600','group flex gap-x-3 rounded-md p-2 text-sm/6 font-semibold',)}>
                          <item.icon aria-hidden="true" className={classNames(item.path==path ? 'text-white' : 'text-gray-400 group-hover:text-indigo-600','h-6 w-6 shrink-0',)}/>
                          {item.name}
                        </a>
                          </li>
                        ))}
                      </ul>
                    </li>
                    <li className="mt-auto">
                      <a href="#" className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm/6 font-semibold text-gray-700 hover:bg-gray-50 hover:text-indigo-600">
                        <Cog6ToothIcon aria-hidden="true" className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-indigo-600"/>
                        Settings
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </DialogPanel>
          </div>
        </Dialog>

        {/* Static sidebar for desktop */}
        {/* removed lg:z-50  */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-72 lg:flex-col"> 
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-gradient-to-b from-white to-gray-50 px-6 pb-4">
            <div className="flex h-16 shrink-0 items-center">
              <img
                alt="Your Company"
                src={process.env.PUBLIC_URL +"/Assets/Logo4.png"}
                className="h-32 w-auto"
              />
                              {/* Download button
                                <button 
                  type="button" 
                  onClick={async () => {
                    try {
                      const response = await axios.get('/server/userActions/download', {
                        responseType: 'blob'
                      });

                      const blob = new Blob([response.data]);
                      const downloadUrl = window.URL.createObjectURL(blob);
                      const link = document.createElement('a');
                      link.href = downloadUrl;

                      const filename = response.headers['content-disposition']
                        ? response.headers['content-disposition'].split('filename=')[1]
                        : 'downloaded-file';

                      link.setAttribute('download', filename);

                      document.body.appendChild(link);
                      link.click();
                      link.remove();

                      window.URL.revokeObjectURL(downloadUrl);
                    } catch (error) {
                      alert('Error downloading: ' + error.message);
                    }
                  }}
                  className="px-3 py-2 text-sm font-semibold text-white bg-indigo-600 rounded-md hover:bg-indigo-500"
                >
                  Download
                </button>   */}
            </div>
            <nav className="flex flex-1 flex-col">
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul role="list" className="-mx-2 space-y-1">
                    {navigation.map((item) => (
                      <li key={item.name}>
                        <a  onClick={()=>{nav(item.href)}} className={classNames(item.path==path?
                           'bg-gradient-to-r from-indigo-50 to-white text-indigo-600': 'text-gray hover:bg-gray-50 hover:text-indigo-600','group flex gap-x-3 rounded-md p-2 text-sm/6 font-semibold',)}>
                          <item.icon aria-hidden="true" className={classNames(item.path==path ? 'text-indigo-600' : 'text-gray-400 group-hover:text-indigo-600','h-6 w-6 shrink-0',)}/>
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </li>
                <li className="mt-auto">
                  <a href="#" className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm/6 font-semibold text-gray-700 hover:bg-gray-50 hover:text-indigo-600">
                    <Cog6ToothIcon aria-hidden="true" className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-indigo-600"/>
                    Settings
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className="lg:pl-72">
          <div className={`sticky top-0 flex shrink-0 items-center gap-x-4 border-b border-gray-200 bg-gradient-to-r from-white via-gray-50 to-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8 ${
            path === "exploreMentors" ? 'h-24' : 'h-16'
          }`}>
            <button type="button" onClick={() => setSidebarOpen(true)} className="-m-2.5 p-2.5 text-gray-700 lg:hidden">
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon aria-hidden="true" className="h-6 w-6" />
            </button>

            {/* Separator */}
            <div aria-hidden="true" className="h-6 w-px bg-gray-200 lg:hidden" />
                    {window.location.pathname.includes("dashboard")?<h1 className="text-2xl font-bold">Welcome back, {window.MM_USER_DETAILS.first_name}!</h1>:<></>}
            <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
              <form action="#" method="GET" className="relative flex flex-1">
                {needSearchBar && <SearchBar onSearch={handleSearchResults} />}
              </form>
              <div className="flex ml-96 items-center gap-x-4 lg:gap-x-6">
                <button 
                  type="button" 
                  onClick={() => {
                    console.log("BellIcon clicked");
                    setNotificationDrawerOpen(true);
                  }} 
                  className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500"
                >
                  <span className="sr-only">View notifications</span>
                  <BellIcon aria-hidden="true" className="h-6 w-6" />
                </button>

                {/* Separator */}
                <div aria-hidden="true" className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-200" />

                {/* Profile dropdown */}
                <Menu as="div" className="relative">
                  <MenuButton className="-m-1.5 flex items-center p-1.5">
                    <span className="sr-only">Open user menu</span>
                    <img alt="" src={"https://user-documents-development.zohostratus.in/"+window.MM_USER_DETAILS.user_id+"/profilePic.jpeg"} className="h-8 w-8 rounded-full bg-gray-50"/>
                    <span className="hidden lg:flex lg:items-center">
                      <span aria-hidden="true" className="ml-4 text-sm/6 font-semibold text-gray-900">
                        Tom Cook
                      </span>
                      <ChevronDownIcon aria-hidden="true" className="ml-2 h-5 w-5 text-gray-400" />
                    </span>
                  </MenuButton>
                  <MenuItems transition className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in">
                    {userNavigation.map((item) => (
                      <MenuItem key={item.name}>
                        <a onClick={()=>{item.name=="Sign out"?window.catalyst.auth.signOut(window.origin):nav(item.href)}} className="block px-3 py-1 text-sm/6 text-gray-900 data-[focus]:bg-gray-50 data-[focus]:outline-none">
                          {item.name}
                        </a>
                      </MenuItem>
                    ))}
                  </MenuItems>
                </Menu>
              </div>
            </div>
          </div>
          <MainContent path={path}></MainContent>
        </div>
      </div>
    </>
  )
}

export default LandingPage