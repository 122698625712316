
import React, { useState } from 'react'
import Subtab from '../Mentor/Subtab'
import MentorProfileBasic from './MentorProfileBasic'
import MentorScheduleTime from './MentorScheduleTime'
import Hello from './Hello'
import MeetingConf from './MeetingConf'


const MentorProfile = () => {
    const tabs = [
        { name: 'Basic', type: 'Basic', current: false },
        { name: 'Meeting Config', type: "MeetingConf", current: false },
        { name: 'Schedule',type:"Schedule",  current: true },
      ]
    const [subTab, setSubTab] = useState("Basic")
    return (
        <>
        <Subtab subTab={subTab} tabs={tabs} setSubTab={setSubTab}></Subtab>
        {
            {
            'Basic': <MentorProfileBasic></MentorProfileBasic>,
            'MeetingConf':<MeetingConf></MeetingConf>,
            'Schedule':<MentorScheduleTime></MentorScheduleTime>,

            }[subTab]
        }
        </>
        )
}

export default MentorProfile