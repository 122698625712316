import React from 'react';
import PropTypes from 'prop-types';

// Default loading icons - replace these with your actual icons
const defaultLoadingIcons = [
  // Spinner
  <svg key="spinner" className="animate-spin h-10 w-10 text-blue-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
  </svg>,
  
  // Dots
  <div key="dots" className="flex space-x-2">
    <div className="w-2 h-2 bg-blue-500 rounded-full animate-bounce"></div>
    <div className="w-2 h-2 bg-blue-500 rounded-full animate-bounce [animation-delay:-.3s]"></div>
    <div className="w-2 h-2 bg-blue-500 rounded-full animate-bounce [animation-delay:-.5s]"></div>
  </div>,
  
  // Pulse
  <div key="pulse" className="w-10 h-10 bg-blue-500 rounded-full animate-pulse"></div>,
  
  // Ring
  <div key="ring" className="w-10 h-10 border-4 border-blue-500 rounded-full border-t-transparent animate-spin"></div>,
  
  // Ping
  <div key="ping" className="relative w-10 h-10">
    <div className="w-10 h-10 bg-blue-500 rounded-full animate-ping absolute opacity-75"></div>
    <div className="w-10 h-10 bg-blue-500 rounded-full relative"></div>
  </div>
];

// Add this SVG checkmark animation
const CheckmarkIcon = () => (
  <div className="relative">
    <div className="w-16 h-16 rounded-full bg-green-50 flex items-center justify-center">
      <svg 
        className="w-10 h-10 text-green-500"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path 
          d="M20 6L9 17L4 12"
          className="origin-center"
          style={{
            strokeDasharray: '30',
            strokeDashoffset: '30',
            animation: 'drawCheck 0.8s ease forwards'
          }}
        />
      </svg>
    </div>
  </div>
);

// Add this style block in your component or in a CSS file
const styles = `
  @keyframes drawCheck {
    from {
      stroke-dashoffset: 30;
    }
    to {
      stroke-dashoffset: 0;
    }
  }
`;

const LoadingSuccess = ({
  isLoading,
  loadingText = 'Loading...',
  successContent,
  customLoadingIcon,
  className = '',
  onActionClick,
  actionButtonText = 'Continue'
}) => {
  const getRandomLoadingIcon = () => {
    const randomIndex = Math.floor(Math.random() * defaultLoadingIcons.length);
    return defaultLoadingIcons[randomIndex];
  };

  return (
    <>
      <style>{styles}</style>
      <div className="fixed inset-0 flex items-center justify-center bg-white/95">
        <div className="max-w-sm w-full p-6">
          {isLoading ? (
            <div className="flex flex-col items-center space-y-4">
              <div className="loading-icon">
                {customLoadingIcon || getRandomLoadingIcon()}
              </div>
              {loadingText && (
                <p className="text-gray-600 text-sm md:text-base font-medium text-center">{loadingText}</p>
              )}
            </div>
          ) : (
            <div className="text-center">
              <div className="flex flex-col items-center space-y-4">
                <CheckmarkIcon />
                <div className="mt-4">
                  {successContent}
                </div>
                {onActionClick && (
                  <button
                    onClick={onActionClick}
                    className="mt-6 px-6 py-2 bg-blue-500 text-white rounded-md hover:bg-green-600 transition-colors w-full"
                  >
                    {actionButtonText}
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

LoadingSuccess.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  loadingText: PropTypes.string,
  successContent: PropTypes.node,
  customLoadingIcon: PropTypes.node,
  className: PropTypes.string,
  onActionClick: PropTypes.func,
  actionButtonText: PropTypes.string
};

export default LoadingSuccess; 