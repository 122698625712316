import React from 'react'

const OuterCard = ({children}) => {
  return (
    <div className="h-full m-5  rounded-lg bg-white shadow">
        <div className="px-4 py-5 h-full sm:p-6 overflow-y-scroll">  
            {children}
        </div>
    </div>
  )
}

export default OuterCard