import React, { useEffect, useState } from 'react';

const MotivationalLoader = () => {
  // Array of motivational quotes
  const quotes = [
    "Believe you can, and you're halfway there.",
    "Don't watch the clock; do what it does. Keep going.",
    "The secret of getting ahead is getting started.",
    "Success is not final, failure is not fatal: It is the courage to continue that counts.",
    "Act as if what you do makes a difference. It does."
  ];

  // State for the current quote
  const [currentQuote, setCurrentQuote] = useState("");

  useEffect(() => {
    // Function to randomly pick a quote
    const randomQuote = () => quotes[Math.floor(Math.random() * quotes.length)];

    // Set the initial quote
    setCurrentQuote(randomQuote());

    // Update quote every 3 seconds
    const interval = setInterval(() => {
      setCurrentQuote(randomQuote());
    }, 3000);

    // Cleanup the interval on component unmount
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <div className="flex items-center space-x-4">
        {/* Spinner */}
        <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500 border-solid"></div>
      </div>
      {/* Motivational Quote */}
      <p className="mt-8 text-center text-lg text-gray-700 font-medium max-w-md italic">
        "{currentQuote}"
      </p>
    </div>
  );
};

export default MotivationalLoader; 