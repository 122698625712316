import React, { useState, useEffect, useCallback } from 'react'
import { PlusIcon, DocumentIcon, PhotoIcon, VideoCameraIcon, MusicalNoteIcon, CodeBracketIcon, DocumentTextIcon, TableCellsIcon, DocumentChartBarIcon, ArchiveBoxIcon, XMarkIcon } from '@heroicons/react/24/outline'
import DocumentEmpty from '../../../../public/Assets/DocumentEmpty.svg'
import axios from 'axios'
import { Document, Page, pdfjs } from 'react-pdf'
import 'react-pdf/dist/Page/AnnotationLayer.css'
import 'react-pdf/dist/Page/TextLayer.css'
import { Viewer } from '@react-pdf-viewer/core';
import { Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import FileViewer from 'react-file-viewer';
import { 
  FaFileAlt, FaFileImage, FaFileVideo, FaFileAudio, 
  FaFileCode, FaFilePdf, FaFileExcel, FaFilePowerpoint, 
  FaFileArchive, FaEllipsisV, FaDownload, FaShare, FaEye 
} from 'react-icons/fa';
import { Menu } from '@headlessui/react';


// Utility functions
const getDisplayName = (fullPath) => {
  if (!fullPath) return 'Unnamed File';
  const parts = fullPath.split('/');
  return parts[parts.length - 1];
};

const getFileIcon = (fileName) => {
  if (!fileName || typeof fileName !== 'string') {
    return <FaFileAlt className="h-full w-full text-gray-400" />;
  }
  
  const extension = fileName.split('.').pop()?.toLowerCase() || '';
  
  const iconMap = {
    // Images
    'jpg': FaFileImage,
    'jpeg': FaFileImage,
    'png': FaFileImage,
    'gif': FaFileImage,
    'svg': FaFileImage,
    'webp': FaFileImage,
    'bmp': FaFileImage,
    
    // Videos
    'mp4': FaFileVideo,
    'mov': FaFileVideo,
    'avi': FaFileVideo,
    'mkv': FaFileVideo,
    'webm': FaFileVideo,
    
    // Audio
    'mp3': FaFileAudio,
    'wav': FaFileAudio,
    'ogg': FaFileAudio,
    'm4a': FaFileAudio,
    
    // Code
    'js': FaFileCode,
    'py': FaFileCode,
    'java': FaFileCode,
    'html': FaFileCode,
    'css': FaFileCode,
    'php': FaFileCode,
    'cpp': FaFileCode,
    
    // Documents
    'pdf': FaFilePdf,
    'doc': FaFileAlt,
    'docx': FaFileAlt,
    'txt': FaFileAlt,
    'rtf': FaFileAlt,
    
    // Spreadsheets
    'xls': FaFileExcel,
    'xlsx': FaFileExcel,
    'csv': FaFileExcel,
    
    // Presentations
    'ppt': FaFilePowerpoint,
    'pptx': FaFilePowerpoint,
    
    // Archives
    'zip': FaFileArchive,
    'rar': FaFileArchive,
    '7z': FaFileArchive,
    'tar': FaFileArchive,
    'gz': FaFileArchive
  };

  const IconComponent = iconMap[extension] || FaFileAlt;
  return <IconComponent className="h-full w-full text-gray-400" />;
};

const FilePreview = ({ file }) => {
  if (!file || !file.key) {
    return (
      <div className="w-full h-32 flex items-center justify-center bg-gray-100 rounded-lg">
        <DocumentIcon className="h-6 w-6 text-gray-400" />
      </div>
    );
  }

  return (
    <div className="w-full h-32 flex items-center justify-center bg-gray-100 rounded-lg">
      {getFileIcon(file.key)}
    </div>
  );
};

const PreviewModal = ({ file, onClose }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  if (file.type !== 'pdf') return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
          <div className="absolute top-0 right-0 pt-4 pr-4">
            <button
              type="button"
              onClick={onClose}
              className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
            >
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>

          <div className="mt-5">
            <Document
              file={file.url}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              <Page pageNumber={pageNumber} />
            </Document>
            
            <div className="mt-4 flex justify-between items-center">
              <button
                onClick={() => setPageNumber(page => Math.max(page - 1, 1))}
                disabled={pageNumber <= 1}
                className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 disabled:opacity-50"
              >
                Previous
              </button>
              
              <p className="text-sm text-gray-500">
                Page {pageNumber} of {numPages}
              </p>
              
              <button
                onClick={() => setPageNumber(page => Math.min(page + 1, numPages))}
                disabled={pageNumber >= numPages}
                className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 disabled:opacity-50"
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const FileActionMenu = ({ file, onView, onDownload, onShare }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = (e, action) => {
    e.stopPropagation(); // Prevent file selection
    setIsOpen(false);
    action();
  };

  return (
    <Menu as="div" className="absolute top-2 right-2 z-50">
      <Menu.Button className="p-2.5 rounded-full hover:bg-gray-200 text-gray-600">
        <FaEllipsisV className="w-4 h-4" />
      </Menu.Button>
      
      <Menu.Items className="absolute right-0 z-50 mt-2 w-48 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
        <div className=" py-1 z-30">
          <button
            onClick={(e) => handleClick(e, onDownload)}
            className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center"
          >
            <FaDownload className="mr-2" /> Download
          </button>
          <button
            onClick={(e) => handleClick(e, onShare)}
            className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center"
          >
            <FaShare className="mr-2" /> Share
          </button>
        </div>
      </Menu.Items>
    </Menu>
  );
};

const FileList = ({ files, handleFileAction }) => {
  const handleDownload = async (file) => {
    try {
      const response = await axios.get('/server/userActions/getFileContent', {
        params: { filename: file.key },
        responseType: 'blob'
      });
      
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', decodeURIComponent(getDisplayName(file.object_url)));
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading file:', error);
      alert('Failed to download file');
    }
  };

  const handleShare = (file) => {
    // Implement share functionality
    alert('Share functionality coming soon!');
  };

  return (
    <ul role="list" className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
      {files.map((file) => (
        <li 
          key={file.object_url} 
          className="relative"

        >
          <div className="group aspect-w-10 aspect-h-7 block w-full overflow-hidden rounded-lg bg-gray-100 cursor-pointer relative"
                    onClick={(e) => {
                      e.preventDefault();
                      handleFileAction(file);
                    }}
          >
            <div className="flex items-center justify-center h-full py-6">
              <div className="h-20 w-20">
                {getFileIcon(file.object_url)}
              </div>
            </div>
            

            
            <div className="absolute inset-0 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500">
              <div className="absolute inset-0 bg-gray-900 bg-opacity-0 group-hover:bg-opacity-30 transition-opacity" />
            </div>
          </div>
          <FileActionMenu
              file={file}
              onView={() => handleFileAction(file)}
              onDownload={() => handleDownload(file)}
              onShare={() => handleShare(file)}
            />
          <p className="pointer-events-none mt-2 block truncate text-sm font-medium text-gray-900">
            {decodeURIComponent(getDisplayName(file.object_url))}
          </p>
        </li>
      ))}
    </ul>
  );
};

const previewBlob = (blob, mimeType) => {
  // Create a modal container
  const modalContainer = document.createElement('div');
  modalContainer.className = 'fixed inset-0 z-50 overflow-y-auto';
  modalContainer.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
  
  // Create content container
  const content = document.createElement('div');
  content.className = 'flex items-center justify-center min-h-screen p-4';
  
  // Create preview wrapper
  const previewWrapper = document.createElement('div');
  previewWrapper.className = 'bg-white rounded-lg p-6 max-w-4xl w-full relative';
  
  // Add close button
  const closeButton = document.createElement('button');
  closeButton.innerHTML = '×';
  closeButton.className = 'absolute top-2 right-4 text-2xl text-gray-500 hover:text-gray-700';
  closeButton.onclick = () => {
    document.body.removeChild(modalContainer);
    URL.revokeObjectURL(previewWrapper.querySelector('iframe, img')?.src);
  };
  
  // Handle different file types
  if (mimeType.startsWith('application/pdf')) {
    const iframe = document.createElement('iframe');
    iframe.src = URL.createObjectURL(blob);
    iframe.className = 'w-full h-[80vh]';
    previewWrapper.appendChild(iframe);
  } else if (mimeType.startsWith('image/')) {
    const img = document.createElement('img');
    img.src = URL.createObjectURL(blob);
    img.className = 'max-w-full max-h-[80vh] object-contain';
    previewWrapper.appendChild(img);
  } else {
    const message = document.createElement('p');
    message.textContent = 'Preview not available for this file type.';
    message.className = 'text-gray-500';
    previewWrapper.appendChild(message);
  }
  
  previewWrapper.appendChild(closeButton);
  content.appendChild(previewWrapper);
  modalContainer.appendChild(content);
  document.body.appendChild(modalContainer);
};

const handleFileAction = async (fileInfo) => {
  try {
    if (!fileInfo || !fileInfo.object_url) {
      console.error('Invalid file information');
      return;
    }

    const fileExtension = fileInfo.object_url.split('.').pop().toLowerCase();
    const mimeType = getMimeType(fileExtension);
    
    // Fetch file content
    const response = await axios.get('/server/userActions/getFileContent', {
      params: { filename: fileInfo.key },
      responseType: 'arraybuffer'
    });

    // Convert buffer to Blob
    const blob = new Blob([response.data], { type: mimeType });
    
    // Use the new previewBlob function
    previewBlob(blob, mimeType);

  } catch (error) {
    console.error('Error handling file:', error);
    alert('Unable to preview file. Please try again later.');
  }
};

// Helper function to get MIME type from extension
const getMimeType = (extension) => {
  const mimeTypes = {
    'pdf': 'application/pdf',
    'jpg': 'image/jpeg',
    'jpeg': 'image/jpeg',
    'png': 'image/png',
    'gif': 'image/gif',
    'bmp': 'image/bmp',
    'doc': 'application/msword',
    'docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'xls': 'application/vnd.ms-excel',
    'xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'ppt': 'application/vnd.ms-powerpoint',
    'pptx': 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'txt': 'text/plain'
  };
  return mimeTypes[extension] || 'application/octet-stream';
};

// Helper function to get file extension
const getFileExtension = (filename) => {
  return filename.split('.').pop() || '';
};

// Helper function to check if file is an image
const isImageFile = (extension) => {
  const imageTypes = ['jpg', 'jpeg', 'png', 'gif', 'bmp'];
  return imageTypes.includes(extension);
};

// Version checking utility
const checkApiVersion = async () => {
  try {
    const response = await fetch('/api/version');
    const versions = await response.json();
    return {
      isCompatible: versions.api === versions.worker,
      api: versions.api,
      worker: versions.worker
    };
  } catch (error) {
    console.error('Version check failed:', error);
    return { isCompatible: false };
  }
};

const MyDocument = () => {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedFile, setSelectedFile] = useState(null);

  // Add this function to handle file uploads from either button
  const triggerFileUpload = () => {
    document.getElementById('fileInput').click();
  };

  useEffect(() => {
    const fetchFiles = async () => {
      try {
        const response = await axios.get('/server/userActions/getFiles', {
          params: { prefix: 'mydocs' }
        });
        
        if (response.data.success) {
          setFiles(response.data.contents || []);
        }
      } catch (error) {
        console.error('Error fetching files:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchFiles();
  }, []);

  useEffect(() => {
    console.log('Current files:', files);
  }, [files]);

  const handleFileUpload = async (event) => {
    try {
      const file = event.target.files[0];
      if (!file) return;

      const formData = new FormData();
      formData.append('file', file);
      formData.append('prefix', 'mydocs');

      const response = await axios.post('/server/userActions/uploadFile', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.data.success) {
        alert('File uploaded successfully!');
        // Optionally refresh the documents list here
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      alert('Failed to upload file. Please try again.');
    }
  };

  return (
    <div className="text-center py-4">
      {/* Add top-right upload button */}
      <div className="flex justify-end px-4 mb-4">
        <button
          type="button"
          onClick={triggerFileUpload}
          className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
          Add Document
        </button>
      </div>

      {loading ? (
        <p>Loading...</p>
      ) : files.length > 0 ? (
        <div className="space-y-4">
          <div className="mt-8 flow-root">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <FileList files={files} handleFileAction={handleFileAction} />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="flex justify-center">
            <img src={DocumentEmpty} alt="No documents" className="h-12 w-12 text-gray-400" />
          </div>
          <h3 className="mt-2 text-sm font-semibold text-gray-900">No documents</h3>
          <p className="mt-1 text-sm text-gray-500">Get started by uploading a new document</p>
          {/* Update empty state upload button */}
          <div className="mt-6">
            <button
              type="button"
              onClick={triggerFileUpload}
              className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
              Add Document
            </button>
          </div>
        </div>
      )}
      
      {/* Move file input outside of the conditional rendering */}
      <input
        type="file"
        id="fileInput"
        className="hidden"
        onChange={handleFileUpload}
      />

      {/* Preview Modal */}
      {selectedFile && (
        <PreviewModal
          file={selectedFile}
          onClose={() => {
            console.log('Closing preview modal'); // Debug log
            setSelectedFile(null);
          }}
        />
      )}
    </div>
  );
};

export default MyDocument;