import React from 'react'
import { useNavigate } from 'react-router-dom'

const NavigateToHome = () => {

  const nav = useNavigate()
  nav("/app/")
  return (
    <></>
  )
}

export default NavigateToHome