import React from 'react'
import UpcomingCalls from './DashboardComponents/UpcomingCalls'
import TopMentors from './DashboardComponents/TopMentors'
import PopularTopics from '../PopularTopics'

const Dashboard = () => {
  return (
    <div className="bg-gray-100 py-24 sm:py-2 h-screen overflow-y-scroll">
      <div className="mx-auto max-w-2xl px-2 lg:max-w-7xl lg:px-8">
        <div className="mt-10 grid grid-cols-1 gap-4 sm:mt-1 lg:grid-cols-6 lg:grid-rows-2">
          {/* Top Experts Section */}
          <div className="relative lg:col-span-3">
            <div className="bg-white rounded-lg max-lg:rounded-t-[2rem] lg:rounded-tl-[2rem] h-full shadow-sm">
              <div className="flex items-center justify-center p-5 border-b">
                <div className="flex items-center gap-2">
                  <span>👨‍🏫</span>
                  <h3 className="text-xl font-bold bg-gradient-to-r from-indigo-600 to-indigo-400 bg-clip-text text-transparent">
                    Top Experts
                  </h3>
                </div>
              </div>
              <div className="h-96 overflow-y-auto p-6">
                <TopMentors />
              </div>
            </div>
          </div>

          {/* Upcoming Calls Section */}
          <div className="relative lg:col-span-3">
            <div className="bg-white rounded-lg lg:rounded-tr-[2rem] h-full shadow-sm">
              <div className="flex items-center justify-center p-5 border-b">
                <div className="flex items-center gap-2">
                  <span>📅</span>
                  <h3 className="text-xl font-bold bg-gradient-to-r from-indigo-600 to-indigo-400 bg-clip-text text-transparent">
                    Upcoming Calls
                  </h3>
                </div>
              </div>
              <div className="h-96 overflow-y-auto p-6">
                <UpcomingCalls />
              </div>
            </div>
          </div>

          {/* Popular Topics Section */}
          <div className="relative lg:col-span-2">
            <div className="bg-white rounded-lg lg:rounded-bl-[2rem] h-full shadow-sm">
              <div className="flex items-center justify-center p-5 border-b">
                <div className="flex items-center gap-2">
                  <span>🔥</span>
                  <h3 className="text-xl font-bold bg-gradient-to-r from-indigo-600 to-indigo-400 bg-clip-text text-transparent">
                    Popular Topics
                  </h3>
                </div>
              </div>
              <div className="h-96 overflow-y-auto p-6">
                <PopularTopics />
              </div>
            </div>
          </div>

          {/* Empty Sections */}
          <div className="relative lg:col-span-2">
            <div className="bg-white rounded-lg h-full shadow-sm">
              {/* Reserved for future content */}
            </div>
          </div>

          <div className="relative lg:col-span-2">
            <div className="bg-white rounded-lg max-lg:rounded-b-[2rem] lg:rounded-br-[2rem] h-full shadow-sm">
              {/* Reserved for future content */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dashboard