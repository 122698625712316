import React, { useEffect, useState } from 'react'
import TimePicker from './TimePicker'
import DateNtimePicker from './DateNtimePicker'
import { ArrowLongRightIcon, QuestionMarkCircleIcon } from '@heroicons/react/20/solid'
import axios, * as others from 'axios';
import QuestionSection from './QuestionSection';


const ScheduleSlot = (props) => {
  console.log("ScheduleSlot props:", props);

  const [mentorAvail, setMentorAvail] = useState(undefined)
  const [selectedDate, setSelDate] = useState(undefined)
  const [selectedTime, setSelectedTime] = useState(undefined)
  
  useEffect(()=>{
    if(mentorAvail==undefined){
      getMentorAvailability()
    }
  })

  

  const getMentorAvailability = async()=>{
    debugger
    await axios.get("/server/MentorActions/getMentorAvail",{params:{mentorId:props.selectedMentor}}).then(resp =>{
      console.log("availllll--> ",resp)
      let val = resp.data
      setMentorAvail({val})
    })
  }
  // alert(props.callDetails.current.Questions)
  console.log("test props.flow",props.flow)

  const handleContinueClick = () => {
    if (!selectedDate || !selectedTime) {
      alert("Please select both date and time before booking");
      return;
    }

    try {
      console.log("Button clicked");
      console.log("props:", props);
      console.log("props.flow:", props.flow);
      
      if (!props.setPageNames) {
        console.error("setPageNames is undefined");
        return;
      }

      if (props.flow === "schedule") {
        props.setPageNames("SchedulePreview");
      } else if (props.flow === "reschedule") {
        props.setPageNames("BookingsUser");
      } else {
        console.log("Unknown flow:", props.flow);
      }
    } catch (error) {
      console.error("Error in onClick handler:", error);
    }
  };

  return (
    <div>
        <div className="flex justify-center items-center space-x-2 mb-6">
            <h2 className="text-2xl font-bold text-gray-900">Schedule Your Session</h2>
            <QuestionMarkCircleIcon 
                className="h-5 w-5 text-gray-400 hover:text-indigo-600 cursor-pointer"
                title="Choose your preferred date and time for the mentoring session"
            />
        </div>
        
        <DateNtimePicker mentorAvail={mentorAvail} setSelDate={setSelDate} {...props}></DateNtimePicker>
        {selectedDate!=undefined?<TimePicker mentorAvail={mentorAvail} selectedDate={selectedDate} setSelectedTime={setSelectedTime} {...props}></TimePicker>:<></> }
        
        <button 
              type="button"
              onClick={handleContinueClick}

                  disabled={!selectedDate || !selectedTime}
                  className={`rounded-md px-4 py-2 text-sm font-semibold shadow-sm
                    ${(!selectedDate || !selectedTime) 
                      ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                      : 'bg-indigo-600 text-white hover:bg-indigo-500 cursor-pointer'
                    }`}
                >
                  {props.flow=="schedule"?"Confirm":"Reschedule Session"}
                </button>

        
    </div>
  )
}

export default ScheduleSlot