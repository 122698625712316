import React from 'react';
import PropTypes from 'prop-types';

const UserCard = ({ name, age, country, state }) => {
  return (
    <div className="max-w-sm rounded overflow-hidden shadow-lg bg-white p-6">
      <h2 className="text-xl font-bold mb-2">{name}</h2>
      <p className="text-gray-700">Age: {age}</p>
      <p className="text-gray-700">Country: {country}</p>
      <p className="text-gray-700">State: {state}</p>
    </div>
  );
};

// PropTypes for type checking
UserCard.propTypes = {
  name: PropTypes.string.isRequired,
  age: PropTypes.number.isRequired,
  country: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
};

// Default props (optional)
UserCard.defaultProps = {
  name: 'John Doe',
  age: 30,
  country: 'USA',
  state: 'California',
};

export default UserCard;
