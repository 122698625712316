import { CheckCircleIcon, XMarkIcon } from '@heroicons/react/20/solid'
import React from 'react'

const TopBandWithClose = (props) => {
  return (
    <button 
      type="button" 
      onClick={(e)=>{props.setIsOpen(false)}}
      className="h-[30px] inline-flex rounded-md bg-gray-50 p-1.5 text-black hover:bg-indigo-200 focus:outline-none ">
        <span className="sr-only">Dismiss</span>
        <XMarkIcon aria-hidden="true" className="size-5" />
    </button>

  )
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default TopBandWithClose

