import React from 'react';

const NotificationInner = (props) => {

  return (
    <div className="space-y-4">
        <div 
          className="flex items-start space-x-3 hover:bg-gray-50 p-2 rounded-lg cursor-pointer"
        >
          <div className="flex-shrink-0">
            {props.icon}
          </div>
          <div className="flex-1 min-w-0">
            <p className="text-sm text-gray-900">
              {props.message}
            </p>
            <p className="text-xs text-gray-500">
              {props.time}
            </p>
          </div>
        </div>
    </div>
  );
};

export default NotificationInner;