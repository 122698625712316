import React,{useState} from 'react'
import { BuildingOffice2Icon, EnvelopeIcon, PhoneIcon } from '@heroicons/react/24/outline'
import CountryDatas from '../sampleDatas/CountryDatas'
import StateDatas from '../sampleDatas/StateDatas'
import DropdownSelect from '../DropdownSelect'
import SingleRangeSlider from '../SingleRangeSlider'
import MultiSelectOption from '../MultiSelectOption'
import Skills from '../sampleDatas/Skills'
import PhoneInputWithCode from '../PhoneInputWithCode'
import DropdownNInput from '../DropdownNInput'
import AddCircularBtn from '../AddCircularBtn'
import mentorSubmitStore from '../../Store/mentorRegisterStore'
import TextInputBox from '../TextInputBox'
import { useNavigate } from 'react-router-dom'
import axios, * as others from 'axios';


const MentorRegister = () => {

    const [selectedCountry, setSelectedCountry] = useState(1);
    const [selectedState, setSelectedState] = useState("");
    const navigateTo = useNavigate() 
    
    const { FirstName, updateFirstName,  LastName, updateLastName,Email, updateEmail, 
      Country, updateCountry, State, updateState, Profession, updateProfession,
      SkillSets, addSkillSet, deleteSkillSet, clearSkillSets, Exp, updateExp, Phn, updatePhn, PhnCode, updatePhnCode } = mentorSubmitStore();

    
    const filteredState  = StateDatas.filter(
      (state) => state.country_id === selectedCountry
    );

    const submitForm = async (e) =>{
      
      if(validateMRForm()){
         await axios.post('/server/home',  {
             "FirstName":FirstName,
             "LastName":LastName,
             "Email":Email,
             "Country":Country,
             "State":State,
             "Profession":Profession,
             "Exp":Exp, 
             "Phn":Phn,
             "PhnCode":PhnCode,
             "SkillSets":SkillSets 
        })
        .then((response) => {
          if(response.data==="SUCCESS"){
            navigateTo("/app/mentorSubmitSuccess")
          }else{
            alert("Internal Error Happened")
          }
        })
        
      }else{
        alert("Please Correct the follwing error")
      }
      
    }
    
    const validateMRForm= (e) =>{

      if(FirstName==null || FirstName=="" || FirstName==undefined){
        return false;
      }

      if(LastName==null || LastName=="" || LastName==undefined){
        return false;
      }
      if(Country==null || Country=="" || Country==undefined){
        return false;
      }

      if(State==null || State=="" || State==undefined){
        return false;
      }
      if(Profession==null || Profession=="" || Profession==undefined){
        return false;
      }
      if(Exp==null || Exp=="" || Exp==undefined){
        return false;
      }
      // if(SkillSets==null || SkillSets=="" || SkillSets==undefined){
      //   return false;
      // }
      if(Phn==null || Phn=="" || Phn==undefined){
        return false;
      }
      if(PhnCode==null || PhnCode=="" || PhnCode==undefined){
        return false;
      }
      if(Email==null || Email=="" || Email==undefined){
        return false;
      }

      return true
    }




    const handleStateChange = (obj) => {
        setSelectedState(obj.value);
        updateState(obj.value)
    };

    const handleCountryChange = (obj) => {
        setSelectedCountry(obj.id);
        updateCountry(obj.value)
        updatePhnCode(obj.phoneCode)
        setSelectedState(""); // Reset state when country changes
    };
    

  return (
    <div className="relative isolate bg-white h-full overflow-y-scroll">
      <div className="mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2">
        <div className="relative px-6 pb-20 pt-24 sm:pt-32 lg:static lg:px-8 lg:py-48">
          <div className="mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
            <div className="absolute inset-y-0 left-0 -z-10 w-full overflow-hidden bg-gray-100 ring-1 ring-gray-900/10 lg:w-1/2">
              <svg aria-hidden="true" className="absolute inset-0 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]">
                <defs>
                  <pattern x="100%" y={-1} id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527" width={200} height={200} patternUnits="userSpaceOnUse">
                    <path d="M130 200V.5M.5 .5H200" fill="none" />
                  </pattern>
                </defs>
                <rect fill="white" width="100%" height="100%" strokeWidth={0} />
                <svg x="100%" y={-1} className="overflow-visible fill-gray-50">
                  <path d="M-470.5 0h201v201h-201Z" strokeWidth={0} />
                </svg>
                <rect fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)" width="100%" height="100%" strokeWidth={0} />
              </svg>
            </div>
            <h2 className="text-pretty text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl"> Get in touch </h2>
            <p className="mt-6 text-lg/8 text-gray-600">
              Proin volutpat consequat porttitor cras nullam gravida at. Orci molestie a eu arcu. Sed ut tincidunt
              integer elementum id sem. Arcu sed malesuada et magna.
            </p>
            <dl className="mt-10 space-y-4 text-base/7 text-gray-600">
              <div className="flex gap-x-4">
                <dt className="flex-none">
                  <span className="sr-only">Address</span>
                  <BuildingOffice2Icon aria-hidden="true" className="h-7 w-6 text-gray-400" />
                </dt>
                <dd>
                  545 Mavis Island
                  <br />
                  Chicago, IL 99191
                </dd>
              </div>
              <div className="flex gap-x-4">
                <dt className="flex-none">
                  <span className="sr-only">Telephone</span>
                  <PhoneIcon aria-hidden="true" className="h-7 w-6 text-gray-400" />
                </dt>
                <dd>
                  <a href="tel:+1 (555) 234-5678" className="hover:text-gray-900">
                    +1 (555) 234-5678
                  </a>
                </dd>
              </div>
              <div className="flex gap-x-4">
                <dt className="flex-none">
                  <span className="sr-only">Email</span>
                  <EnvelopeIcon aria-hidden="true" className="h-7 w-6 text-gray-400" />
                </dt>
                <dd>
                  <a href="mailto:hello@example.com" className="hover:text-gray-900">
                    hello@example.com
                  </a>
                </dd>
              </div>
            </dl>
          </div>
        </div>
        <div  className="px-6 pb-24 pt-32 sm:pb-32 lg:px-8 lg:py-25   h-screen overflow-y-scroll">
          <div className="mx-auto max-w-xl lg:mr-0 lg:max-w-lg">
            <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
              <div>
                <label htmlFor="first-name" className="block text-left text-sm/6 font-semibold text-gray-900">
                  First name
                </label>
                <div className="mt-2.5">
                  <TextInputBox id="first-name" value={FirstName} onChangeCallback={(newVal)=>{updateFirstName(newVal)}}/>                  
                </div>
              </div>
              <div>
                <label htmlFor="last-name" className="block text-sm/6 font-semibold text-gray-900 text-left">
                  Last name
                </label>
                <div className="mt-2.5">
                  <TextInputBox id="last-name" value={LastName} onChangeCallback={(newVal)=>{updateLastName(newVal)}}/>
                </div>
              </div>



              <div className="sm:col-span-2">
                <label htmlFor="email" className="block text-sm/6 font-semibold text-gray-900 text-left">
                  Email
                </label>
                <div className="mt-2.5">
                  <TextInputBox id="email" value={Email} onChangeCallback={(newVal)=>{updateEmail(newVal)}}/>
                </div>
              </div>
                

              <div>
                <label htmlFor="Country" className="block text-sm/6 font-semibold text-gray-900 text-left">Country</label>
                <div className='mt-2.5'>
                  <DropdownSelect options={CountryDatas} onChangeCallback={(newVal)=>{handleCountryChange(newVal)}}/>
                </div>
              </div>

              <div>
                <label htmlFor="Country" className="block text-sm/6 font-semibold text-gray-900 text-left">State</label>
                <div className='mt-2.5'>
                  <DropdownSelect options={filteredState} onChangeCallback={(newVal)=>{handleStateChange(newVal)}}/>
                </div>
              </div>

              <div>
                <label htmlFor="Country" className="block text-sm/6 font-semibold text-gray-900 text-left">Profession</label>
                <div className='mt-2.5'>
                  <DropdownSelect 
                    options={[{value: "1",label:"I am a software Developer"},{value: "2",label:"I am a Full time Mentor"},{value: "3",label:"I am a Trainer"},]}  
                    onChangeCallback={(currObj)=>{updateProfession(currObj.value)}}  
                  />
                </div>
              </div>

              <div className="sm:col-span-2">
                <label htmlFor="email" className="block text-sm/6 font-semibold text-gray-900 text-left">
                  Skills
                </label>
                <div className="mt-2.5 shadow-sm">
                  <MultiSelectOption options={Skills}
                    isFilter={false}
                    tokens={SkillSets}
                    addToken={addSkillSet}
                    removeToken={deleteSkillSet}
                    clearTokens={clearSkillSets} />
                  </div>
              </div>
              
              <div className="sm:col-span-2">
                <label htmlFor="email" className="block text-sm/6 font-semibold text-gray-900 text-left">
                  Experience
                </label>
                <div className="mt-2.5">
                  <SingleRangeSlider 
                    min={1} 
                    max={21}
                    onChangeCallback={(newVal)=>{updateExp(newVal)}}
                  />
                  </div>
              </div>
                            
              <div className="sm:col-span-2">
                <label htmlFor="email" className="block text-sm/6 font-semibold text-gray-900 text-left">
                  Phone
                </label>
                <div className="mt-2.5">
                  <PhoneInputWithCode PhnCode={PhnCode} onChangeCallback={(newVal)=>{updatePhn(newVal)}} ></PhoneInputWithCode>
                  </div>
              </div>

              <div className="sm:col-span-2">
                <label htmlFor="email" className="block text-sm/6 font-semibold text-gray-900 text-left">
                  Website
                </label>
                <div className="mt-2.5  ">
                  <DropdownNInput options={["Facebook", "LinkedIn", "Personal"]}/>
                </div> 
              </div>

            </div>
            <div className="mt-8 flex justify-end">
              <button type="button" onClick={(e)=>{submitForm()}} className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                Send message
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MentorRegister