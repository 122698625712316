import React,{useEffect,useState} from 'react'
import LoadingSuccess from './LoadingSuccess';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

const LoadingDots = (props) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  const handleContinue = () => {
    // Handle the continue action
    console.log('Continue clicked');
    navigate("/app/user/bookings")
  };

  return (
    <LoadingSuccess
      isLoading={isLoading}
      loadingText="Please wait while we process your request..."
      successContent={
        <div className="space-y-2">
          <h2 className="text-2xl font-semibold text-gray-800">Success!</h2>
          <p className="text-gray-600">Your meeting is scheduled<br/></p>
        </div>
      }
      onActionClick={handleContinue}
      actionButtonText="View Schedule"
    />
  );
};

// Add PropTypes
LoadingDots.propTypes = {
  onContinue: PropTypes.func
};

export default LoadingDots