
const CountryDatas =  [
    
    {id: "1",phoneCode: "+93",value: "Afghanistan",label:"Afghanistan"},
    {id: "2",phoneCode: "+355",value: "Albania",label:"Albania"},
    {id: "3",phoneCode: "+213",value: "Algeria",label:"Algeria"},
    {id: "4",phoneCode: "+1684",value: "American Samoa",label:"American Samoa"},
    {id: "5",phoneCode: "+376",value: "Andorra",label:"Andorra"},
    {id: "6",phoneCode: "+244",value: "Angola",label:"Angola"},
    {id: "7",phoneCode: "+1264",value: "Anguilla",label:"Anguilla"},
    {id: "8",phoneCode: "+0",value: "Antarctica",label:"Antarctica"},
    {id: "9",phoneCode: "+1268",value: "Antigua And Barbuda",label:"Antigua And Barbuda"},
    {id: "10",phoneCode: "+54",value: "Argentina",label:"Argentina"},
    {id: "11",phoneCode: "+374",value: "Armenia",label:"Armenia"},
    {id: "12",phoneCode: "+297",value: "Aruba",label:"Aruba"},
    {id: "13",phoneCode: "+61",value: "Australia",label:"Australia"},
    {id: "14",phoneCode: "+43",value: "Austria",label:"Austria"},
    {id: "15",phoneCode: "+994",value: "Azerbaijan",label:"Azerbaijan"},
    {id: "16",phoneCode: "+1242",value: "Bahamas The",label:"Bahamas The"},
    {id: "17",phoneCode: "+973",value: "Bahrain",label:"Bahrain"},
    {id: "18",phoneCode: "+880",value: "Bangladesh",label:"Bangladesh"},
    {id: "19",phoneCode: "+1246",value: "Barbados",label:"Barbados"},
    {id: "20",phoneCode: "+375",value: "Belarus",label:"Belarus"},
    {id: "21",phoneCode: "+32",value: "Belgium",label:"Belgium"},
    {id: "22",phoneCode: "+501",value: "Belize",label:"Belize"},
    {id: "23",phoneCode: "+229",value: "Benin",label:"Benin"},
    {id: "24",phoneCode: "+1441",value: "Bermuda",label:"Bermuda"},
    {id: "25",phoneCode: "+975",value: "Bhutan",label:"Bhutan"},
    {id: "26",phoneCode: "+591",value: "Bolivia",label:"Bolivia"},
    {id: "27",phoneCode: "+387",value: "Bosnia and Herzegovina",label:"Bosnia and Herzegovina"},
    {id: "28",phoneCode: "+267",value: "Botswana",label:"Botswana"},
    {id: "29",phoneCode: "+0",value: "Bouvet Island",label:"Bouvet Island"},
    {id: "30",phoneCode: "+55",value: "Brazil",label:"Brazil"},
    {id: "31",phoneCode: "+246",value: "British Indian Ocean Territory",label:"British Indian Ocean Territory"},
    {id: "32",phoneCode: "+673",value: "Brunei",label:"Brunei"},
    {id: "33",phoneCode: "+359",value: "Bulgaria",label:"Bulgaria"},
    {id: "34",phoneCode: "+226",value: "Burkina Faso",label:"Burkina Faso"},
    {id: "35",phoneCode: "+257",value: "Burundi",label:"Burundi"},
    {id: "36",phoneCode: "+855",value: "Cambodia",label:"Cambodia"},
    {id: "37",phoneCode: "+237",value: "Cameroon",label:"Cameroon"},
    {id: "38",phoneCode: "+1",value: "Canada",label:"Canada"},
    {id: "39",phoneCode: "+238",value: "Cape Verde",label:"Cape Verde"},
    {id: "40",phoneCode: "+1345",value: "Cayman Islands",label:"Cayman Islands"},
    {id: "41",phoneCode: "+236",value: "Central African Republic",label:"Central African Republic"},
    {id: "42",phoneCode: "+235",value: "Chad",label:"Chad"},
    {id: "43",phoneCode: "+56",value: "Chile",label:"Chile"},
    {id: "44",phoneCode: "+86",value: "China",label:"China"},
    {id: "45",phoneCode: "+61",value: "Christmas Island",label:"Christmas Island"},
    {id: "46",phoneCode: "+672",value: "Cocos (Keeling) Islands",label:"Cocos (Keeling) Islands"},
    {id: "47",phoneCode: "+57",value: "Colombia",label:"Colombia"},
    {id: "48",phoneCode: "+269",value: "Comoros",label:"Comoros"},
    {id: "49",phoneCode: "+242",value: "Republic Of The Congo",label:"Republic Of The Congo"},
    {id: "50",phoneCode: "+242",value: "Democratic Republic Of The Congo",label:"Democratic Republic Of The Congo"},
    {id: "51",phoneCode: "+682",value: "Cook Islands",label:"Cook Islands"},
    {id: "52",phoneCode: "+506",value: "Costa Rica",label:"Costa Rica"},
    {id: "53",phoneCode: "+225",value: "Cote D''Ivoire (Ivory Coast)",label:"Cote D''Ivoire (Ivory Coast)"},
    {id: "54",phoneCode: "+385",value: "Croatia (Hrvatska)",label:"Croatia (Hrvatska)"},
    {id: "55",phoneCode: "+53",value: "Cuba",label:"Cuba"},
    {id: "56",phoneCode: "+357",value: "Cyprus",label:"Cyprus"},
    {id: "57",phoneCode: "+420",value: "Czech Republic",label:"Czech Republic"},
    {id: "58",phoneCode: "+45",value: "Denmark",label:"Denmark"},
    {id: "59",phoneCode: "+253",value: "Djibouti",label:"Djibouti"},
    {id: "60",phoneCode: "+1767",value: "Dominica",label:"Dominica"},
    {id: "61",phoneCode: "+1809",value: "Dominican Republic",label:"Dominican Republic"},
    {id: "62",phoneCode: "+670",value: "East Timor",label:"East Timor"},
    {id: "63",phoneCode: "+593",value: "Ecuador",label:"Ecuador"},
    {id: "64",phoneCode: "+20",value: "Egypt",label:"Egypt"},
    {id: "65",phoneCode: "+503",value: "El Salvador",label:"El Salvador"},
    {id: "66",phoneCode: "+240",value: "Equatorial Guinea",label:"Equatorial Guinea"},
    {id: "67",phoneCode: "+291",value: "Eritrea",label:"Eritrea"},
    {id: "68",phoneCode: "+372",value: "Estonia",label:"Estonia"},
    {id: "69",phoneCode: "+251",value: "Ethiopia",label:"Ethiopia"},
    {id: "70",phoneCode: "+61",value: "External Territories of Australia",label:"External Territories of Australia"},
    {id: "71",phoneCode: "+500",value: "Falkland Islands",label:"Falkland Islands"},
    {id: "72",phoneCode: "+298",value: "Faroe Islands",label:"Faroe Islands"},
    {id: "73",phoneCode: "+679",value: "Fiji Islands",label:"Fiji Islands"},
    {id: "74",phoneCode: "+358",value: "Finland",label:"Finland"},
    {id: "75",phoneCode: "+33",value: "France",label:"France"},
    {id: "76",phoneCode: "+594",value: "French Guiana",label:"French Guiana"},
    {id: "77",phoneCode: "+689",value: "French Polynesia",label:"French Polynesia"},
    {id: "78",phoneCode: "+0",value: "French Southern Territories",label:"French Southern Territories"},
    {id: "79",phoneCode: "+241",value: "Gabon",label:"Gabon"},
    {id: "80",phoneCode: "+220",value: "Gambia The",label:"Gambia The"},
    {id: "81",phoneCode: "+995",value: "Georgia",label:"Georgia"},
    {id: "82",phoneCode: "+49",value: "Germany",label:"Germany"},
    {id: "83",phoneCode: "+233",value: "Ghana",label:"Ghana"},
    {id: "84",phoneCode: "+350",value: "Gibraltar",label:"Gibraltar"},
    {id: "85",phoneCode: "+30",value: "Greece",label:"Greece"},
    {id: "86",phoneCode: "+299",value: "Greenland",label:"Greenland"},
    {id: "87",phoneCode: "+1473",value: "Grenada",label:"Grenada"},
    {id: "88",phoneCode: "+590",value: "Guadeloupe",label:"Guadeloupe"},
    {id: "89",phoneCode: "+1671",value: "Guam",label:"Guam"},
    {id: "90",phoneCode: "+502",value: "Guatemala",label:"Guatemala"},
    {id: "91",phoneCode: "+44",value: "Guernsey and Alderney",label:"Guernsey and Alderney"},
    {id: "92",phoneCode: "+224",value: "Guinea",label:"Guinea"},
    {id: "93",phoneCode: "+245",value: "Guinea-Bissau",label:"Guinea-Bissau"},
    {id: "94",phoneCode: "+592",value: "Guyana",label:"Guyana"},
    {id: "95",phoneCode: "+509",value: "Haiti",label:"Haiti"},
    {id: "96",phoneCode: "+0",value: "Heard and McDonald Islands",label:"Heard and McDonald Islands"},
    {id: "97",phoneCode: "+504",value: "Honduras",label:"Honduras"},
    {id: "98",phoneCode: "+852",value: "Hong Kong S.A.R.",label:"Hong Kong S.A.R."},
    {id: "99",phoneCode: "+36",value: "Hungary",label:"Hungary"},
    {id: "100",phoneCode: "+354",value: "Iceland",label:"Iceland"},
    {id: "101",phoneCode: "+91",value: "India",label:"India"},
    {id: "102",phoneCode: "+62",value: "Indonesia",label:"Indonesia"},
    {id: "103",phoneCode: "+98",value: "Iran",label:"Iran"},
    {id: "104",phoneCode: "+964",value: "Iraq",label:"Iraq"},
    {id: "105",phoneCode: "+353",value: "Ireland",label:"Ireland"},
    {id: "106",phoneCode: "+972",value: "Israel",label:"Israel"},
    {id: "107",phoneCode: "+39",value: "Italy",label:"Italy"},
    {id: "108",phoneCode: "+1876",value: "Jamaica",label:"Jamaica"},
    {id: "109",phoneCode: "+81",value: "Japan",label:"Japan"},
    {id: "110",phoneCode: "+44",value: "Jersey",label:"Jersey"},
    {id: "111",phoneCode: "+962",value: "Jordan",label:"Jordan"},
    {id: "112",phoneCode: "+7",value: "Kazakhstan",label:"Kazakhstan"},
    {id: "113",phoneCode: "+254",value: "Kenya",label:"Kenya"},
    {id: "114",phoneCode: "+686",value: "Kiribati",label:"Kiribati"},
    {id: "115",phoneCode: "+850",value: "Korea North",label:"Korea North"},
    {id: "116",phoneCode: "+82",value: "Korea South",label:"Korea South"},
    {id: "117",phoneCode: "+965",value: "Kuwait",label:"Kuwait"},
    {id: "118",phoneCode: "+996",value: "Kyrgyzstan",label:"Kyrgyzstan"},
    {id: "119",phoneCode: "+856",value: "Laos",label:"Laos"},
    {id: "120",phoneCode: "+371",value: "Latvia",label:"Latvia"},
    {id: "121",phoneCode: "+961",value: "Lebanon",label:"Lebanon"},
    {id: "122",phoneCode: "+266",value: "Lesotho",label:"Lesotho"},
    {id: "123",phoneCode: "+231",value: "Liberia",label:"Liberia"},
    {id: "124",phoneCode: "+218",value: "Libya",label:"Libya"},
    {id: "125",phoneCode: "+423",value: "Liechtenstein",label:"Liechtenstein"},
    {id: "126",phoneCode: "+370",value: "Lithuania",label:"Lithuania"},
    {id: "127",phoneCode: "+352",value: "Luxembourg",label:"Luxembourg"},
    {id: "128",phoneCode: "+853",value: "Macau S.A.R.",label:"Macau S.A.R."},
    {id: "129",phoneCode: "+389",value: "Macedonia",label:"Macedonia"},
    {id: "130",phoneCode: "+261",value: "Madagascar",label:"Madagascar"},
    {id: "131",phoneCode: "+265",value: "Malawi",label:"Malawi"},
    {id: "132",phoneCode: "+60",value: "Malaysia",label:"Malaysia"},
    {id: "133",phoneCode: "+960",value: "Maldives",label:"Maldives"},
    {id: "134",phoneCode: "+223",value: "Mali",label:"Mali"},
    {id: "135",phoneCode: "+356",value: "Malta",label:"Malta"},
    {id: "136",phoneCode: "+44",value: "Man (Isle of)",label:"Man (Isle of)"},
    {id: "137",phoneCode: "+692",value: "Marshall Islands",label:"Marshall Islands"},
    {id: "138",phoneCode: "+596",value: "Martinique",label:"Martinique"},
    {id: "139",phoneCode: "+222",value: "Mauritania",label:"Mauritania"},
    {id: "140",phoneCode: "+230",value: "Mauritius",label:"Mauritius"},
    {id: "141",phoneCode: "+269",value: "Mayotte",label:"Mayotte"},
    {id: "142",phoneCode: "+52",value: "Mexico",label:"Mexico"},
    {id: "143",phoneCode: "+691",value: "Micronesia",label:"Micronesia"},
    {id: "144",phoneCode: "+373",value: "Moldova",label:"Moldova"},
    {id: "145",phoneCode: "+377",value: "Monaco",label:"Monaco"},
    {id: "146",phoneCode: "+976",value: "Mongolia",label:"Mongolia"},
    {id: "147",phoneCode: "+1664",value: "Montserrat",label:"Montserrat"},
    {id: "148",phoneCode: "+212",value: "Morocco",label:"Morocco"},
    {id: "149",phoneCode: "+258",value: "Mozambique",label:"Mozambique"},
    {id: "150",phoneCode: "+95",value: "Myanmar",label:"Myanmar"},
    {id: "151",phoneCode: "+264",value: "Namibia",label:"Namibia"},
    {id: "152",phoneCode: "+674",value: "Nauru",label:"Nauru"},
    {id: "153",phoneCode: "+977",value: "Nepal",label:"Nepal"},
    {id: "154",phoneCode: "+599",value: "Netherlands Antilles",label:"Netherlands Antilles"},
    {id: "155",phoneCode: "+31",value: "Netherlands The",label:"Netherlands The"},
    {id: "156",phoneCode: "+687",value: "New Caledonia",label:"New Caledonia"},
    {id: "157",phoneCode: "+64",value: "New Zealand",label:"New Zealand"},
    {id: "158",phoneCode: "+505",value: "Nicaragua",label:"Nicaragua"},
    {id: "159",phoneCode: "+227",value: "Niger",label:"Niger"},
    {id: "160",phoneCode: "+234",value: "Nigeria",label:"Nigeria"},
    {id: "161",phoneCode: "+683",value: "Niue",label:"Niue"},
    {id: "162",phoneCode: "+672",value: "Norfolk Island",label:"Norfolk Island"},
    {id: "163",phoneCode: "+1670",value: "Northern Mariana Islands",label:"Northern Mariana Islands"},
    {id: "164",phoneCode: "+47",value: "Norway",label:"Norway"},
    {id: "165",phoneCode: "+968",value: "Oman",label:"Oman"},
    {id: "166",phoneCode: "+92",value: "Pakistan",label:"Pakistan"},
    {id: "167",phoneCode: "+680",value: "Palau",label:"Palau"},
    {id: "168",phoneCode: "+970",value: "Palestinian Territory Occupied",label:"Palestinian Territory Occupied"},
    {id: "169",phoneCode: "+507",value: "Panama",label:"Panama"},
    {id: "170",phoneCode: "+675",value: "Papua new Guinea",label:"Papua new Guinea"},
    {id: "171",phoneCode: "+595",value: "Paraguay",label:"Paraguay"},
    {id: "172",phoneCode: "+51",value: "Peru",label:"Peru"},
    {id: "173",phoneCode: "+63",value: "Philippines",label:"Philippines"},
    {id: "174",phoneCode: "+0",value: "Pitcairn Island",label:"Pitcairn Island"},
    {id: "175",phoneCode: "+48",value: "Poland",label:"Poland"},
    {id: "176",phoneCode: "+351",value: "Portugal",label:"Portugal"},
    {id: "177",phoneCode: "+1787",value: "Puerto Rico",label:"Puerto Rico"},
    {id: "178",phoneCode: "+974",value: "Qatar",label:"Qatar"},
    {id: "179",phoneCode: "+262",value: "Reunion",label:"Reunion"},
    {id: "180",phoneCode: "+40",value: "Romania",label:"Romania"},
    {id: "181",phoneCode: "+70",value: "Russia",label:"Russia"},
    {id: "182",phoneCode: "+250",value: "Rwanda",label:"Rwanda"},
    {id: "183",phoneCode: "+290",value: "Saint Helena",label:"Saint Helena"},
    {id: "184",phoneCode: "+1869",value: "Saint Kitts And Nevis",label:"Saint Kitts And Nevis"},
    {id: "185",phoneCode: "+1758",value: "Saint Lucia",label:"Saint Lucia"},
    {id: "186",phoneCode: "+508",value: "Saint Pierre and Miquelon",label:"Saint Pierre and Miquelon"},
    {id: "187",phoneCode: "+1784",value: "Saint Vincent And The Grenadines",label:"Saint Vincent And The Grenadines"},
    {id: "188",phoneCode: "+684",value: "Samoa",label:"Samoa"},
    {id: "189",phoneCode: "+378",value: "San Marino",label:"San Marino"},
    {id: "190",phoneCode: "+239",value: "Sao Tome and Principe",label:"Sao Tome and Principe"},
    {id: "191",phoneCode: "+966",value: "Saudi Arabia",label:"Saudi Arabia"},
    {id: "192",phoneCode: "+221",value: "Senegal",label:"Senegal"},
    {id: "193",phoneCode: "+381",value: "Serbia",label:"Serbia"},
    {id: "194",phoneCode: "+248",value: "Seychelles",label:"Seychelles"},
    {id: "195",phoneCode: "+232",value: "Sierra Leone",label:"Sierra Leone"},
    {id: "196",phoneCode: "+65",value: "Singapore",label:"Singapore"},
    {id: "197",phoneCode: "+421",value: "Slovakia",label:"Slovakia"},
    {id: "198",phoneCode: "+386",value: "Slovenia",label:"Slovenia"},
    {id: "199",phoneCode: "+44",value: "Smaller Territories of the UK",label:"Smaller Territories of the UK"},
    {id: "200",phoneCode: "+677",value: "Solomon Islands",label:"Solomon Islands"},
    {id: "201",phoneCode: "+252",value: "Somalia",label:"Somalia"},
    {id: "202",phoneCode: "+27",value: "South Africa",label:"South Africa"},
    {id: "203",phoneCode: "+0",value: "South Georgia",label:"South Georgia"},
    {id: "204",phoneCode: "+211",value: "South Sudan",label:"South Sudan"},
    {id: "205",phoneCode: "+34",value: "Spain",label:"Spain"},
    {id: "206",phoneCode: "+94",value: "Sri Lanka",label:"Sri Lanka"},
    {id: "207",phoneCode: "+249",value: "Sudan",label:"Sudan"},
    {id: "208",phoneCode: "+597",value: "Surivalue",label:"Surivalue"},
    {id: "209",phoneCode: "+47",value: "Svalbard And Jan Mayen Islands",label:"Svalbard And Jan Mayen Islands"},
    {id: "210",phoneCode: "+268",value: "Swaziland",label:"Swaziland"},
    {id: "211",phoneCode: "+46",value: "Sweden",label:"Sweden"},
    {id: "212",phoneCode: "+41",value: "Switzerland",label:"Switzerland"},
    {id: "213",phoneCode: "+963",value: "Syria",label:"Syria"},
    {id: "214",phoneCode: "+886",value: "Taiwan",label:"Taiwan"},
    {id: "215",phoneCode: "+992",value: "Tajikistan",label:"Tajikistan"},
    {id: "216",phoneCode: "+255",value: "Tanzania",label:"Tanzania"},
    {id: "217",phoneCode: "+66",value: "Thailand",label:"Thailand"},
    {id: "218",phoneCode: "+228",value: "Togo",label:"Togo"},
    {id: "219",phoneCode: "+690",value: "Tokelau",label:"Tokelau"},
    {id: "220",phoneCode: "+676",value: "Tonga",label:"Tonga"},
    {id: "221",phoneCode: "+1868",value: "Trinidad And Tobago",label:"Trinidad And Tobago"},
    {id: "222",phoneCode: "+216",value: "Tunisia",label:"Tunisia"},
    {id: "223",phoneCode: "+90",value: "Turkey",label:"Turkey"},
    {id: "224",phoneCode: "+7370",value: "Turkmenistan",label:"Turkmenistan"},
    {id: "225",phoneCode: "+1649",value: "Turks And Caicos Islands",label:"Turks And Caicos Islands"},
    {id: "226",phoneCode: "+688",value: "Tuvalu",label:"Tuvalu"},
    {id: "227",phoneCode: "+256",value: "Uganda",label:"Uganda"},
    {id: "228",phoneCode: "+380",value: "Ukraine",label:"Ukraine"},
    {id: "229",phoneCode: "+971",value: "United Arab Emirates",label:"United Arab Emirates"},
    {id: "230",phoneCode: "+44",value: "United Kingdom",label:"United Kingdom"},
    {id: "231",phoneCode: "+1",value: "United States",label:"United States"},
    {id: "232",phoneCode: "+1",value: "United States Minor Outlying Islands",label:"United States Minor Outlying Islands"},
    {id: "233",phoneCode: "+598",value: "Uruguay",label:"Uruguay"},
    {id: "234",phoneCode: "+998",value: "Uzbekistan",label:"Uzbekistan"},
    {id: "235",phoneCode: "+678",value: "Vanuatu",label:"Vanuatu"},
    {id: "236",phoneCode: "+39",value: "Vatican City State (Holy See)",label:"Vatican City State (Holy See)"},
    {id: "237",phoneCode: "+58",value: "Venezuela",label:"Venezuela"},
    {id: "238",phoneCode: "+84",value: "Vietnam",label:"Vietnam"},
    {id: "239",phoneCode: "+1284",value: "Virgin Islands (British)",label:"Virgin Islands (British)"},
    {id: "240",phoneCode: "+1340",value: "Virgin Islands (US)",label:"Virgin Islands (US)"},
    {id: "241",phoneCode: "+681",value: "Wallis And Futuna Islands",label:"Wallis And Futuna Islands"},
    {id: "242",phoneCode: "+212",value: "Western Sahara",label:"Western Sahara"},
    {id: "243",phoneCode: "+967",value: "Yemen",label:"Yemen"},
    {id: "244",phoneCode: "+38",value: "Yugoslavia",label:"Yugoslavia"},
    {id: "245",phoneCode: "+260",value: "Zambia",label:"Zambia"},
    {id: "246",phoneCode: "+26",value: "Zimbabwe",label:"Zimbabwe"},

]

export default CountryDatas