import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { formatDuration, intervalToDuration } from 'date-fns';
import { ReactComponent as NoCall } from '../../../../public/Assets/NoCall.svg'

const UpcomingCalls = () => {
    const [upcomingCalls, setUpcomingCalls] = useState([]);

    useEffect(() => {
        const fetchUpcomingCalls = async () => {
            try {
                const response = await axios.get('/server/userActions/getUpcomingSessions');
                setUpcomingCalls(response.data);
            } catch (error) {
                console.error('Error fetching upcoming calls:', error);
            }
        };

        fetchUpcomingCalls();
    }, []);

    const getTimeUntil = (date, time) => {
        try {
            if (!date || !time) return 'Date unavailable';
            
            // Convert 12-hour time format to 24-hour format
            const [rawTime, period] = time.split(' ');
            let [hours, minutes] = rawTime.split(':');
            hours = parseInt(hours);
            
            // Convert to 24-hour format
            if (period === 'PM' && hours < 12) hours += 12;
            if (period === 'AM' && hours === 12) hours = 0;
            
            // Pad hours with leading zero if needed
            const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes}`;
            
            // Combine date and time into a single string
            const dateTimeString = `${date}T${formattedTime}`;
            
            const meetingDate = new Date(dateTimeString);

            if (isNaN(meetingDate.getTime())) {
                console.error('Invalid date format:', dateTimeString);
                return 'Date unavailable';
            }

            const duration = intervalToDuration({
                start: new Date(),
                end: meetingDate
            });

            const formattedDuration = formatDuration(duration, {
                format: ['days', 'hours', 'minutes'],
                delimiter: ' '
            });

            return formattedDuration ? `in ${formattedDuration}` : 'now';

        } catch (error) {
            console.error('Error parsing date:', error);
            return 'Date unavailable';
        }
    };

    return (
        (!Array.isArray(upcomingCalls) || upcomingCalls.length === 0) ? 
        <div className="text-center text-gray-500 flex flex-col items-center justify-center  mt-5">
            
            <div className="text-gray-500 w-36 h-36 ">
            <NoCall />

            </div>
            
             No upcoming calls</div> :
        <ul role="list" className="divide-y divide-gray-100">
            {upcomingCalls.map((call) => (
                <li key={call.MeetingName} className="flex gap-x-4 py-5">
                    <img alt="" src={"https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60"} className="h-12 w-12 flex-none rounded-full bg-gray-50" />
                    <div className="flex-auto">
                        <div className="flex items-baseline justify-between gap-x-4">
                            <p className="text-sm/6 font-semibold text-right text-gray-900">{call.Name}</p>
                            <p className="flex-none text-xs text-gray-600">
                                {getTimeUntil(call.Meeting_Date, call.Meeting_DateTime)}
                            </p>
                        </div>
                        <p className="mt-1 line-clamp-2 text-sm/6 text-gray-600">
                            {call.Meeting_Date} {call.Meeting_DateTime}
                        </p>
                    </div>
                </li>
            ))}
        </ul>
    );
};

export default UpcomingCalls