import React, { useState } from 'react'
import TopBandWithClose from './Components/TopBandWithClose'
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react'


const CommonModal = (props) => {
//  let panelStyle =
  return (
    <>
    <Dialog open={props.isOpen} onClose={()=>{props.setIsOpen(false)}} className="relative z-10">
    <DialogBackdrop transition
      className="fixed inset-0 bg-gray-500/75 transition-opacity  data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
    />
    <div className="fixed inset-0 z-10 overflow-y-auto">
      <div className="flex min-h-full items-center justify-center p-4 text-center">
        <DialogPanel transition 
        className="relative transform overflow-y-scroll rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 w-[800px] mx-auto data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
        style={{ maxHeight: '80vh' }}
        >
          <div className="flex justify-end mb-4">
            <TopBandWithClose setIsOpen={props.setIsOpen} />
          </div>
          {props.element[0]}
        </DialogPanel>
      </div>
    </div>
  </Dialog>:<></>
  </>
  )
}
function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}
export default CommonModal