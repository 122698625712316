 const Companies = [
    {value : "Amazon", label : "Amazon"},
    {value : "Ajio", label : "Ajio"},
    {value : "Flipcart", label : "Flipcart"},
    {value : "Paypal", label : "Paypal"},
    {value : "Zoho", label : "Zoho"},
    {value : "FreshWorks", label : "FreshWorks"},
    {value : "Cambly", label : "Cambly"},
    {value : "TCS", label : "TCS"},
    {value : "CTS", label : "CTS"},
    {value : "Comcast", label : "Comcast"},
    {value : "Atos", label : "Atos"},
    {value : "Paytm", label : "Paytm"},
    {value : "Google", label : "Google"},
    {value : "Goldman schachs", label : "Goldman schachs"},
    {value : "Wipro", label : "Wipro"},
    {value : "Accenture", label : "Accenture"},
    {value : "L&T", label : "L&T"},
    {value : "Infosys", label : "Infosys"},
    {value : "Capegemini", label : "Capegemini"},
    {value : "Vitusa",label : "Vitusa"},
]

export default Companies