import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import { PaperClipIcon } from '@heroicons/react/20/solid'
import Subtab from '../Mentor/Subtab'
import Badge from '../Badge'
import { PencilIcon } from '@heroicons/react/24/outline'
import CountryDatas from '../sampleDatas/CountryDatas'
import StateDatas from '../sampleDatas/StateDatas'
import DropdownSelect from '../DropdownSelect'
import PhoneInputWithCode from '../PhoneInputWithCode'
import SingleRangeSlider from '../SingleRangeSlider'
import MultiSelectOption from '../MultiSelectOption'
import Skills from '../sampleDatas/Skills'
import useUserStore from '../../stores/userStore'
import { toast } from 'react-hot-toast'
import MotivationalLoader from '../MotivationalLoader'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'

const CropModal = ({ image, crop, setCrop, onComplete, onCancel }) => {
  const [completedCrop, setCompletedCrop] = useState(null);
  const imgRef = useRef(null);

  const getCroppedImg = () => {
    if (!completedCrop || !imgRef.current) return;

    const canvas = document.createElement('canvas');
    const scaleX = imgRef.current.naturalWidth / imgRef.current.width;
    const scaleY = imgRef.current.naturalHeight / imgRef.current.height;
    canvas.width = completedCrop.width;
    canvas.height = completedCrop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      imgRef.current,
      completedCrop.x * scaleX,
      completedCrop.y * scaleY,
      completedCrop.width * scaleX,
      completedCrop.height * scaleY,
      0,
      0,
      completedCrop.width,
      completedCrop.height
    );

    return canvas.toDataURL('image/jpeg');
  };

  const handleComplete = () => {
    const croppedImage = getCroppedImg();
    onComplete(croppedImage);
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-4 rounded-lg max-w-2xl w-full">
        <div className="max-h-[600px] overflow-auto">
          <ReactCrop
            crop={crop}
            onChange={(c) => setCrop(c)}
            onComplete={(c) => setCompletedCrop(c)}
            aspect={1}
          >
            <img
              ref={imgRef}
              src={image}
              alt="Crop me"
              className="max-w-full h-auto"
            />
          </ReactCrop>
        </div>
        <div className="mt-4 flex justify-end gap-2">
          <button
            onClick={onCancel}
            className="px-4 py-2 text-sm text-gray-600 hover:text-gray-800"
          >
            Cancel
          </button>
          <button
            onClick={handleComplete}
            className="px-4 py-2 text-sm bg-indigo-600 text-white rounded-md hover:bg-indigo-500"
          >
            Crop & Save
          </button>
        </div>
      </div>
    </div>
  );
};

const UserProfile = () => {
  const { userDetails, isLoading, error, fetchUserDetails, updateUserDetails } = useUserStore();
  const [isEditing, setIsEditing] = useState(false);
  const [editedDetails, setEditedDetails] = useState({});
  const [selectedCountry, setSelectedCountry] = useState(1);
  const [selectedState, setSelectedState] = useState("");
  const [showCropModal, setShowCropModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [crop, setCrop] = useState({
    unit: '%',
    width: 50,
    aspect: 1
  });
  const [croppedImage, setCroppedImage] = useState(null);
  const fileInputRef = useRef(null);

  useEffect(() => {
    fetchUserDetails();
  }, []);

  useEffect(() => {
    if (userDetails) {
      setEditedDetails({
        Firstname: userDetails.Firstname || '',
        Lastname: userDetails.Lastname || '',
        Email: userDetails.Email || '',
        AboutMe: userDetails.AboutMe || '',
        Skillsets: userDetails.Skillsets || '',
        Experience: userDetails.Experience || '',
        Country: userDetails.Country || '',
        State: userDetails.State || '',
        Phone: userDetails.Phone || '',
        PhoneCode: userDetails.PhoneCode || ''
      });

      const country = CountryDatas.find(c => c.value === userDetails.Country);
      if (country) {
        setSelectedCountry(country.id);
      }
      setSelectedState(userDetails.State || '');
    }
  }, [userDetails]);

  const handleInputChange = (field, value) => {
    setEditedDetails(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleSave = async () => {
    try {
      await updateUserDetails(editedDetails);
      setIsEditing(false);
      toast.success('Profile updated successfully');
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleStateChange = (obj) => {
    setSelectedState(obj.value);
    handleInputChange('State', obj.value);
  };

  const handleCountryChange = (obj) => {
    setSelectedCountry(obj.id);
    handleInputChange('Country', obj.value);
    setSelectedState(""); // Reset state when country changes
  };

  const filteredState = StateDatas.filter(
    (state) => state.country_id === selectedCountry
  );

  const handleCancel = () => {
    setIsEditing(false);
    // Reset edited details to original values
    setEditedDetails({
      Firstname: userDetails.Firstname || '',
      Lastname: userDetails.Lastname || '',
      Email: userDetails.Email || '',
      AboutMe: userDetails.AboutMe || '',
      Skillsets: userDetails.Skillsets || '',
      Experience: userDetails.Experience || '',
      Country: userDetails.Country || '',
      State: userDetails.State || '',
      Phone: userDetails.Phone || '',
      PhoneCode: userDetails.PhoneCode || ''
    });
  };

  const handleAddSkill = (skill) => {
    const updatedSkills = editedDetails.Skillsets ? editedDetails.Skillsets + ',' + skill : skill;
    handleInputChange('Skillsets', updatedSkills);
  };

  const handleRemoveSkill = (skill) => {
    const skillsArray = editedDetails.Skillsets.split(',');
    const updatedSkills = skillsArray.filter(s => s !== skill).join(',');
    handleInputChange('Skillsets', updatedSkills);
  };

  const handleClearSkills = () => {
    handleInputChange('Skillsets', '');
  };

  const handleEditClick = () => {
    setEditedDetails({
      Firstname: userDetails.Firstname || '',
      Lastname: userDetails.Lastname || '',
      Email: userDetails.Email || '',
      AboutMe: userDetails.AboutMe || '',
      Skillsets: userDetails.Skillsets || '',
      Experience: userDetails.Experience || '1',
      Country: userDetails.Country || '',
      State: userDetails.State || '',
      Phone: userDetails.Phone || '',
      PhoneCode: userDetails.PhoneCode || ''
    });

    const country = CountryDatas.find(c => c.value === userDetails.Country);
    if (country) {
      setSelectedCountry(country.id);
    }
    setSelectedState(userDetails.State || '');
    setIsEditing(true);
  };

  const handleAvatarClick = () => {
    fileInputRef.current.click();
  };

  const handleFileSelect = (e) => {
    if (e.target.files && e.target.files[0]) {
      const reader = new FileReader();
      reader.onload = () => {
        setSelectedFile(reader.result);
        setShowCropModal(true);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handleCropComplete = async (croppedImg) => {
    setCroppedImage(croppedImg);
    setShowCropModal(false);
    
    try {
      // Convert base64 to blob
      const base64Response = await fetch(croppedImg);
      const blob = await base64Response.blob();
      
      // Create form data
      const formData = new FormData();
      formData.append('profilePic', blob, 'profilePic.jpg');

      // Make API call
      const response = await axios.post('/server/userActions/updateProfilePic', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      if (response.data.success) {
        toast.success('Profile picture updated successfully');
      } else {
        throw new Error(response.data.error || 'Failed to update profile picture');
      }
    } catch (error) {
      toast.error(error.message || 'Error updating profile picture');
      setCroppedImage(null);
    }
  };

  if (isLoading) {
    return (
      <MotivationalLoader />
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-red-600">Error: {error}</div>
      </div>
    );
  }

  return (
    <>
      {userDetails ? (
        <div className="mx-auto max-w-7xl lg:flex fullHeight pb-16 mb-16 overflow-y-scroll lg:gap-x-16 lg:px-8">
          <main className="px-4  sm:px-6 lg:flex-auto lg:px-0 lg:py-6">
            <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
              <div>
                <h2 className="text-base/7 font-semibold text-gray-900">Basic Info</h2>
                <dl className="mt-6 space-y-6 divide-y  bord border-gray-200 text-sm/6">
                  <div className="col-span-full flex items-center justify-between">
                    <div className="flex items-center gap-x-8">
                      <img
                        alt=""
                        src={croppedImage || "https://user-documents-development.zohostratus.in/"+userDetails.UserId+"/profilePic.jpeg"}
                        className="size-24 flex-none rounded-lg bg-gray-800 object-cover"
                      />
                      <div>
                        <input
                          type="file"
                          ref={fileInputRef}
                          onChange={handleFileSelect}
                          accept="image/*"
                          className="hidden"
                        />
                        <button
                          type="button"
                          onClick={handleAvatarClick}
                          className="rounded-md bg-white/10 px-3 py-2 text-sm font-semibold text-black shadow-sm hover:bg-white/20"
                        >
                          Change avatar
                        </button>
                        <p className="mt-2 text-xs/5 text-gray-400">JPG, GIF or PNG. 1MB max.</p>
                      </div>
                    </div>
                    
                    <div className="flex gap-x-3">
                      {isEditing ? (
                        <>
                          <button
                            type="button"
                            onClick={handleCancel}
                            className="inline-flex items-center gap-x-2 rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            onClick={handleSave}
                            className="inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                          >
                            <PencilIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                            Save Changes
                          </button>
                        </>
                      ) : (
                        <button
                          type="button"
                          onClick={handleEditClick}
                          className="inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                          <PencilIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                          Edit Profile
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="pt-6 sm:flex">
                    <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Full name</dt>
                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                      {isEditing ? (
                        <div className="flex gap-x-2 w-full">
                          <input
                            type="text"
                            value={editedDetails.Firstname}
                            onChange={(e) => handleInputChange('Firstname', e.target.value)}
                            className="block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                          <input
                            type="text"
                            value={editedDetails.Lastname}
                            onChange={(e) => handleInputChange('Lastname', e.target.value)}
                            className="block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                      ) : (
                        <div className="text-gray-900">{userDetails.Firstname + ' ' + userDetails.Lastname}</div>
                      )}
                    </dd>
                  </div>
                  <div className="pt-6 sm:flex">
                    <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Email address</dt>
                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                      {isEditing ? (
                        <input
                          type="email"
                          value={editedDetails.Email}
                          onChange={(e) => handleInputChange('Email', e.target.value)}
                          className="block w-[500px] rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      ) : (
                        <div className="text-gray-900">{userDetails.Email}</div>
                      )}
                    </dd>
                  </div>
                  <div className="pt-6 sm:flex">
                    <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">About Me</dt>
                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                      {isEditing ? (
                        <textarea
                          value={editedDetails.AboutMe}
                          onChange={(e) => handleInputChange('AboutMe', e.target.value)}
                          rows={4}
                          className="block w-[500px] rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      ) : (
                        <div className="text-gray-900">{userDetails.AboutMe}</div>
                      )}
                    </dd>
                  </div>
                </dl>
              </div>
              <div>
              <dl className="mt-6 space-y-6 divide-y  bord border-gray-200 text-sm/6">
                <h2 className="text-base/7 font-semibold text-gray-900">Skill Set</h2>
                {/* <p className="mt-1 text-sm/6 text-gray-500">Connect bank accounts to your account.</p> */}

                <div className="pt-6 sm:flex">
                    <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Skills</dt>
                    <dd className="mt-1 flex sm:mt-0 sm:flex-auto">
                      {isEditing ? (
                        <div className="w-[500px]">
                          <MultiSelectOption 
                            options={Skills}
                            isFilter={false}
                            tokens={editedDetails.Skillsets ? editedDetails.Skillsets.split(',').filter(Boolean) : []}
                            addToken={handleAddSkill}
                            removeToken={handleRemoveSkill}
                            clearTokens={handleClearSkills}
                          />
                        </div>
                      ) : (
                        userDetails.Skillsets.split(',').map((skill) => (
                          <div key={skill} className='mx-2'><Badge label={skill}></Badge></div>
                        ))
                      )}
                    </dd>
                  </div>
                  <div className="pt-6 sm:flex">
                    <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Experience</dt>
                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                      {isEditing ? (
                        <div className="w-[500px]">
                          <SingleRangeSlider 
                            min={1} 
                            max={21}
                            defaultValue={parseInt(editedDetails.Experience) || 1}
                            value={parseInt(editedDetails.Experience) || 1}
                            onChangeCallback={(newVal) => handleInputChange('Experience', newVal)}
                          />
                        </div>
                      ) : (
                        <div className="text-gray-900">{userDetails.Experience} years</div>
                      )}
                    </dd>
                  </div>
                    </dl >
              </div>

              <div>
              <dl className="mt-6 space-y-6 divide-y  bord border-gray-200 text-sm/6">
                <h2 className="text-base/7 font-semibold text-gray-900">Contacts</h2>
                {/* <p className="mt-1 text-sm/6 text-gray-500">Connect bank accounts to your account.</p> */}

                <div className="pt-6 sm:flex">
                    <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Country</dt>
                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                      {isEditing ? (
                        <div className="w-[500px]">
                          <DropdownSelect 
                            options={CountryDatas} 
                            onChangeCallback={handleCountryChange}
                            value={editedDetails.Country}
                            defaultValue={editedDetails.Country}
                          />
                        </div>
                      ) : (
                        <div className="text-gray-900">{userDetails.Country || 'Not specified'}</div>
                      )}
                    </dd>
                  </div>

                  <div className="pt-6 sm:flex">
                    <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">State</dt>
                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                      {isEditing ? (
                        <div className="w-[500px]">
                          <DropdownSelect 
                            options={filteredState} 
                            onChangeCallback={handleStateChange}
                            value={editedDetails.State}
                            defaultValue={editedDetails.State}
                          />
                        </div>
                      ) : (
                        <div className="text-gray-900">{userDetails.State || 'Not specified'}</div>
                      )}
                    </dd>
                  </div>

                <div className="pt-6 sm:flex">
                    <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Phone</dt>
                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                      {isEditing ? (
                        <PhoneInputWithCode 
                          PhnCode={editedDetails.PhoneCode}
                          value={editedDetails.Phone}
                          onChangeCallback={(newVal) => handleInputChange('Phone', newVal)}
                        />
                      ) : (
                        <div className="text-gray-900">{userDetails.PhoneCode + " " + userDetails.Phone}</div>
                      )}
                    </dd>
                  </div>
                    </dl >
              </div>
            </div>
          </main>
        </div>
      ) : (
        <></>
      )}
      
      {showCropModal && (
        <CropModal
          image={selectedFile}
          crop={crop}
          setCrop={setCrop}
          onComplete={handleCropComplete}
          onCancel={() => setShowCropModal(false)}
        />
      )}
    </>
  );
};

export default UserProfile











