import './App.css';
import LandingPage from './LandingPage';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Homepage from './Homepage';
import MentorRegister from './Components/Modules/MentorRegister';
import MentorRegSuccess from './Components/Modules/MentorRegSuccess';
import { useState, useEffect } from 'react';
import Modal from './Components/Modal';
import ValidateRoute from './ValidateRoute';
import LoadingSkeleton from './LoadingSkeleton';
import NavigateToHome from './NavigateToHome';
import PageNotFound from './PageNotFound';
import MentorLanding from './Components/MentorLanding';
import TermsAndConditions from './Components/TermsAndConditions';
import PrivacyPolicy from './Components/PrivacyPolicy';
import HomeFooter from './Components/HomeFooter';
import { Toaster } from 'react-hot-toast';


function App() {
  const [isAuth, setAuth] = useState(undefined)

  useEffect(() => {
   window.catalyst.auth.isUserAuthenticated().then((result) => {
       if(result!=undefined && result.content.role_details.role_name!=undefined && result.content.role_details.role_name=="App Mentor") {
          window.isMentor= true
       }
       setAuth(result)

   }).catch((err) => {
       setAuth("Invalid_User")
   })
}, []);

  return (
    <>
      <Toaster position="top-right" />
      {isAuth===undefined || isAuth==="Invalid_User"?
      <>
        <Router>
          <Routes>
          {/* <Route path="/app/home/dashboard" element={<LandingPage/>} /> */}
            <Route path="/app/" exact element={<Homepage></Homepage>} />
            <Route path="/app/mentorRegister" element={<MentorRegister></MentorRegister>}/>
            <Route path="/app/mentorSubmitSuccess" element={<MentorRegSuccess></MentorRegSuccess>}/>
            <Route path="/app/terms" element={<><TermsAndConditions /></>} />
            <Route path="/app/privacy" element={<><PrivacyPolicy /></>} />
            <Route path="/*" exact element={<NavigateToHome></NavigateToHome>} />
          </Routes>
        </Router>
      </>
      :
      <div className="parent-container z-0" id="parentContainer">
        <div className='z-0'> 
          {isAuth.content.role_details.role_name=="App User"?
          <Router>
              <Routes>
                <Route element={<ValidateRoute isAuth={isAuth}/>} >
                
                  <Route path="/app/" exact element={<Homepage loggedIn={true}></Homepage>} />
                  <Route path="/app/home/dashboard" element={<LandingPage/>} />
                  <Route path="/app/user/*" element={<LandingPage/>} />
                  <Route path="/app/mentorRegister" element={<MentorRegister></MentorRegister>}/>
                  <Route path="/app/mentorSubmitSuccess" element={<MentorRegSuccess></MentorRegSuccess>}/>
                  <Route path="/app/terms" element={<><TermsAndConditions /></>} />
                  <Route path="/app/privacy" element={<><PrivacyPolicy /></>} />
                </Route>
              </Routes>
          </Router>:isAuth.content.role_details.role_name=="App Mentor"?
              <Router>
                <Routes>
                    <Route path="/app/" element={<Homepage loggedIn={true}></Homepage>} />
                    <Route  path="/app/home/dashboard" element={<MentorLanding/>} />
                    <Route  path="/app/mentor/*" element={<MentorLanding/>} />
                    <Route path="/app/mentorRegister" element={<MentorRegister></MentorRegister>}/>
                    <Route path="/app/mentorSubmitSuccess" element={<MentorRegSuccess></MentorRegSuccess>}/>
                    <Route path="/app/terms" element={<TermsAndConditions />} />
                    <Route path="/app/privacy" element={<PrivacyPolicy />} />
                </Routes>
              </Router>:
              <Router>
                  <Routes>
                    <Route path="/*" exact element={<PageNotFound></PageNotFound>} />
                  </Routes>
              </Router>
        } 
        </div>
      </div>}
    </>
    
  );
}

export default App;
