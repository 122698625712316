import { XCircleIcon } from '@heroicons/react/20/solid'
import React,{useState} from 'react'
import { useNavigate } from 'react-router-dom';
const PaymentSuccess = () => {

  const nav = useNavigate()


  const CallDetails = [
    {
      id: 1,
      name: 'MockInterview With Naresh',
      imageUrl: process.env.PUBLIC_URL+"/Assets/propic"+Math.floor(Math.random() * 10)+".jpeg",
      otherDtls: { date: 'December 13, 2022', dateTime: '7:00 PM', amount: '$2,000.00', status: 'Overdue' },
    },
  ]

  return (
    <>
<ul role="list" className="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-1 xl:gap-x-8">
      {CallDetails.map((client) => (
        <li key={client.id} className="overflow-hidden rounded-xl border border-gray-200">
          <div className="flex items-center justify-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
            <img
              alt={client.name}
              src={client.imageUrl}
              className="size-14 flex-none rounded-lg bg-white object-cover ring-1 ring-gray-900/10"
            />
            <div className="text-lg jus font-medium text-gray-900">{client.name}</div>
     
          </div>
          <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm/6">
            
          <div className="flex justify-between gap-x-4 py-3">
              <dt className="text-black">Name</dt>
              <dd className="text-gray-700">
                <time>Balaji Subramani</time>
              </dd>
            </div>

            <div className="flex justify-between gap-x-4 py-3">
              <dt className="text-black">Mail ID</dt>
              <dd className="text-gray-700">
                <time>logeshbalaji222@gmail.com</time>
              </dd>
            </div>

          <div className="flex justify-between gap-x-4 py-3">
              <dt className="text-black">Session Type</dt>
              <dd className="text-gray-700">
                <time>MockInterview</time>
              </dd>
            </div>

            <div className="flex justify-between gap-x-4 py-3">
              <dt className="text-black">Date</dt>
              <dd className="text-gray-700">
                <time>{client.otherDtls.date}</time>
              </dd>
            </div>

            <div className="flex justify-between gap-x-4 py-3">
              <dt className="text-black">Time</dt>
              <dd className="text-gray-700">
                <time>{client.otherDtls.dateTime}</time>
              </dd>
            </div>
          </dl>
        </li>
      ))}
    </ul>


<ul role="list" className="mt-2 grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-1 xl:gap-x-8">
        <li  className="overflow-hidden rounded-xl border border-gray-200">
          <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
            <div className="text-sm font-medium text-gray-900">Order Summary</div>     
          </div>
          <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm/6">
            
          <div className="flex justify-between gap-x-4 py-3">
              <dt className="text-black">MockInterview Session</dt>
              <dd className="text-gray-700">
                <time>1000 Rs</time>
              </dd>
            </div>

            <div className="flex justify-between gap-x-4 py-3">
              <dt className="text-black">Platform Fee</dt>
              <dd className="text-gray-700">
                <time>21 Rs</time>
              </dd>
            </div>

          <div className="flex justify-between gap-x-4 py-3">
              <dt className="text-black">Total</dt>
              <dd className="text-gray-700">
                <time>1021 Rs</time>
              </dd>
          </div>

          </dl>
        </li>
    </ul>



    <div className="mt-6 flex items-center justify-end gap-x-6">
            <button
              type="button"
              onClick={()=>{nav("/app/home/dashboard")}}
              className="inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Close
              <XCircleIcon
                aria-hidden="true"
                className="ml-3 size-5 text-gray-400"
              />
            </button>
          </div>


    </>
  );
}

export default PaymentSuccess