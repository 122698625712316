import React from 'react';

const CalendarIcon = (props) => {
  return (
    <div className="relative w-16 h-20">
      {/* Calendar top part */}
      <div className="absolute top-0 w-full h-4 bg-blue-600 rounded-t-lg flex items-center justify-center">
        <div className="h-2 w-2 bg-white rounded-full mx-1"></div>
        <div className="h-2 w-2 bg-white rounded-full mx-1"></div>
      </div>
      
      {/* Calendar body */}
      <div className="absolute top-4 w-full h-16 bg-white border-2 border-blue-600 rounded-b-lg flex flex-col items-center justify-center">
        <span className="text-2xl font-bold text-gray-700">
          {props.dateVal}
        </span>
        <span className="text-sm text-gray-600">
         {props.monthVal}
        </span>
      </div>
    </div>
  );
};

export default CalendarIcon;