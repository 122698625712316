import { useState, useEffect } from 'react'
import { Dialog, DialogBackdrop, DialogPanel, TransitionChild } from '@headlessui/react'
import {
  Bars3Icon,
  CalendarIcon,
  CogIcon,
  HomeIcon,
  MagnifyingGlassCircleIcon,
  MapIcon,
  MegaphoneIcon,
  SquaresPlusIcon,
  UserGroupIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import { ChevronLeftIcon, EnvelopeIcon, FunnelIcon, MagnifyingGlassIcon, PhoneIcon ,ArrowLongRightIcon, StarIcon} from '@heroicons/react/20/solid'
import CheckBox from './CheckBox'
import DateNtimePicker from './DateNtimePicker'
import TimePicker from './TimePicker'


const user = {
    name: 'Tom Cook',
    imageUrl:
      'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  }
  const navigation = [
    { name: 'Dashboard', href: '#', icon: HomeIcon, current: false },
    { name: 'Calendar', href: '#', icon: CalendarIcon, current: false },
    { name: 'Teams', href: '#', icon: UserGroupIcon, current: false },
    { name: 'Directory', href: '#', icon: MagnifyingGlassCircleIcon, current: true },
    { name: 'Announcements', href: '#', icon: MegaphoneIcon, current: false },
    { name: 'Office Map', href: '#', icon: MapIcon, current: false },
  ]
  const secondaryNavigation = [
    { name: 'Apps', href: '#', icon: SquaresPlusIcon },
    { name: 'Settings', href: '#', icon: CogIcon },
  ]
  const tabs = [
    { name: 'Profile', href: '#', current: true },
  ]
  const profile = {
    name: 'Ricardo Cooper',
    imageUrl:
      'https://images.unsplash.com/photo-1463453091185-61582044d556?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80',
    coverImageUrl:
      'https://images.unsplash.com/photo-1444628838545-ac4016a5418a?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80',
    about: `
      <p>Tincidunt quam neque in cursus viverra orci, dapibus nec tristique. Nullam ut sit dolor consectetur urna, dui cras nec sed. Cursus risus congue arcu aenean posuere aliquam.</p>
      <p>Et vivamus lorem pulvinar nascetur non. Pulvinar a sed platea rhoncus ac mauris amet. Urna, sem pretium sit pretium urna, senectus vitae. Scelerisque fermentum, cursus felis dui suspendisse velit pharetra. Augue et duis cursus maecenas eget quam lectus. Accumsan vitae nascetur pharetra rhoncus praesent dictum risus suspendisse.</p>
    `,
    fields: {
      Title: 'Senior Front-End Developer',
      Skills: "Java, Python, DS & Algo",
      Location: 'India',
      State: 'Odisha',
      Experience: "7 Years",

    },
  }
  const team = [
    {
        name: '1 on 1 Session',
        imageUrl:
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
        <path strokeLinecap="round" strokeLinejoin="round" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
      </svg>,
    },
    {
      name: 'Mock Interview',
      imageUrl:
      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
      <path stroke-linecap="round" stroke-linejoin="round" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01" />
    </svg>,
    },
    {
      name: 'Mentorship',
      imageUrl:
      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
      <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
    </svg>,
    },
  ]
  
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

const UserPreview = (props) => {
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setTimeout(() => setLoading(false), 1000)
    }, [])

    const availabilityStatus = {
        status: 'Available',
        nextSlot: 'Today, 4:00 PM',
        timezone: 'IST'
    }

    const handleBooking = () => {
        // Scroll to schedule options section
        const scheduleSection = document.getElementById('schedule-options-section');
        if (scheduleSection) {
            scheduleSection.scrollIntoView({ behavior: 'smooth' });
        }
    };

  return (<>
    {loading ? (
      <div className="flex h-full items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-indigo-600" />
      </div>
    ) : (
    <div className="bg-gray-50 min-h-screen">
      {/* Hero Section with Cover Image */}
      <div className="relative">
        <div className="h-48 w-full object-cover">
          <img
            src={profile.coverImageUrl}
            alt="Profile cover"
            className="h-full w-full object-cover"
          />
          {/* Availability Badge */}
          <div className="absolute top-4 right-4 px-4 py-2 rounded-full bg-green-100 text-green-800 text-sm font-semibold shadow-sm border border-green-200">
            {availabilityStatus.status}
          </div>
        </div>

        {/* Profile Info Card */}
        <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="relative -mt-12 sm:-mt-16 bg-white rounded-lg shadow-md p-6">
            <div className="sm:flex sm:items-center sm:justify-between">
              {/* Profile Image and Basic Info */}
              <div className="sm:flex sm:space-x-6 items-center">
                <img
                  src={process.env.PUBLIC_URL + "/Assets/propic" + Math.floor(Math.random() * 10) + ".jpeg"}
                  alt={profile.name}
                  className="size-24 sm:size-32 rounded-full ring-4 ring-white shadow-lg"
                />
                <div className="mt-4 sm:mt-0">
                  <h1 className="text-2xl font-bold text-gray-900">{profile.name}</h1>
                  <p className="text-md text-gray-600">{profile.fields.Title}</p>
                  <div className="mt-2 flex items-center">
                    <div className="flex items-center">
                      {[...Array(5)].map((_, i) => (
                        <StarIcon key={i} className="h-5 w-5 text-yellow-400" />
                      ))}
                    </div>
                    <span className="ml-2 text-sm text-gray-600">4.8 (124 reviews)</span>
                  </div>
                </div>
              </div>

              {/* Action Buttons */}
              <div className="mt-6 sm:mt-0 flex flex-col sm:flex-row gap-3">
                <button 
                  onClick={handleBooking}
                  className="inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 transition-colors"
                >
                  Book Meeting
                  <ArrowLongRightIcon className="ml-2 h-5 w-5" />
                </button>
              </div>
            </div>

            {/* Skills Tags */}
            <div className="mt-6">
              <div className="flex flex-wrap gap-2">
                {profile.fields.Skills.split(',').map((skill) => (
                  <span key={skill} className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-indigo-50 text-indigo-700 border border-indigo-100">
                    {skill.trim()}
                  </span>
                ))}
              </div>
            </div>

            {/* Next Available Slot */}
            <div className="mt-6 p-4 bg-gray-50 rounded-lg border border-gray-100">
              <div className="flex items-center justify-between">
                <div>
                  <p className="text-sm font-medium text-gray-900">Next Available Slot</p>
                  <p className="text-sm text-gray-600">{availabilityStatus.nextSlot} {availabilityStatus.timezone}</p>
                </div>
                <CalendarIcon className="h-6 w-6 text-gray-400" />
              </div>
            </div>
          </div>
        </div>

        {/* About Section */}
        <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 mt-8">
          <div className="bg-white rounded-lg shadow-md p-6">
            <h2 className="text-xl font-semibold text-gray-900 mb-4">About Me</h2>
            <div 
              dangerouslySetInnerHTML={{ __html: "I am a software developer with over 7 years of experience in designing, developing, and maintaining software applications. My expertise spans various programming languages and technologies, enabling me to create efficient and scalable solutions tailored to meet client needs." }}
              className="prose prose-indigo max-w-none text-gray-600"
            />
          </div>
        </div>

        {/* Additional Info Grid */}
        <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 mt-8 mb-8">
          <div className="bg-white rounded-lg shadow-md p-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {Object.entries(profile.fields).map(([key, value]) => (
                key !== 'Skills' && (
                  <div key={key} className="border-l-4 border-indigo-200 pl-4">
                    <dt className="text-sm font-medium text-gray-500">{key}</dt>
                    <dd className="mt-1 text-sm text-gray-900">{value}</dd>
                  </div>
                )
              ))}
            </div>
          </div>
        </div>

        {/* Session Types */}
        <div id="schedule-options-section" className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 mb-8">
          <CheckBox {...props} />
        </div>
      </div>
    </div>
    )}
  </>)
}

export default UserPreview