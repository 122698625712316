import React from 'react'
import { useState, useRef } from 'react' 
import AddCircularBtn from './AddCircularBtn'
import { SocialIcon } from 'react-social-icons'
import ReactDOM from 'react-dom';


const DropdownNInput = (props) => {
    const [inputText, setInputText] = useState("")
    const inputOptnRef = useRef(null); 
    const [tempOptn, setTempOptn] = useState(["Official", "LinkedIn", "Facebook", "Twitter", "Reddit", "Medium", "Github", "Other"])
    const [websiteKeyValue, updateKeyValue] = useState({})

    const addKeyValue = () => {    
        if(inputText!="" && inputText!=undefined){
            updateKeyValue(prevState => ({...prevState, [inputOptnRef.current.value]: inputText}));
        }
        
        setTempOptn(tempOptn.filter(item => item !== inputOptnRef.current.value))
        setInputText("")        
    };
    
    const deleteKeyValue = (key) => {
        updateKeyValue(prevState => {
            const newState = { ...prevState }; 
            delete newState[key];              
            return newState;
        });
        setTempOptn(prevItems => [key, ...prevItems ]);
    };
    console.log("--->",websiteKeyValue)
    const selectOptn = []
    const ele1= []
    tempOptn.map((value)=>(
        selectOptn.push(<option value={value}>{value}</option>)
     )) 
        Object.entries(websiteKeyValue).map(([key, value]) => (
            ele1.push(
                <div className="rounded-md max-w-lg  bg-indigo-50  text-indigo-600 shadow-sm px-2 py-1 text-sm flex items-center mt-2 mr-2">
                    <span className="truncate ">
                    <a ><SocialIcon  className='w-2 h-2' style={{height:"20px", width:"20px"}}  url={value} /></a>
                    <span className=" pl-1 "> {key}</span>
                    </span>
                <button className="ml-1 float-right hover:bg-indigo-100 focus:outline-none text-gray-600"> 
                    <i className="p-1 text-sm font-sans" key={key} onClick={()=>{deleteKeyValue(key)}}>&#x2715;</i>
                </button>
                </div>
            )
        ))
        return (
                    <div>
            {selectOptn.length!=0?
            
            <div className="relative mt-2 w-96  flex   rounded-md -sm">

                <div className='w-96'>
                <div className="absolute inset-y-0 left-0 flex items-center">
                    <label htmlFor="country" className="sr-only">
                        Country
                    </label>
                    <select id="country" name="country" autoComplete="country"
                    ref={inputOptnRef}
                    className="h-full rounded-md border-0 bg-transparent  pl-3 pr-2 text-black focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm">
                        {selectOptn}
                    </select>
                </div>
                <input id="phone-number" name="phone-number" type="text" placeholder="Enter the URL.."
                    value={inputText}
                    onChange={(e)=>{setInputText(e.target.value)}}
                    className="block w-full rounded-md border-0 py-1.5 pl-28 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"/>
               </div>
                <div className='float-right'><AddCircularBtn size={"2"} onClickCallback={(e)=>{addKeyValue()}}></AddCircularBtn></div>
            </div>:<></>}

            <div className="relative w-9/12">
                <div className="flex flex-wrap items-center  rounded-md pt-1 ">
                {ele1}
                </div>
            </div>



       </div>
  )
}


export default DropdownNInput