// import React from 'react'

// import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
// import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
// import { EllipsisVerticalIcon } from '@heroicons/react/20/solid'


import React from 'react'

import { useState } from 'react'
import { Radio, RadioGroup } from '@headlessui/react'
import { CheckCircleIcon } from '@heroicons/react/20/solid'
import { InformationCircleIcon } from '@heroicons/react/20/solid'
import QuestionSection from './QuestionSection'



const timeValues = [
    { time: '10:00 AM'},
    { time: '11:00 AM'},
    { time: '12:00 PM'},
    { time: '1:00 PM'},
    { time: '2:00 PM'},
    { time: '3:00 PM'},
    { time: '4:00 PM'},
    { time: '5:00 PM'},
    { time: '6:00 PM'},
    { time: '7:00 PM'},
    { time: '8:00 PM'},
  ]

const TimePicker = (props) => {
   let timeValues =props.mentorAvail.val[props.selectedDate.name.toUpperCase()]
   const [selectedTime, setSelectedTime] = useState(undefined)
   debugger
   const onchange = (e)=>{
      props.callDetails.current["sessionDate"] = props.selectedDate
      props.callDetails.current["sessionTime"] = e.time
      setSelectedTime(e.time)
      props.setSelectedTime(e.time)
   }
  return (
    <>
      {props.selectedDate.name!=undefined && props.selectedDate.name!=""?
        <div className="m-5">
          <fieldset>
            <legend className="text-sm/6 font-semibold text-indigo-600">Select Time</legend>
            <RadioGroup
              // value={selectedMailingLists}
              onChange={(e)=>{onchange(e)}}
              className="mt-2 grid grid-cols-1 gap-y-6 sm:grid-cols-7 sm:gap-x-2"
            >
              {timeValues.map((time) => (
                time.isChecked==true?
                <Radio
                  value={time}
                  className="group relative flex cursor-pointer rounded-lg border border-gray-300 bg-white p-4 shadow-sm focus:outline-none data-[focus]:border-indigo-600 data-[focus]:ring-2 data-[focus]:ring-indigo-600"
                >
                  <span className="flex flex-1">
                    <span className="flex flex-col">
                      <span className="block text-sm font-medium text-gray-900">{time.time}</span>
                    </span>
                  </span>
                  <CheckCircleIcon
                    aria-hidden="true"
                    className="size-5 text-indigo-600 [.group:not([data-checked])_&]:invisible"
                  />
                  <span
                    aria-hidden="true"
                    className="pointer-events-none absolute -inset-px rounded-lg border-2 border-transparent group-data-[focus]:border group-data-[checked]:border-indigo-600"
                  />
                </Radio>:<></>
              ))}
            </RadioGroup>
          </fieldset>
          <div className="rounded-md bg-blue-50 mt-5 p-4">
              <div className="flex">
                <div className="shrink-0">
                  <InformationCircleIcon aria-hidden="true" className="size-5 text-blue-400" />
                </div>
                <div className="ml-3 flex-1 md:flex md:justify-between">
                

                  <p className="text-sm text-blue-700">The call is scheduled for: {props.selectedDate.name}, {props.selectedDate.month} {props.selectedDate.date}, {new Date().getFullYear()}, {selectedTime}</p>
                </div>
              </div>
            </div>
        </div>
      :<></>}
      {/* {selectedTime && 
      <>
      <div className="mt-10 m-5">
      <legend className="text-sm/6 font-semibold text-indigo-600">Questions</legend>
      <QuestionSection {...props}></QuestionSection>
      </div>
      </>
      } */}
    </>
  )
}

export default TimePicker