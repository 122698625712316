import { useState, useEffect } from 'react'

function SingleRangeSlider({ min, max, value, defaultValue, onChangeCallback }) {
  const [sliderValue, setSliderValue] = useState(defaultValue || min)

  useEffect(() => {
    // Update slider value when value or defaultValue changes
    if (value !== undefined) {
      setSliderValue(value)
    } else if (defaultValue !== undefined) {
      setSliderValue(defaultValue)
    }
  }, [value, defaultValue])

  const handleChange = (e) => {
    const newValue = parseInt(e.target.value)
    setSliderValue(newValue)
    onChangeCallback(newValue)
  }

  return (
    <div className="w-full">
      <input
        type="range"
        min={min}
        max={max}
        value={sliderValue}
        onChange={handleChange}
        className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
      />
      <div className="text-sm text-gray-500 mt-2">
        {sliderValue} years
      </div>
    </div>
  )
}

export default SingleRangeSlider