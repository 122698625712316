import React, {useState} from 'react'
import mentorSubmitStore from '../../Store/mentorRegisterStore';
import { useNavigate } from 'react-router-dom'

const MentorRegSuccess = () => {

    // const { FirstName, updateFirstName,  LastName, updateLastName,Email, updateEmail, 
    //     Country, updateCountry, State, updateState, Profession, updateProfession,
    //     SkillSets, addSkillSet, deleteSkillSet, Exp, updateExp, Phn, updatePhn } = mentorSubmitStore();
    const navigateTo = useNavigate() 
  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full">
        <body class="h-full">
        ```
      */}
       <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
      <div className="flex min-h-full flex-col bg-white pb-12 pt-16">
        <main className="mx-auto flex w-full max-w-7xl grow flex-col justify-center px-6 lg:px-8">
          {/* <div className="flex shrink-0 justify-center">
            <a href="#" className="inline-flex">
              <span className="sr-only">Your Company</span>
              <img
                alt=""
                src="https://tailwindui.com/plus/img/logos/mark.svg?color=indigo&shade=600"
                className="h-12 w-auto"
              />
            </a>
          </div> */}
          <div className="py-16">
            <div className="text-center">
              {/* <p className="text-base font-semibold text-indigo-600">404</p> */}
              <h1 className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">Thank you for submitting.</h1>
              <p className="mt-2 text-base text-gray-500">We will get you back shortly</p>
              <div className="mt-6">
                <a onClick={()=>{navigateTo("/app/")}} className="text-base font-medium text-indigo-600 hover:text-indigo-500">
                  Go back home
                  <span aria-hidden="true"> &rarr;</span>
                </a>
              </div>
            </div>
          </div>
        </main>
      </div>
     </div>
    </>
  )
}

export default MentorRegSuccess