import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();

export default function MultiSelectOption(props) {
    const [selectedOptions, setSelectedOptions] = useState([]);

    // Update selected options when default options change
    useEffect(() => {
        if (props.defaultOptions) {
            const defaultValues = Array.isArray(props.defaultOptions) 
                ? props.defaultOptions.map(value => ({
                    value: value,
                    label: value
                }))
                : [];
            setSelectedOptions(defaultValues);
        }
    }, [props.defaultOptions]);

    const handleChange = (newSelectedOptions) => {
        setSelectedOptions(newSelectedOptions || []);
        const values = (newSelectedOptions || []).map(option => option.value);
        
        if (props.isFilter) {
            props.filterOnchange(props.keyName, values);
        } else {
            // Handle non-filter case
            const addedTokens = values.filter(token => !props.tokens?.includes(token));
            const removedTokens = props.tokens?.filter(token => !values.includes(token));
            
            addedTokens?.forEach(token => props.addToken?.(token));
            removedTokens?.forEach(token => props.removeToken?.(token));
        }
    };

    return (
        <Select
            closeMenuOnSelect={false}
            components={animatedComponents}
            value={selectedOptions}
            defaultValue={props.defaultOptions?.map(value => ({
                value: value,
                label: value
            }))}
            isMulti
            styles={{
                indicatorSeparator: () => ({ display: "none" }),
                menu: provided => ({ ...provided, zIndex: 9999 })
            }}
            onChange={handleChange}
            options={props.options}
        />
    );
}