import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import HomeFooter from './HomeFooter';

function PrivacyPolicy() {
  const [activeSection, setActiveSection] = useState('introduction');

  // Define sections and their IDs with bullets instead of numbers
  const sections = [
    { id: 'introduction', label: 'Introduction' },
    { id: 'collection', label: '• Information We Collect' },
    { id: 'use', label: '• How We Use Your Information' },
    { id: 'sharing', label: '• Information Sharing' },
    { id: 'security', label: '• Data Security' },
    { id: 'rights', label: '• Your Rights' },
    { id: 'updates', label: '• Updates to This Policy' },
    { id: 'contact', label: '• Contact Us' }
  ];

  useEffect(() => {
    // Create an Intersection Observer
    const observer = new IntersectionObserver(
      (entries) => {
        // Find the first section that's intersecting
        const visibleSection = entries.find((entry) => entry.isIntersecting);
        if (visibleSection) {
          setActiveSection(visibleSection.target.id);
        }
      },
      {
        // Adjust these values to control when sections become active
        rootMargin: '-10% 0px -80% 0px',
        threshold: 0.1
      }
    );

    // Observe all section elements
    const sectionElements = document.querySelectorAll('section[id]');
    sectionElements.forEach((element) => observer.observe(element));

    // Cleanup function
    return () => {
      sectionElements.forEach((element) => observer.unobserve(element));
    };
  }, []);

  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      const offset = 100;
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
  };

  return (
    <div className="h-screen flex flex-col overflow-y-scroll scrollbar-hide">
    <div className="bg-gray-50 z-0 h-full flex flex-col ">
    {/* Navigation Sidebar */}
      <div className="fixed h-full z-0 inset-y-0 left-0 w-64 bg-white border-r border-gray-200 ">
        <nav className="p-6 space-y-2">
          <div className="text-indigo-600 font-medium">Privacy Policy</div>
          <Link to="/app/terms" className="block text-gray-600 hover:text-indigo-600">
            Terms of Service
          </Link>
          
          {/* Section Links */}
          <div className="mt-6 space-y-3">
            {sections.map((section) => (
              <button
                key={section.id}
                onClick={() => scrollToSection(section.id)}
                className={`block w-full text-left text-sm py-1 px-2 rounded transition-colors duration-200 ${
                  activeSection === section.id
                    ? 'text-indigo-600 font-medium bg-indigo-50'
                    : 'text-gray-600 hover:text-indigo-600 hover:bg-gray-50'
                }`}
              >
                {section.label}
              </button>
            ))}
          </div>
        </nav>
        
      </div>

      <div className="h-full overflow-y-scroll scrollbar-hide ml-64">
        <div className="px-8 py-12 max-w-4xl">
          <h1 className="text-4xl font-bold text-gray-900">Privacy Policy</h1>
          <p className="mt-4 text-gray-600">Last updated: {new Date().toLocaleDateString()}</p>

          <div className="mt-8 space-y-12">
            <section id="introduction" className="prose max-w-none min-h-[100px]">
              <p className="text-lg text-gray-700">
                At [Your Website Name], we are committed to protecting your privacy and ensuring that your personal information is handled in a safe and responsible manner. This Privacy Policy outlines how we collect, use, share, and secure your information. By using our website, you agree to the terms of this policy.
              </p>
            </section>

            <section id="collection" className="prose max-w-none min-h-[100px]">
              <h2 className="text-2xl font-semibold text-gray-900">1. Information We Collect</h2>
              <p className="text-gray-700">We may collect the following types of information from you:</p>
              <ul className="list-disc pl-6 text-gray-700 space-y-2">
                <li><strong>Personal Information:</strong> Such as your name, email address, phone number, and payment details when you create an account, make a purchase, or contact us.</li>
                <li><strong>Usage Information:</strong> Information about how you interact with our website, including IP addresses, browser type, pages visited, and the time and date of your visit.</li>
                <li><strong>Uploaded Documents:</strong> If you upload documents to our website, we collect and store these files for the purpose of providing our services.</li>
                <li><strong>Cookies and Tracking Technologies:</strong> Data collected through cookies and similar technologies to enhance user experience and analyze site performance.</li>
              </ul>
            </section>

            <section id="use" className="prose max-w-none min-h-[100px]">
              <h2 className="text-2xl font-semibold text-gray-900">2. How We Use Your Information</h2>
              <p className="text-gray-700">We use your information to:</p>
              <ul className="list-disc pl-6 text-gray-700 space-y-2">
                <li>Provide and improve our services.</li>
                <li>Process transactions and manage your account.</li>
                <li>Communicate with you regarding updates, offers, and customer support.</li>
                <li>Analyze usage patterns to improve website functionality and user experience.</li>
                <li>Comply with legal obligations and enforce our terms and policies.</li>
              </ul>
            </section>

            <section id="sharing" className="prose max-w-none min-h-[100px]">
              <h2 className="text-2xl font-semibold text-gray-900">3. Information Sharing</h2>
              <p className="text-gray-700">We do not sell or rent your personal information to third parties. However, we may share your information with:</p>
              <ul className="list-disc pl-6 text-gray-700 space-y-2">
                <li><strong>Service Providers:</strong> Third-party vendors who assist in providing our services (e.g., payment processors, hosting services).</li>
                <li><strong>Legal Authorities:</strong> When required by law or to protect our rights and the safety of others.</li>
                <li><strong>Business Transfers:</strong> In connection with a merger, sale, or transfer of assets.</li>
              </ul>
            </section>

            <section id="security" className="prose max-w-none min-h-[100px]">
              <h2 className="text-2xl font-semibold text-gray-900">4. Data Security</h2>
              <p className="text-gray-700">
                We implement industry-standard security measures to protect your information from unauthorized access, alteration, disclosure, or destruction. However, no method of transmission over the internet or electronic storage is completely secure. While we strive to protect your information, we cannot guarantee its absolute security.
              </p>
            </section>

            <section id="rights" className="prose max-w-none min-h-[100px]">
              <h2 className="text-2xl font-semibold text-gray-900">5. Your Rights</h2>
              <p className="text-gray-700">Depending on your jurisdiction, you may have the following rights:</p>
              <ul className="list-disc pl-6 text-gray-700 space-y-2">
                <li>Access, update, or delete your personal information.</li>
                <li>Opt-out of receiving marketing communications.</li>
                <li>Restrict or object to certain data processing activities.</li>
                <li>Withdraw consent where processing is based on consent.</li>
              </ul>
              <p className="mt-4 text-gray-700">To exercise your rights, please contact us at [Your Contact Information].</p>
            </section>

            <section id="updates" className="prose max-w-none min-h-[100px]">
              <h2 className="text-2xl font-semibold text-gray-900">6. Updates to This Policy</h2>
              <p className="text-gray-700">
                We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. We encourage you to review this policy periodically. Your continued use of the website after changes are made constitutes your acceptance of the revised policy.
              </p>
            </section>

            <section id="contact" className="prose max-w-none min-h-[100px]">
              <h2 className="text-2xl font-semibold text-gray-900">7. Contact Us</h2>
              <p className="text-gray-700">If you have any questions or concerns about this Privacy Policy, please contact us at:</p>
              <div className="mt-4 space-y-2 text-gray-700">
                <p><strong>Email:</strong> [Your Email Address]</p>
                <p><strong>Phone:</strong> [Your Phone Number]</p>
                <p><strong>Address:</strong> [Your Address]</p>
              </div>
              <p className="mt-6 text-gray-700">Thank you for trusting [Your Website Name].</p>
            </section>
          </div>
        </div>
      </div>

    </div>
    <div className="z-10">
          <HomeFooter />
        </div>
    </div>
  );
}

export default PrivacyPolicy; 