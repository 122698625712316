import React, {useState, useEffect, useCallback} from 'react'
import ProfileCard from '../ProfileCard'
import UserCardOuter from '../UserCardOuter'
import Filter from '../Filter'
import OuterCard from '../OuterCard'
import axios, * as others from 'axios';
import Modal from '../Modal'
import { useAsyncError } from 'react-router-dom'
import { XMarkIcon, FunnelIcon, EllipsisHorizontalIcon } from '@heroicons/react/24/outline'
import { XCircleIcon } from '@heroicons/react/20/solid'

const pg = ["UserPreview","ScheduleSlot","SchedulePreview","PaymentPage"]
const ExploreMentor = () => {

    const [mentorList, setMentorList] = useState([])
    const [filterObj, setFilterObj] = useState({
        SkillSets: [],
        Companies: [],
        Countries: [],
        Languages: [],
        Exp_minVal: 0,
        Exp_maxVal: 20
    });
    const [isOpen,setIsOpen] = useState(false)
    const [pageNames, setPageNames] = useState("")
    const [selectedMentor, setSelectedMentor] = useState(undefined)
    const [isFilterOpen, setIsFilterOpen] = useState(false)
    const [showAllFilters, setShowAllFilters] = useState(false);
    const [visibleFiltersCount, setVisibleFiltersCount] = useState(3);
    const [isMoreFiltersOpen, setIsMoreFiltersOpen] = useState(false);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            getMentorDetails();
        }, 300);

        return () => clearTimeout(timeoutId);
    }, [filterObj]);
    
    useEffect(() => {
        console.log('Current filterObj:', filterObj);
    }, [filterObj]);

    const getMentorDetails = async () =>{
        let queryParams = {}
        queryParams["FirstName"]=filterObj.name
        if(filterObj.SkillSets!=undefined){
            queryParams["SkillSets"] = filterObj.SkillSets
        }
        if(filterObj.Countries!=undefined){
            queryParams["Countries"] = filterObj.Countries
        }
        if(filterObj.Companies!=undefined){
            queryParams["Companies"] = filterObj.Companies
        }
        if(filterObj.Languages!=undefined){
            queryParams["Languages"] = filterObj.Languages
        }
        const response =  await axios.get('/server/UserActions/getMentorList',{params:queryParams})
        if(response.data!=undefined && response.data!="ERROR"){
            setMentorList(response.data)
         }else{
            setMentorList(undefined)
         }
    }

    const removeFilter = (type, value) => {
        setFilterObj(prev => ({
            ...prev,
            [type]: prev[type]?.filter(item => item !== value)
        }))
    }

    const getFilterLabel = (type) => {
        const labels = {
            SkillSets: 'Skills',
            Countries: 'Country',
            Companies: 'Company',
            Languages: 'Language'
        }
        return labels[type] || type
    }

    const handleFilterUpdate = useCallback((newFilterObj) => {
        console.log('New filter values:', newFilterObj);
        setFilterObj(newFilterObj);
    }, []);

    const renderFilterBreadcrumbs = () => {
        let allFilters = [];
        Object.entries(filterObj).forEach(([key, values]) => {
            if (Array.isArray(values) && values.length > 0) {
                values.forEach((value) => {
                    allFilters.push({
                        key,
                        value,
                        label: `${getFilterLabel(key)} Contains ${value}`
                    });
                });
            }
        });

        const visibleFilters = allFilters.slice(0, visibleFiltersCount);
        const moreFilters = allFilters.slice(visibleFiltersCount);
        const remainingCount = moreFilters.length;

        return (
            <>
            {visibleFilters.length > 0 && (
                <div className="flex items-center h-10 overflow-hidden z-0">
                    <div className="flex items-center gap-2 flex-1 overflow-x-auto scrollbar-thin scrollbar-thumb-gray-300 z-0 scrollbar-track-transparent">
                        {visibleFilters.map(({ key, value, label }) => (
                            <span
                                key={`${key}-${value}`}
                                className="inline-flex items-center gap-2 px-3 h-8 rounded-md text-sm bg-white border border-gray-300 text-gray-700 whitespace-nowrap"
                            >
                                {label}
                                <button
                                    onClick={() => removeFilter(key, value)}
                                    className="hover:text-gray-900"
                                >
                                    <XMarkIcon className="h-4 w-4" />
                                </button>
                            </span>
                        ))}
                        
                        {remainingCount > 0 && (
                            <button
                                onClick={() => setIsMoreFiltersOpen(true)}
                                className="inline-flex items-center gap-1 px-3 h-8 rounded-md text-sm bg-white border border-gray-300 text-gray-700 hover:bg-gray-50 whitespace-nowrap"
                            >
                                <EllipsisHorizontalIcon className="h-4 w-4" />
                                <span>{remainingCount} more</span>
                            </button>
                        )}
                    </div>

                    {allFilters.length > 0 && (
                        <button
                            onClick={() => {
                                setFilterObj({
                                    name: undefined,
                                    SkillSets: [],
                                    Countries: [],
                                    Companies: [],
                                    Languages: [],
                                    Exp_minVal: 0,
                                    Exp_maxVal: 20
                                });
                                setIsMoreFiltersOpen(false);
                            }}
                            className="text-sm text-blue-600 hover:text-blue-800 hover:underline whitespace-nowrap ml-2 shrink-0"
                        >
                            Clear all filters
                        </button>
                    )}
                </div>
            )}

                {/* More Filters Popup */}
                {isMoreFiltersOpen && (
                    <>
                        <div className="fixed inset-0 bg-black bg-opacity-25 z-50" onClick={() => setIsMoreFiltersOpen(false)} />
                        <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg shadow-xl z-50 w-full max-w-lg">
                            <div className="p-4 border-b border-gray-200">
                                <div className="flex items-center justify-between">
                                    <h3 className="text-lg font-medium text-gray-900">More Filters</h3>
                                    <button
                                        onClick={() => setIsMoreFiltersOpen(false)}
                                        className="text-gray-400 hover:text-gray-500"
                                    >
                                        <XMarkIcon className="h-5 w-5" />
                                    </button>
                                </div>
                            </div>
                            <div className="p-4 max-h-[60vh] overflow-y-auto">
                                <div className="flex flex-wrap gap-2">
                                    {moreFilters.map(({ key, value, label }) => (
                                        <span
                                            key={`${key}-${value}`}
                                            className="inline-flex items-center gap-2 px-3 py-1.5 rounded-md text-sm bg-white border border-gray-300 text-gray-700"
                                        >
                                            {label}
                                            <button
                                                onClick={() => removeFilter(key, value)}
                                                className="hover:text-gray-900"
                                            >
                                                <XMarkIcon className="h-4 w-4" />
                                            </button>
                                        </span>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </>
        );
    };

console.log("filterObjfilterObj...", filterObj)
  return (
    <div className="py-4 bg-gray-50">
        
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
            {console.log('Rendering breadcrumbs with filterObj:', filterObj)}
            

            <button
                onClick={() => setIsFilterOpen(true)}
                className="fixed right-4 top-20 z-30 lg:hidden bg-blue-600 text-white p-3 rounded-full shadow-lg hover:bg-blue-700"
            >
                <FunnelIcon className="h-6 w-6" />
            </button>

            <div className="flex flex-col lg:flex-row gap-8">
                <div className="lg:w-9/12 w-full overflow-y-scroll">














                {/* <OuterCard className="mb-6 bg-white rounded-lg shadow-sm p-4"> */}
                <div className="sticky mb-1 z-10 bg-gray-50">
                    {/* <OuterCard className="bg-white rounded-lg shadow-sm"> */}
                        {renderFilterBreadcrumbs()}
                    {/* </OuterCard> */}
                </div>
            {/* </OuterCard> */}


                    {mentorList === undefined ? (
                        <div className="text-center p-8 bg-white rounded-lg shadow-sm min-h-[600px] flex items-center justify-center">
                            <p className="text-gray-500 text-lg">No mentors found</p>
                        </div>
                    ) : (
                        <div className="overflow-y-auto max-h-[calc(100vh-120px)] scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100">
                            <UserCardOuter className="min-h-[600px]">
                                <ProfileCard
                                    setSelectedMentor={setSelectedMentor}
                                    setIsOpen={setIsOpen}
                                    mentorList={mentorList}
                                    setPageNames={setPageNames}
                                />
                            </UserCardOuter>
                        </div>
                    )}
                </div>

                <div className={`
                    fixed inset-y-0 right-0 w-80 bg-white shadow-lg transform transition-transform duration-300 ease-in-out
                    lg:static lg:w-3/12 lg:transform-none lg:transition-none overflow-y-auto max-h-[calc(100vh-120px)]
                    ${isFilterOpen ? 'translate-x-0' : 'translate-x-full lg:translate-x-0'}
                `}>
                    <div className="h-full p-4 overflow-y-auto">
                        <button
                            onClick={() => setIsFilterOpen(false)}
                            className="lg:hidden absolute top-4 right-4 text-gray-500 hover:text-gray-700"
                        >
                            <XMarkIcon className="h-6 w-6" />
                        </button>

                        <div className="pt-8 lg:pt-0">
                            {/* <OuterCard className="bg-white rounded-lg shadow-sm p-4"> */}
                                <div className="max-w-sm mx-auto">
                                    <Filter 
                                        filterObj={filterObj} 
                                        updateFilterObj={handleFilterUpdate} 
                                    />
                                </div>
                            {/* </OuterCard> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        {isFilterOpen && (
            <div 
                className="fixed inset-0 bg-black bg-opacity-50 z-30 lg:hidden"
                onClick={() => setIsFilterOpen(false)}
            />
        )}

        {selectedMentor != undefined && (
            <Modal
                flow={"schedule"}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                setSelectedMentor={setSelectedMentor}
                selectedMentor={selectedMentor}
                pageName={pageNames}
                setPageNames={setPageNames}
            />
        )}
    </div>
  )
}

export default ExploreMentor