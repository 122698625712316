import {create} from 'zustand';

const mentorSubmitStore = create((set) => ({
  FirstName:"",
  updateFirstName: (newVal) => set({ FirstName: newVal }),
  LastName:"",
  updateLastName: (newVal) => set({ LastName: newVal }),
  Email:"",
  updateEmail: (newVal) => set({ Email: newVal }),
  Country:"",
  updateCountry: (newVal) => set({ Country: newVal }),
  State:"",
  updateState: (newVal) => set({ State: newVal }),
  Profession:"",
  updateProfession: (newVal) => set({ Profession: newVal }),
  SkillSets:[],  
  addSkillSet: (newVal) => set((state) => ({ SkillSets: [...state.SkillSets, newVal] })),
  deleteSkillSet: (newVal) => set((state) => ({
    SkillSets: state.SkillSets.filter(item => item !== newVal)
  })),
  clearSkillSets: () => set(() => ({ SkillSets: [] })),
  Exp:"",
  updateExp: (newVal) => set({ Exp: newVal }),
  Phn:"",
  updatePhn: (newVal) => set({ Phn: newVal }),
  PhnCode: "",
  updatePhnCode: (newVal) => set({ PhnCode: newVal }),
  Website:[],
}));

export default mentorSubmitStore;
