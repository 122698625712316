import React, { useState } from 'react'
import OuterCard from '../OuterCard'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { EllipsisHorizontalIcon } from '@heroicons/react/20/solid'
import axios, * as others from 'axios';
import Modal from '../Modal';
import EditMeetingConf from './EditMeetingConf';
import CommonModal from '../../CommonModal';
import UserCard from './UserCard';
import Hello from './Hello';
import ToggleSwitch from './ToggleSwitch';
import ConfirmationModal from '../Common/ConfirmationModal'

const MeetingConf = () => {

    const [meetingDtls,setMeetingDtls] = useState(undefined)
    const [openEditForm, setOpenEditForm] = useState(undefined)
    const [isOpen, setIsOpen] = useState(false)
    const [deleteModalOpen, setDeleteModalOpen] = useState(false)
    const [meetingToDelete, setMeetingToDelete] = useState(null)
    
    let defaultMeetings  = []
    let customMeetings = []
    let formEle = []
    let count = 0
    const getMeetingDtls = async () =>{
        const response =  await axios.get('/server/MentorActions/getAllMeetingDtls')
        console.log("The response is....", response)
        setMeetingDtls(response.data)
    }

    const handleToggleChange = async (isEnabled, index, meetingName) => {
      try {
        let meeting = meetingDtls[index]
        const meetingData = {
          ROWID: meeting?.ROWID || "",
          isEnabled: isEnabled,
          type: "edit"
        }
  
        const response = await axios.get('/server/MentorActions/saveMeetingConf', { params: meetingData })
        if (response.status === 200) {
          const updatedMeetingDtls = [...meetingDtls];
          updatedMeetingDtls[index] = {
            ...meetingDtls[index],
            isEnabled: isEnabled
          };
          setMeetingDtls(updatedMeetingDtls);
        }
      } catch (error) {
        console.error('Error saving meeting configuration:', error)
        alert('Failed to save meeting configuration')
      }
    }

    const handleDeleteMeeting = async () => {
        try {
            const meetingData = {
              ROWID: meetingToDelete,
              type: "delete",
            }
            const response = await axios.get('/server/MentorActions/saveMeetingConf', { params: meetingData })
            console.log("Deleting meeting:", meetingToDelete)
            debugger
            if(response.status==200){
              setDeleteModalOpen(false)
              setMeetingToDelete(null)
              getMeetingDtls()
            }else{
              alert("Failed to delete meeting")
            }
        } catch (error) {
            console.error('Error deleting meeting!!!!!!!!!!!!!!!:', error)
            alert('Failed to delete meeting111')
        }
    }

    if(meetingDtls==undefined){
        getMeetingDtls()
    }else{
      Object.keys(meetingDtls).forEach(index =>{
        let meeting = meetingDtls[index]
        if(meeting.isDefault==true){
          defaultMeetings.push(<li key={meeting.MeetingName} className="overflow-hidden rounded-xl border border-gray-200">
            <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
              <img
              alt={meeting.MeetingName}
              src={'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60'}
              className="size-12 flex-none rounded-lg bg-white object-cover ring-1 ring-gray-900/10"
            />
            <div className="text-sm/6 font-medium text-gray-900">{meeting.MeetingName}</div>
            <ToggleSwitch 
              isEnabled={meeting.isEnabled} 
              onChange={(isEnabled) => handleToggleChange(isEnabled, index, meeting.MeetingName)}
            />


            <Menu as="div" className="relative ml-auto">
              <MenuButton className="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500">
                <span className="sr-only">Open options</span>
                <EllipsisHorizontalIcon aria-hidden="true" className="size-5" />
              </MenuButton>
              <MenuItems
                transition
                className="absolute right-0 z-10 mt-0.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
              >
                <MenuItem>

                  <a
                    onClick={(e)=>{debugger;setOpenEditForm(meeting.MeetingName); setIsOpen(true)}}
                    className="block px-3 py-1 text-sm/6 text-gray-900 data-[focus]:bg-gray-50 data-[focus]:outline-none"
                  >
                    <div className='flex items-center gap-x-2'>                             <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="size-5">
                  <path d="m2.695 14.762-1.262 3.155a.5.5 0 0 0 .65.65l3.155-1.262a4 4 0 0 0 1.343-.886L17.5 5.501a2.121 2.121 0 0 0-3-3L3.58 13.419a4 4 0 0 0-.885 1.343Z" />
                </svg> Edit</div>
                  </a>
                </MenuItem>
              </MenuItems>
            </Menu>
          </div>
          <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm/6">
            <div className="flex justify-between gap-x-4 py-3">
              <dt className="text-gray-500">Description</dt>
              <dd className="text-gray-700">
                <div className="font-medium text-gray-900">{meeting.Description}</div>
              </dd>
            </div>
            <div className="flex justify-between gap-x-4 py-3">
              <dt className="text-gray-500">Amount</dt>
              <dd className="flex items-start gap-x-2">
                <div className="font-medium text-gray-900">{meeting.Amount}</div>
              </dd>
            </div>
          </dl>
        </li>)}
        else{
          customMeetings.push(<li key={meeting.MeetingName} className="overflow-hidden rounded-xl border border-gray-200">
            <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
              <img
              alt={meeting.MeetingName}
              src={'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60'}
              className="size-12 flex-none rounded-lg bg-white object-cover ring-1 ring-gray-900/10"
            />
            <div className="text-sm/6 font-medium text-gray-900">{meeting.MeetingName}</div>
            <Menu as="div" className="relative ml-auto">
              <MenuButton className="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500">
                <span className="sr-only">Open options</span>
                <EllipsisHorizontalIcon aria-hidden="true" className="size-5" />
              </MenuButton>
              <MenuItems
                transition
                className="absolute right-0 z-10 mt-0.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
              >
                <MenuItem>
                
                  <a
                    onClick={(e)=>{debugger;setOpenEditForm(meeting.MeetingName); setIsOpen(true)}}
                    className="block px-3 py-1 text-sm/6 text-gray-900 data-[focus]:bg-gray-50 data-[focus]:outline-none"
                  >

                    
                    <div className='flex items-center gap-x-2'>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="size-5">
                  <path d="m2.695 14.762-1.262 3.155a.5.5 0 0 0 .65.65l3.155-1.262a4 4 0 0 0 1.343-.886L17.5 5.501a2.121 2.121 0 0 0-3-3L3.58 13.419a4 4 0 0 0-.885 1.343Z" />
                </svg>
                      Edit
                    </div>
                  </a>
                </MenuItem>
                <MenuItem>
                  <a
                    onClick={(e) => {
                      setMeetingToDelete(meeting.ROWID);
                      setDeleteModalOpen(true);
                    }}
                    className="block px-3 py-1 text-sm/6 text-gray-900 data-[focus]:bg-gray-50 data-[focus]:outline-none"
                  >
                    <div className='flex items-center gap-x-2'>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="size-5">
                        <path fillRule="evenodd" d="M8.75 1A2.75 2.75 0 0 0 6 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 1 0 .23 1.482l.149-.022.841 10.518A2.75 2.75 0 0 0 7.596 19h4.807a2.75 2.75 0 0 0 2.742-2.53l.841-10.52.149.023a.75.75 0 0 0 .23-1.482A41.03 41.03 0 0 0 14 4.193V3.75A2.75 2.75 0 0 0 11.25 1h-2.5ZM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4ZM8.58 7.72a.75.75 0 0 0-1.5.06l.3 7.5a.75.75 0 1 0 1.5-.06l-.3-7.5Zm4.34.06a.75.75 0 1 0-1.5-.06l-.3 7.5a.75.75 0 1 0 1.5.06l.3-7.5Z" clipRule="evenodd" />
                      </svg> 
                      Delete
                    </div>
                  </a>
                </MenuItem>
              </MenuItems>
            </Menu>
          </div>
          <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm/6">
            <div className="flex justify-between gap-x-4 py-3">
              <dt className="text-gray-500">Description</dt>
              <dd className="text-gray-700">
                <div className="font-medium text-gray-900">{meeting.Description}</div>
              </dd>
            </div>
            <div className="flex justify-between gap-x-4 py-3">
              <dt className="text-gray-500">Amount</dt>
              <dd className="flex items-start gap-x-2">
                <div className="font-medium text-gray-900">{meeting.Amount}</div>
              </dd>
            </div>
          </dl>
        </li>)
        }
      })
    }

    if(openEditForm!=undefined){
      formEle.push(<EditMeetingConf meetingDtls={meetingDtls} setMeetingDtls={setMeetingDtls} setIsOpen={setIsOpen} currentEditForm={openEditForm}></EditMeetingConf>)
    }
    
  return (
    <>{defaultMeetings.length!=0?
    <div className='h-screen overflow-y-scroll'>

      <OuterCard>
          <div className="border-b border-gray-200 bg-white px-4 py-2 sm:px-6">
            <h3 className="text-base font-semibold text-gray-900">Default Meetings</h3>
          </div>
          <ul role="list" className="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8 mt-4">
            {defaultMeetings}
          </ul>


          <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
            <h3 className="text-base font-semibold text-gray-900">Custom Meetings</h3>
          </div>
          <ul role="list" className="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8 mt-4">
            {customMeetings}
            {customMeetings.length<=2?<button
            onClick={()=>{setIsOpen(true);setOpenEditForm("NEW_MEETING")}}
            type="button"
            className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            <div className='flex items-center justify-center'>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
              </svg>
            </div>
            <span className="mt-2 block text-sm font-semibold text-gray-900">Add Meeting</span>
          </button>
            :<></>}
          </ul>
      </OuterCard>
    </div>:<></>}
    {isOpen==true?
    <CommonModal element={formEle} isOpen={isOpen} setIsOpen={setIsOpen} width={"w-[600px]"} height={"h-auto"}></CommonModal>:<></>
    }
    <ConfirmationModal 
        isOpen={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        onConfirm={handleDeleteMeeting}
        title="Delete Meeting"
        message="Are you sure you want to delete this meeting? This action cannot be undone."
        confirmButtonText="Delete"
        confirmButtonColor="red"
    />
    </>
  )
}

export default MeetingConf






















