import { PlusIcon } from '@heroicons/react/24/outline';
import React from 'react'

const AddCircularBtn = (props) => {

    let content = [] ;
    switch (props.size) {
        case '1':
            content.push(
                            <button type="button" onClick={(e)=>{props.onClickCallback(e)}}  className="h-8  m-1 ml-2 rounded-full  active:text-black bg-[#EEF2FF] p-1  text-indigo-600 shadow-sm hover:bg-indigo-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                            <PlusIcon aria-hidden="true" className="h-5 w-5" />
                            </button>
                        );
            break;
        case '2':
            content.push(
                            <button type="button" onClick={(e)=>{props.onClickCallback(e)}}  className="h-8  m-1 ml-2 rounded-full  active:text-black bg-[#EEF2FF] p-1.5 text-indigo-600 shadow-sm hover:bg-indigo-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                            <PlusIcon aria-hidden="true" className="h-5 w-5" />
                             </button>
                        );
            break;
        case '3':
            content.push(   
                            <button type="button" onClick={(e)=>{props.onClickCallback(e)}}  className="h-8 m-1 ml-2  rounded-full  active:text-black bg-[#EEF2FF] p-2 text-indigo-600 shadow-sm hover:bg-indigo-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                            <PlusIcon aria-hidden="true" className="h-5 w-5" />
                            </button>
                        );
            break;
        default:
            content.push(
                            <button type="button" onClick={(e)=>{props.onClickCallback(e)}}  className="h-8 m-1 ml-2  rounded-full  active:text-black bg-[#EEF2FF] p-1 relative text-indigo-600 shadow-sm hover:bg-indigo-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                            <PlusIcon aria-hidden="true" className="h-5 w-5" />
                            </button>
                        );
    }
  return (
        <>
            {content}
        </>
  )
}

export default AddCircularBtn