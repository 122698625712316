import React from 'react'

const Badge = (props) => {
  return (
        <span className="inline-flex items-center gap-x-0.5 rounded-md bg-indigo-50 px-2 py-1 text-xs font-medium text-indigo-700 ring-1 ring-inset ring-indigo-700/10">
                      {props.label}
            <button type="button" className="group relative -mr-1 size-3.5 rounded-sm hover:bg-indigo-600/20">
                <span className="sr-only">Remove</span>
                    <svg viewBox="0 0 14 14" className="size-3.5 stroke-indigo-600/50 group-hover:stroke-indigo-600/75">
                        <path d="M4 4l6 6m0-6l-6 6" />
                    </svg>
                    <span className="absolute -inset-1" />
            </button>
        </span>


  )
}

export default Badge