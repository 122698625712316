import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Modal from '../../Modal'



const people = [
    {
      name: 'Leslie Alexander',
      email: 'leslie.alexander@example.com',
      imageUrl:
        'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
    {
      name: 'Michael Foster',
      email: 'michael.foster@example.com',
      imageUrl:
        'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
    {
      name: 'Dries Vincent',
      email: 'dries.vincent@example.com',
      imageUrl:
        'https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
    {
      name: 'Lindsay Walton',
      email: 'lindsay.walton@example.com',
      imageUrl:
        'https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
    {
      name: 'Courtney Henry',
      email: 'courtney.henry@example.com',
      imageUrl:
        'https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
    {
      name: 'Tom Cook',
      email: 'tom.cook@example.com',
      imageUrl:
        'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
  ]

const TopMentors = () => {

    const [topExperts, setTopExperts] = useState([])
    const [isOpen, setIsOpen] = useState(false)
    const [selectedMentor, setSelectedMentor] = useState(null)
    const [pageNames, setPageNames] = useState('')



   


    useEffect(()=>{
        axios.get('/server/userActions/getTopExperts').then((res)=>{
            setTopExperts(res.data)
        })
    },[])

    const handleViewProfile = (person, e) => {
        e.stopPropagation()
        setIsOpen(true)
        setSelectedMentor(person.userId)
        setPageNames('UserPreview')
    }

    return (
        <>
        <div className='h-full'>
        <ul role="list" className="divide-y divide-gray-100 overflow-y-scroll">
        {topExperts.map((person) => (
          <li 
            key={person.Email} 
            className="group flex items-center justify-between gap-x-4 py-5 px-2 hover:bg-gray-50 rounded-lg cursor-pointer transition-colors duration-150"
            onClick={() => {
              setIsOpen(true)
              setSelectedMentor(person.userId)
              setPageNames('UserPreview')
            }}
          >
            <div className="flex min-w-0 gap-x-4">
              <img 
                alt="" 
                src={process.env.PUBLIC_URL+"/Assets/propic"+Math.floor(Math.random() * 10)+".jpeg"} 
                className="size-12 flex-none rounded-full bg-gray-50" 
              />
              <div className="min-w-0">
                <p className="text-sm/6 font-semibold text-gray-900">{person.FirstName+" "+person.LastName}</p>
                <div className="mt-2 flex flex-wrap gap-2">
                  {person.SkillSets.split(',').map((skill, index) => (
                    <span 
                      key={index}
                      className="inline-flex items-center rounded-full bg-indigo-50 px-2 py-1 text-xs font-medium text-indigo-700 ring-1 ring-inset ring-indigo-700/10"
                    >
                      {skill.trim()}
                    </span>
                  ))}
                </div>
              </div>
            </div>
            <div
              onClick={(e) => handleViewProfile(person, e)}
              className="rounded-full bg-white px-3.5 py-1.5 text-xs font-semibold text-gray-900 
              shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-indigo-50 hover:text-indigo-600 
              transition-colors duration-150 opacity-0 group-hover:opacity-100"
            >
              View Profile
            </div>
          </li>
        ))}
      </ul>
      </div>
      {isOpen && (
        <Modal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        setSelectedMentor={setSelectedMentor}
        selectedMentor={selectedMentor}
        pageName={pageNames}
        setPageNames={setPageNames}
    />
    )}
    </>
  )
}

export default TopMentors
