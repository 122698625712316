import React, { useState } from 'react';
import ScheduleSlot from './ScheduleSlot'; // Import the ScheduleSlot component
import axios from 'axios';

import {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    Popover,
    PopoverBackdrop,
    PopoverButton,
    PopoverPanel,
  } from '@headlessui/react'
  import {
    ArrowLongLeftIcon,
    CheckIcon,
    HandThumbUpIcon,
    HomeIcon,
    MagnifyingGlassIcon,
    PaperClipIcon,
    QuestionMarkCircleIcon,
    UserIcon,
    ArrowLeftIcon,
    InformationCircleIcon,
    PaperAirplaneIcon,
    EllipsisVerticalIcon,
    ArrowDownTrayIcon,
    TrashIcon,
  } from '@heroicons/react/20/solid'
  import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline'
import CalendarIcon from './CalendarIcon';
import { Transition } from '@headlessui/react';
import { Fragment } from 'react';
import Modal from './Modal';

  const user = {
    name: 'Whitney Francis',
    email: 'whitney@example.com',
    imageUrl:
      'https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80',
  }
  const navigation = [
    { name: 'Dashboard', href: '#' },
    { name: 'Jobs', href: '#' },
    { name: 'Applicants', href: '#' },
    { name: 'Company', href: '#' },
  ]
  const breadcrumbs = [
    { name: 'Jobs', href: '#', current: false },
    { name: 'Front End Developer', href: '#', current: false },
    { name: 'Applicants', href: '#', current: true },
  ]
  const userNavigation = [
    { name: 'Your Profile', href: '#' },
    { name: 'Settings', href: '#' },
    { name: 'Sign out', href: '#' },
  ]
  const attachments = [
    { name: 'resume_front_end_developer.pdf', href: '#' },
    { name: 'coverletter_front_end_developer.pdf', href: '#' },
  ]
  const eventTypes = {
    applied: { icon: UserIcon, bgColorClass: 'bg-gray-400' },
    advanced: { icon: HandThumbUpIcon, bgColorClass: 'bg-blue-500' },
    completed: { icon: CheckIcon, bgColorClass: 'bg-green-500' },
  }
  const timeline = [
    {
      id: 1,
      type: eventTypes.applied,
      content: 'Applied to',
      target: 'Front End Developer',
      date: 'Sep 20',
      datetime: '2020-09-20',
    },
    {
      id: 2,
      type: eventTypes.advanced,
      content: 'Advanced to phone screening by',
      target: 'Bethany Blake',
      date: 'Sep 22',
      datetime: '2020-09-22',
    },
    {
      id: 3,
      type: eventTypes.completed,
      content: 'Completed phone screening with',
      target: 'Martha Gardner',
      date: 'Sep 28',
      datetime: '2020-09-28',
    },
    {
      id: 4,
      type: eventTypes.advanced,
      content: 'Advanced to interview by',
      target: 'Bethany Blake',
      date: 'Sep 30',
      datetime: '2020-09-30',
    },
    {
      id: 5,
      type: eventTypes.completed,
      content: 'Completed interview with',
      target: 'Katherine Snyder',
      date: 'Oct 4',
      datetime: '2020-10-04',
    },
  ]
  const comments = [
    {
      id: 1,
      name: 'Leslie Alexander',
      date: '4d ago',
      imageId: '1494790108377-be9c29b29330',
      body: 'Ducimus quas delectus ad maxime totam doloribus reiciendis ex. Tempore dolorem maiores. Similique voluptatibus tempore non ut.',
    },
    {
      id: 2,
      name: 'Michael Foster',
      date: '4d ago',
      imageId: '1519244703995-f4e0f30006d5',
      body: 'Et ut autem. Voluptatem eum dolores sint necessitatibus quos. Quis eum qui dolorem accusantium voluptas voluptatem ipsum. Quo facere iusto quia accusamus veniam id explicabo et aut.',
    },
    {
      id: 3,
      name: 'Dries Vincent',
      date: '4d ago',
      imageId: '1506794778202-cad84cf45f1d',
      body: 'Expedita consequatur sit ea voluptas quo ipsam recusandae. Ab sint et voluptatem repudiandae voluptatem et eveniet. Nihil quas consequatur autem. Perferendis rerum et.',
    },
  ]
  
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

const BookingOverview = (props) => {
  // Dummy meeting data
  debugger
  console.log("Selectedddd.....", props.selectedSession)
  const meetingDetails = {
    name: "Technical Consultation Session",
    expertName: "Dr. Sarah Johnson",
    expertImage: "/Assets/propic3.jpeg",
    meetingDate: new Date("2024-03-25T14:30:00"),
    duration: 60,
    bookedDate: new Date("2024-03-20"),
    description: "One-on-one technical consultation to discuss system architecture and best practices for scaling microservices. We'll cover database optimization, caching strategies, and deployment pipelines.",
    documents: [
      { name: 'project_requirements.pdf', size: '2.4 MB', type: 'application/pdf' },
      { name: 'project_requirements.pdf', size: '2.4 MB', type: 'application/pdf' },
      { name: 'project_requirements.pdf', size: '2.4 MB', type: 'application/pdf' },
      { name: 'project_requirements.pdf', size: '2.4 MB', type: 'application/pdf' },
      { name: 'project_requirements.pdf', size: '2.4 MB', type: 'application/pdf' },
      { name: 'project_requirements.pdf', size: '2.4 MB', type: 'application/pdf' },
      { name: 'project_requirements.pdf', size: '2.4 MB', type: 'application/pdf' },
      { name: 'project_requirements.pdf', size: '2.4 MB', type: 'application/pdf' },
      { name: 'project_requirements.pdf', size: '2.4 MB', type: 'application/pdf' },
      { name: 'architecture_diagram.png', size: '1.8 MB', type: 'image/png' },
      { name: 'technical_specs.docx', size: '892 KB', type: 'application/docx' }
    ]
  };

  // Dummy conversation data
  const [conversations, setConversations] = useState([
    {
      id: 1,
      author: {
        name: "Dr. Sarah Johnson",
        role: "Expert",
        imageUrl: "/Assets/propic3.jpeg"
      },
      message: "Hello! Looking forward to our technical consultation. Please share any specific topics you'd like to focus on.",
      timestamp: "2 days ago"
    },
    {
      id: 2,
      author: {
        name: "John Smith",
        role: "Learner",
        imageUrl: "/Assets/propic5.jpeg"
      },
      message: "Hi Dr. Johnson! I'd like to discuss microservices architecture and database optimization strategies in detail.",
      timestamp: "1 day ago"
    },
    {
      id: 3,
      author: {
        name: "Dr. Sarah Johnson",
        role: "Expert",
        imageUrl: "/Assets/propic3.jpeg"
      },
      message: "Perfect! I'll prepare some case studies and examples. Feel free to share any current challenges you're facing.",
      timestamp: "1 day ago"
    }
  ]);

  const [newMessage, setNewMessage] = useState('');
  const [openReschedule, setOpenReschedule] = useState(false);


  const handleSendMessage = (e) => {
    e.preventDefault();
    if (!newMessage.trim()) return; // Don't send empty messages
    
    // Add new message to conversations
    const newConversation = {
      id: conversations.length + 1,
      author: {
        name: "John Smith", // Replace with actual user name
        role: "Learner",
        imageUrl: "/Assets/propic5.jpeg" // Replace with actual user image
      },
      message: newMessage,
      timestamp: "Just now"
    };
    
    setConversations([...conversations, newConversation]);
    setNewMessage('');
  };

  const handleFileUpload = (event) => {
    // Handle file upload logic here
    console.log('File uploaded:', event.target.files[0]);
  };

  return (
    <>
    <div className="min-h-full flex flex-col h-screen overflow-auto">
      {/* Header with back button and reschedule option */}
      <div className="flex-shrink-0 pt-6">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mb-4 flex justify-between">
          <button
            onClick={props.onBack}
            className="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700"
          >
            <ArrowLeftIcon className="mr-2 h-5 w-5" />
            Back
          </button>

          <button
            type="button"
            onClick={() => setOpenReschedule(true)}
            className="inline-flex items-center justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            Ask for reschedule
          </button>
        </div>
      </div>

      {/* Main content area with grid layout */}
      <div className="mx-auto w-full px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
          {/* Left side - Meeting Details and Documents */}
          <div className="lg:col-span-2 space-y-6">
            {/* Meeting Details Card */}
            <div className="bg-white shadow rounded-lg overflow-hidden">
              {/* Expert Info Section */}
              <div className="p-6 border-b border-gray-200">
                <div className="flex items-center space-x-5">
                  <div className="shrink-0">
                    <img
                      alt="Expert profile"
                      src={process.env.PUBLIC_URL + meetingDetails.expertImage}
                      className="h-12 w-12 rounded-full object-cover"
                    />
                  </div>
                  <div>
                    <h1 className="text-lg font-semibold text-gray-900">{meetingDetails.name}</h1>
                    <p className="text-sm text-gray-500">Expert: {meetingDetails.expertName}</p>
                  </div>
                </div>
              </div>

              {/* Meeting Details Section */}
              <div className="p-6 grid grid-cols-1 gap-6 md:grid-cols-2">
                {/* Calendar and Time */}
                <div className="space-y-4">
                  <div className="flex items-center space-x-4">
                    <CalendarIcon 
                      dateVal={meetingDetails.meetingDate.getDate()} 
                      monthVal={meetingDetails.meetingDate.toLocaleString('default', { month: 'short' }).toUpperCase()} 
                    />
                    <div>
                      <p className="text-sm font-medium text-gray-500">Meeting Time</p>
                      <p className="text-sm font-medium text-gray-900">
                        {meetingDetails.meetingDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                      </p>
                    </div>
                  </div>

                  <div>
                    <p className="text-sm font-medium text-gray-500">Duration</p>
                    <p className="text-sm font-medium text-gray-900">{meetingDetails.duration} minutes</p>
                  </div>

                  <div>
                    <p className="text-sm font-medium text-gray-500">Booked on</p>
                    <p className="text-sm font-medium text-gray-900">
                      {meetingDetails.bookedDate.toLocaleDateString()}
                    </p>
                  </div>
                </div>

                {/* Meeting Description */}
                <div>
                  <h3 className="text-sm font-medium text-gray-500">Meeting Description</h3>
                  <p className="mt-2 text-sm text-gray-700">
                    {meetingDetails.description}
                  </p>
                </div>
              </div>
            </div>

            {/* Documents Section */}
            <div className="bg-white shadow rounded-lg overflow-hidden">
              <div className="p-6">
                <div className="flex justify-between items-center mb-4">
                  <h2 className="text-sm font-semibold text-gray-900">Meeting Documents</h2>
                  <div className="relative">
                    <input
                      type="file"
                      className="hidden"
                      id="file-upload"
                      onChange={handleFileUpload}
                      multiple
                    />
                    <label
                      htmlFor="file-upload"
                      className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 cursor-pointer"
                    >
                      <PaperClipIcon className="h-5 w-5 mr-2 text-gray-500" />
                      Attach Files
                    </label>
                  </div>
                </div>

                {/* Document List */}
                <div className="mt-4">
                  <ul className="divide-y divide-gray-200">
                    {meetingDetails.documents.map((doc, index) => (
                      <li key={index} className="py-4 flex items-center justify-between group">
                        <div className="flex items-center">
                          <div className="flex-shrink-0">
                            <PaperClipIcon className="h-5 w-5 text-gray-400" />
                          </div>
                          <div className="ml-4">
                            <p className="text-sm font-medium text-gray-900">{doc.name}</p>
                            <p className="text-sm text-gray-500">{doc.size}</p>
                          </div>
                        </div>
                        
                        <Menu as="div" className="relative inline-block text-left">
                          <div>
                            <Menu.Button className="invisible group-hover:visible inline-flex items-center p-2 text-gray-400 hover:text-gray-600">
                              <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                            </Menu.Button>
                          </div>

                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="absolute right-0 z-10 mt-2 w-36 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                              <div className="py-1">
                                <Menu.Item>
                                  {({ active }) => (
                                    <button
                                      className={`${
                                        active ? 'bg-gray-100' : ''
                                      } flex w-full items-center px-4 py-2 text-sm text-gray-700`}
                                      onClick={() => {/* Handle download */}}
                                    >
                                      <ArrowDownTrayIcon className="mr-3 h-5 w-5 text-gray-400" />
                                      Download
                                    </button>
                                  )}
                                </Menu.Item>
                                <Menu.Item>
                                  {({ active }) => (
                                    <button
                                      className={`${
                                        active ? 'bg-gray-100' : ''
                                      } flex w-full items-center px-4 py-2 text-sm text-red-700`}
                                      onClick={() => {/* Handle remove */}}
                                    >
                                      <TrashIcon className="mr-3 h-5 w-5 text-red-400" />
                                      Remove
                                    </button>
                                  )}
                                </Menu.Item>
                              </div>
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      </li>
                    ))}
                  </ul>
                </div>

                {/* Upload Instructions */}
                <div className="mt-3">
                  <div className="rounded-md bg-gray-50 p-3">
                    <div className="flex">
                      <div className="shrink-0">
                        <InformationCircleIcon className="h-4 w-4 text-gray-400" aria-hidden="true" />
                      </div>
                      <div className="ml-2">
                        <p className="text-xs text-gray-600">
                          PDF, DOC, DOCX, PNG, JPG (max 10MB)
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Meeting Actions */}
            <div className="flex justify-end space-x-4 mb-6">
              <button
                type="button"
                className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
              >
                Join Meeting
              </button>
              <button
                type="button"
                className="inline-flex items-center px-3 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
              >
                Add to Calendar
              </button>
            </div>
          </div>

          {/* Right side - Conversation */}
          <div className="lg:col-span-1">
            <div className="bg-white shadow rounded-lg overflow-hidden sticky top-6">
              <div className="p-6">
                <h2 className="text-sm font-semibold text-gray-900 mb-6">Conversation</h2>

                {/* Messages List with fixed height and scroll */}
                <div className="space-y-6 h-[calc(100vh-300px)] overflow-y-auto">
                  {conversations.map((conversation) => (
                    <div key={conversation.id} className="flex space-x-3">
                      <div className="flex-shrink-0">
                        <img
                          className="h-8 w-8 rounded-full"
                          src={process.env.PUBLIC_URL + conversation.author.imageUrl}
                          alt={conversation.author.name}
                        />
                      </div>
                      <div className="flex-grow">
                        <div className="flex items-center justify-between">
                          <p className="text-sm font-medium text-gray-900">
                            {conversation.author.name}
                          </p>
                          <p className="text-sm text-gray-500">{conversation.timestamp}</p>
                        </div>
                        <p className="mt-1 text-sm text-gray-700">{conversation.message}</p>
                      </div>
                    </div>
                  ))}
                </div>

                {/* Message Input - Fixed at bottom */}
                <div className="mt-6 border-t border-gray-200 pt-4">
                  <form onSubmit={handleSendMessage} className="flex space-x-3">
                    <div className="flex-grow">
                      <textarea
                        rows={1}
                        name="message"
                        id="message"
                        className="block w-full rounded-md border-0 py-1.5 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600"
                        placeholder="Type your message..."
                        value={newMessage}
                        onChange={(e) => setNewMessage(e.target.value)}
                      />
                    </div>
                    <button
                      type="submit"
                      className="inline-flex items-center rounded-md bg-blue-600 px-3 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                    >
                      <PaperAirplaneIcon className="h-5 w-5 mr-2" />
                      Send
                    </button>
                  </form>

                  <div className="mt-2">
                    <p className="text-xs text-gray-500 italic">
                      Messages are visible to all participants
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {openReschedule && (
      <Modal pageName={props.pageName} flow={"reschedule"} 
      setPageNames={props.setPageName} 
      isOpen={openReschedule} setIsOpen={setOpenReschedule} 
      selectedMentor={props.selectedSession?.Host} selectedSession={props.selectedSession}></Modal>
    )}
    </>
  );
};

export default BookingOverview;


























  
 