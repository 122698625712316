import React from 'react'
import { CloudArrowUpIcon, LockClosedIcon, ServerIcon } from '@heroicons/react/20/solid'


const PrimaryFeature = () => {


    const features = [
        {
          name: 'Choose a Mentor.',
          description:
            'With Meet Masters, you will get access to top experts who are eager to share their nuggets of wisdom. You will have the accessibility to navigate through the list of mentors and can select a mentor of your choice. You can get valuable insights and real-world exposure by selecting a mentor from a specialization that you are most interested in',
          icon: CloudArrowUpIcon,
        },
        {
          name: 'Flexible Schedules.',
          description: 'Don’t have to rush your way through things, at Meet Masters, you’ll be able to schedule mock interviews at your flexibility. We understand your time, it shouldn’t be a constraint, and we help you to sort it out. .',
          icon: LockClosedIcon,
        },
        {
          name: 'Personalized Feedbacks.',
          description: 'With Meet Masters, you will not only get to attend mock interviews, but receive valuable feedback. As Bill Gates nicely quotes, “We all need people who give us feedback. That’s how we improve"',
          icon: ServerIcon,
        },
      ]


  return (
    <div className="overflow-hidden bg-white py-24 sm:py-32">
    <div className="mx-auto max-w-7xl md:px-6 lg:px-8">
      <div className="grid grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:grid-cols-2 lg:items-start">
        <div className="px-6 lg:px-0 lg:pr-4 lg:pt-4">
          <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-lg">
            <p className="mt-2 text-pretty text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
            Practice with Mocks, Thrive in Real Life!
            </p>
            <p className="mt-6 text-lg/8 text-gray-600">
            Meet experienced masters who help you master the art of “cracking interviews” in real time. 
            </p>
            <dl className="mt-10 max-w-xl space-y-8 text-base/7 text-gray-600 lg:max-w-none">
              {features.map((feature) => (
                <div key={feature.name} className="relative pl-9">
                  <dt className="inline font-semibold text-gray-900">
                    <feature.icon aria-hidden="true" className="absolute left-1 top-1 h-5 w-5 text-indigo-600" />
                    {feature.name}
                  </dt>{' '}
                  <dd className="inline">{feature.description}</dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
        <div className="sm:px-6 lg:px-0">
          <div className="relative isolate overflow-hidden bg-indigo-500 px-6 pt-8 sm:mx-auto sm:max-w-2xl sm:rounded-3xl sm:pl-16 sm:pr-0 sm:pt-16 lg:mx-0 lg:max-w-none">
            <div
              aria-hidden="true"
              className="absolute -inset-y-px -left-3 -z-10 w-full origin-bottom-left skew-x-[-30deg] bg-indigo-100 opacity-20 ring-1 ring-inset ring-white"
            />
            <div className="mx-auto max-w-2xl sm:mx-0 sm:max-w-none">
              <img
                alt="Product screenshot"
                src={process.env.PUBLIC_URL+"/Assets/app.png"}
                width={2432}
                height={1442}
                className="-mb-12 w-[57rem] max-w-none rounded-tl-xl bg-gray-800 ring-1 ring-white/10"
              />
            </div>
            <div
              aria-hidden="true"
              className="pointer-events-none absolute inset-0 ring-1 ring-inset ring-black/10 sm:rounded-3xl"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default PrimaryFeature