import React from 'react'
import ExploreMentor from './Modules/ExploreMentor';
import Dashboard from './Modules/Dashboard';
import Documents from './Modules/Documents';
import UserProfile from './Modules/UserProfile';
import UpComingSessions from './UpComingSessions';
import MentorProfile from './Mentor/MentorProfile';
import BookingsUser from './BookingsUser';
import MainContainer from './MainContainer';

const MainContent = (props) => {

    let content = [] ;
    switch (props.path) {
        case 'dashboard':
            content.push(<Dashboard></Dashboard>);
            break;
        case 'exploreMentors':
            content.push(<ExploreMentor></ExploreMentor>);
            break;
        case 'userProfile':
            content.push(<UserProfile></UserProfile>);
            break;
        case 'documents':
            content.push(<MainContainer><Documents></Documents></MainContainer>);
            break;
        case 'bookings':
            content.push( <MainContainer><BookingsUser></BookingsUser></MainContainer>);
            break;
        case 'mentorBookings':
            content.push( <MainContainer><BookingsUser></BookingsUser></MainContainer>);
            break;
        case 'upcomingSessions':
            content.push(<UpComingSessions></UpComingSessions>);
            break;
        case 'mentorProfile':
            content.push(<MentorProfile></MentorProfile>);
            break;    
        default:
            content.push(<h1>Error</h1>);
    }
    
  return (
    <>
        {content}
    </>

  )
}

export default MainContent