import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom';
import Modal from './Modal'
import EmptyStateInfo from './EmptyStateInfo'
import BookingOverview from './BookingOverview';

const people = [
    {
      name: 'Lindsay Walton',
      title: 'Front-end Developer',
      department: 'Optimization',
      email: 'lindsay.walton@example.com',
      role: 'Member',
      image:
        'https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
    // More people...
  ]
  



const BookingsUser = () => {

    const navigate = useNavigate();
    const [sessions, setSessions] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [selectedSession, setSelectedSession] = useState(null)
    const [isOpen, setIsOpen] = useState(false)
    const [error, setError] = useState(null)
    const [pageName, setPageName] = useState("ScheduleSlot")

    useEffect(() => {
        async function fetchSessions() {
            setIsLoading(true)
            try {
                const response = await axios.get('/server/userActions/getUpcomingSessions')
                debugger
                setSessions(response.data)
            } catch (err) {
                setError('Failed to fetch upcoming sessions')
                console.error('Error fetching sessions:', err)
            } finally {
                setIsLoading(false)
            }
        }

        fetchSessions()
    }, [])

    const handleBackFromOverview = () => {
        setIsOpen(false);
        setSelectedSession(null);
    };

    if (isLoading) {
        return <div className="flex justify-center items-center min-h-screen">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-600"></div>
        </div>
    }

    if (error) {
        return <div className="text-red-600 text-center mt-4">{error}</div>
    }

    return(
      <>
      {isOpen && (
        <BookingOverview 
          pageName={pageName} 
          flow={"reschedule"} 
          setPageNames={setPageName} 
          isOpen={isOpen} 
          setIsOpen={setIsOpen} 
          selectedMentor={selectedSession?.Host} 
          selectedSession={selectedSession}
          onBack={handleBackFromOverview}
        />
      )}
      {!isOpen && (
     
      <div className="px-4  sm:px-6 lg:px-8 mt-10">
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 border-2 border-gray-200 rounded">
            <div className="inline-block min-w-full py-2 align-middle h-screen bg-gray-50">
              {(Array.isArray(sessions) && sessions.length > 0) ? (
              <table className="min-w-full">
                <thead>
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:pl-8">
                      Mentor
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Meeting Name
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Date
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Time
                    </th>
                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6 lg:pr-8">
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200  bg-white">
                  {sessions.map((session) => (
                    <tr key={session.id} className="hover:bg-gray-50 transition-colors duration-200 cursor-pointer" onClick={() => {setSelectedSession(session); setIsOpen(true)}}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 sm:pl-6 lg:pl-8">
                        <div className="flex items-center">
                          <div className="h-11 w-11 flex-shrink-0">
                            <img alt="" src={process.env.PUBLIC_URL+"/Assets/propic"+Math.floor(Math.random() * 10)+".jpeg"} className="h-11 w-11 rounded-full object-cover" />
                          </div>
                          <div className="ml-4">
                            <div className="font-medium text-gray-900">{session.mentor?.name || 'Mentor Name'}</div>
                            <div className="text-gray-500">{session.mentor?.email || 'mentor@example.com'}</div>
                          </div>
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm">
                        <div className="text-gray-900 font-medium">{session.meetingName || 'Meeting Title'}</div>
                        <div className="text-gray-500">{session.description || 'Meeting Description'}</div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm">
                        <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                          {session.Meeting_Date}
                        </span>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {session.Meeting_DateTime}
                      </td>
                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 lg:pr-8">
                        <a href="#" className="text-indigo-600 hover:text-indigo-900">
                          Join<span className="sr-only">, {session.meetingName}</span>
                        </a>
                      </td>
                    </tr>
                  ))}
                   {sessions.map((session) => (
                    <tr key={session.id} className="hover:bg-gray-50 transition-colors duration-200 cursor-pointer" onClick={() => {setSelectedSession(session); setIsOpen(true)}}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 sm:pl-6 lg:pl-8">
                        <div className="flex items-center">
                          <div className="h-11 w-11 flex-shrink-0">
                            <img alt="" src={process.env.PUBLIC_URL+"/Assets/propic"+Math.floor(Math.random() * 10)+".jpeg"} className="h-11 w-11 rounded-full object-cover" />
                          </div>
                          <div className="ml-4">
                            <div className="font-medium text-gray-900">{session.mentor?.name || 'Mentor Name'}</div>
                            <div className="text-gray-500">{session.mentor?.email || 'mentor@example.com'}</div>
                          </div>
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm">
                        <div className="text-gray-900 font-medium">{session.meetingName || 'Meeting Title'}</div>
                        <div className="text-gray-500">{session.description || 'Meeting Description'}</div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm">
                        <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                          {session.Meeting_Date}
                        </span>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {session.Meeting_DateTime}
                      </td>
                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 lg:pr-8">
                        <a href="#" className="text-indigo-600 hover:text-indigo-900">
                          Join<span className="sr-only">, {session.meetingName}</span>
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              ) : null}
            </div>
          </div>
        </div>
      </div>
       )}
      {Array.isArray(sessions) && sessions.length > 0 ? <></> : 
        <div className="flex justify-center items-center flex-col gap-4 mt-10">
          <EmptyStateInfo icon="Conversation" text="Your learning journey starts with one step!!" isBtn={true} btnText="Connect with Experts" onClick={() => {navigate('/app/user/exploreMentors')}} />
        </div>
      }
      

      </>
    )
}

export default BookingsUser