import React from 'react'
import { ChevronDownIcon } from '@heroicons/react/16/solid'


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Subtab = (props) => {
  return (
    <div className=''>
    <div className=" ml-5 grid grid-cols-1 sm:hidden">
      {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
      <select
        defaultValue={props.tabs.find((tab) => tab.current).name}
        aria-label="Select a tab"
        className="col-start-1 row-start-1 w-full appearance-none rounded-md bg-white py-2 pl-3 pr-8 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600"
      >
        {props.tabs.map((tab) => (
          <option key={tab.type}>{tab.name}</option>
        ))}
      </select>
      <ChevronDownIcon
        aria-hidden="true"
        className="pointer-events-none col-start-1 row-start-1 mr-2 size-5 self-center justify-self-end fill-gray-500"
      />
    </div>
    <div className="hidden sm:block">
      <div className="border-b border-gray-200">
        <nav aria-label="Tabs" className="ml-5 -mb-px flex space-x-8">
          {props.tabs.map((tab) => (
            <a
              key={tab.type}
              onClick={(e)=>{props.setSubTab(tab.type)}}
              aria-current={tab.current ? 'page' : undefined}
              className={classNames(
                props.subTab==tab.type
                  ? 'border-indigo-500 text-indigo-600'
                  : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                'whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium',
              )}
            >
              {tab.name}
            </a>
          ))}
        </nav>
      </div>
    </div>
    <div className="mt-4">
      {props.children}
    </div>
  </div>
  )
}

export default Subtab