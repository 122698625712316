// import React from 'react'

// import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
// import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
// import { EllipsisVerticalIcon } from '@heroicons/react/20/solid'


import React from 'react'

import { useState } from 'react'
import { Radio, RadioGroup } from '@headlessui/react'
import { CheckCircleIcon } from '@heroicons/react/20/solid'

function getNext7days () {
  const today = new Date();
  const next7Days = [];
  for (let i = 1; i < 7; i++) {
      const nextDay = new Date(today);
      nextDay.setDate(today.getDate() + i);
      const dayDetails = {
          year: nextDay.getFullYear(),                  // Year (e.g., 2024)
          date: nextDay.getDate(),                     // Day of the month (e.g., 16)
          day: nextDay.toLocaleString('en-US', { weekday: 'short' }), // Day name (e.g., Monday)
          month: nextDay.toLocaleString('en-US', { month: 'short' })  // Month name (e.g., December)
      };
      next7Days.push(dayDetails);
  }
  return next7Days
}
const DateNtimePicker = (props) => {
    
    const [AvailDays, setAvailDays] = useState([])
    let next7days = getNext7days()
    let jsonVal = undefined

    if(props.mentorAvail!=undefined && AvailDays.length==0){
      jsonVal = props.mentorAvail
      const days = []
      debugger
      Object.keys(next7days).forEach(val=>{
        let day= next7days[val].day.toUpperCase()
        let temp = []
        let timings = jsonVal.val[day]
        Object.keys(timings).forEach((t)=>{
         if(jsonVal.val[day][t].isChecked==true && temp.indexOf(day)=="-1"){
             let res = {}
             res["name"]  = next7days[val].day
             res["date"]  = next7days[val].date
             res["month"]  = next7days[val].month
             temp.push(day)
             days.push(res)
         }
        })
      })
      props.callDetails.current["sessionDate"] = days
      setAvailDays(days)
    }

  return (
    AvailDays!=undefined && AvailDays.length!=0?
    <div className="m-5">
    <fieldset>
    <legend className="text-sm/6 font-semibold text-indigo-600">Select Date</legend>
    <RadioGroup
      onChange={(e)=>{debugger;props.setSelDate(e)}}
      className="mt-2 grid grid-cols-1 gap-y-6 sm:grid-cols-7 sm:gap-x-2"
    >
      {  
      AvailDays.map((day) => (
          <Radio
          value={day}
          className="group relative flex cursor-pointer rounded-lg border border-gray-300 bg-white p-4 shadow-sm focus:outline-none data-[focus]:border-indigo-600 data-[focus]:ring-2 data-[focus]:ring-indigo-600"
          >
          <span className="flex flex-1">
            <span className="flex flex-col">
              <span className="mt-1 flex items-center text-sm text-black">{day.date}</span>
              <span className="mt-1 flex items-center text-sm text-black">{day.name + " "+ day.month}</span>
            </span>
          </span>
          <CheckCircleIcon
            aria-hidden="true"
            className="size-5 text-indigo-600 [.group:not([data-checked])_&]:invisible"
          />
          <span
            aria-hidden="true"
            className="pointer-events-none absolute -inset-px rounded-lg border-2 border-transparent group-data-[focus]:border group-data-[checked]:border-indigo-600"
          />
        </Radio>
        ))
      }
    </RadioGroup>
  </fieldset>
  </div>:<></>
  )
}

export default DateNtimePicker