import React, { useRef } from 'react'
import { useState } from 'react'
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/24/outline'
import UserPreview from './UserPreview'
import TopBandWithClose from './TopBandWithClose'
import ScheduleSlot from './ScheduleSlot'
import SchedulePreview from './SchedulePreview'
import InvokePayment from './PaymentSuccess'
import PaymentSuccess from './PaymentSuccess'
import LoadingDots from './LoadingDots'
import BookingOverview from './BookingOverview'
import BookingBreadcrumbs from './BookingBreadcrumbs'

const Modal = (props) => {
  const callDetails = useRef({})
  
  const handleBack = () => {
    // Add logic here to handle back navigation based on your app's flow
    // This is a simple example - you'll need to adjust based on your needs
    switch(props.pageName) {
      case 'ScheduleSlot':
        props.setPageName('UserPreview');
        break;
      case 'SchedulePreview':
        props.setPageName('ScheduleSlot');
        break;
      case 'PaymentPage':
        props.setPageName('SchedulePreview');
        break;
      case 'BookingsUser':
        props.setPageName('PaymentPage');
        break;
      default:
        props.setIsOpen(false);
    }
  };
console.log("props.pageName==========",props.pageName)
  return (
    <Dialog open={props.isOpen} onClose={()=>{props.setIsOpen(false)}} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0  bg-gray-500/75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />
      <div className="fixed inset-0 z-10  mt-10 overflow-y-auto">
        <div className="flex  w-[1000px] h-[800px] ml-[370px] items- justify-center p-4 text-center sm:items- sm:p-0">
          <DialogPanel transition className="relative transform overflow-y-scroll h-[600px] rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in 
          sm:my-8 sm:w-full sm: data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95">
            <BookingBreadcrumbs 
              currentPage={props.pageName}
              onBack={handleBack}
            />
            <div className="px-4 pb-4 pt-5">
              {props.pageName=="UserPreview"?<UserPreview callDetails={callDetails} {...props}></UserPreview>:<></>}
              {props.pageName=="ScheduleSlot"?<ScheduleSlot callDetails={callDetails} {...props}></ScheduleSlot>:<></>}
              {props.pageName=="SchedulePreview"?<SchedulePreview callDetails={callDetails} {...props}></SchedulePreview>:<></>}
              {props.pageName=="LoadingDots"?<LoadingDots {...props}></LoadingDots>:<></>}
              {props.pageName=="PaymentPage"?<PaymentSuccess {...props}></PaymentSuccess>:<></>}
              {props.pageName=="BookingsUser"?<BookingOverview {...props}></BookingOverview>:<></>}
            </div>
          </DialogPanel>
          <TopBandWithClose setIsOpen={props.setIsOpen}></TopBandWithClose>
        </div>
      </div>
    </Dialog>
  )
}

export default Modal