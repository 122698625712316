import React, { useState } from 'react'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { ArrowLongRightIcon, XCircleIcon } from '@heroicons/react/20/solid'
import { useRazorpay, RazorpayOrderOptions } from "react-razorpay";
import axios, * as others from 'axios';
import { useNavigate } from 'react-router-dom';

const statuses = {
  Paid: 'text-green-700 bg-green-50 ring-green-600/20',
  Withdraw: 'text-gray-600 bg-gray-50 ring-gray-500/10',
  Overdue: 'text-red-700 bg-red-50 ring-red-600/10',
}
// const CallDetails = [
//   {
//     id: 1,
//     name: 'MockInterview With Naresh',
//     imageUrl: ,
//     otherDtls: { date: 'December 13, 2022', dateTime: '7:00 PM', amount: '$2,000.00', status: 'Overdue' },
//   },
// ]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const SchedulePreview = (props) => {
  const [amount, setAmount] = useState(150)
  const nav = useNavigate()
  const { error, isLoading, Razorpay } = useRazorpay();
  const RAZORPAY_KEY_ID = "rzp_test_C1YRDPmNMO1uqh";
  const handlePayment = async () => {
    try {
      debugger
      const totalAmount = props.callDetails.current.Amount + 21
      if(totalAmount=="" || totalAmount==undefined){
          console.log("Enter the amount")
      }else{
        //Creating Order
        const response =  await axios.get('/server/UserActions/Payment_order',{params:{amount:totalAmount,
          meetingId:props.callDetails.current.MeetingId}})
        const order = response
        console.log(order)

        const options = {
          key: RAZORPAY_KEY_ID,
          amount: order.data.amount,
          currency: order.data.currency,
          name: "Meet Masters", 
          description: "Meet Masters",
          order_id: order.data.id,
          handler: async (response) => {
            try {
              //Verification 
              // debugger
              const verifyResp =  await axios.get('/server/UserActions/Payment_verify',
                {
                  params:
                  {
                    razorpay_order_id: response.razorpay_order_id,
                    razorpay_payment_id: response.razorpay_payment_id,
                    razorpay_signature: response.razorpay_signature,
                    mentorUserId: props.selectedMentor,
                    callDetails: props.callDetails.current
                  }
                }
              )

            //   const meetingResp =  await axios.get('/server/UserActions/CreateMeeting',
            //     {
            //       params:
            //           {
            //             razorpay_order_id: response.razorpay_order_id,
            //             razorpay_payment_id: response.razorpay_payment_id,
            //             razorpay_signature: response.razorpay_signature,
            //             mentorUserId: props.selectedMentor,
                        
            //           }
            //     }
        
            // )




              props.setPageNames("LoadingDots")
            } catch (err) {
              alert("Payment failed: " + err.message);
            }
          },
          prefill: {
            name: "Customer Name", 
            email: "customer@gmail.com", 
            contact: "9999999999",
          },
          notes: {
            address: "Razorpay Corporate Office",
          },
          theme: {
            color: "#3399cc",
          },
        };
        const rzpay = new Razorpay(options);
        rzpay.open(options);
      }
    } catch (err) {
      alert("Error creating order: " + err.message);
    } 
    

  }
  let callDetails = props.callDetails.current
  return (
 <>

      <ul role="list" className="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-1 xl:gap-x-8">
        <li  className="overflow-hidden rounded-xl border border-gray-200">
          <div className="flex items-center justify-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
            <img
              alt={callDetails.name}
              src={process.env.PUBLIC_URL+"/Assets/propic"+Math.floor(Math.random() * 10)+".jpeg"}
              className="size-14 flex-none rounded-lg bg-white object-cover ring-1 ring-gray-900/10"
            />
            <div className="text-lg jus font-medium text-gray-900">{callDetails.MeetingName}</div>
     
          </div>
          <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm/6">
            
          <div className="flex justify-between gap-x-4 py-3">
              <dt className="text-black">Name</dt>
              <dd className="text-gray-700">
                <time>{callDetails.MeetingName}</time>
              </dd>
            </div>

            <div className="flex justify-between gap-x-4 py-3">
              <dt className="text-black">Duration</dt>
              <dd className="text-gray-700">
                <time>{callDetails.Duration}</time>
              </dd>
            </div>

          <div className="flex justify-between gap-x-4 py-3">
              <dt className="text-black">Session Type</dt>
              <dd className="text-gray-700">
                <time>{callDetails.MeetingName}</time>
              </dd>
            </div>

            <div className="flex justify-between gap-x-4 py-3">
              <dt className="text-black">Date</dt>
              <dd className="text-gray-700">
                <time>{callDetails.sessionDate.month}, {callDetails.sessionDate.date}</time>
              </dd>
            </div>

            <div className="flex justify-between gap-x-4 py-3">
              <dt className="text-black">Time</dt>
              <dd className="text-gray-700">
                <time>{callDetails.sessionTime}</time>
              </dd>
            </div>
          </dl>
        </li>
    </ul>


<ul role="list" className="mt-2 grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-1 xl:gap-x-8">
        <li  className="overflow-hidden rounded-xl border border-gray-200">
          <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
            <div className="text-sm font-medium text-gray-900">Order Summary</div>     
          </div>
          <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm/6">
            
          <div className="flex justify-between gap-x-4 py-3">
              <dt className="text-black">{callDetails.MeetingName}</dt>
              <dd className="text-gray-700">
                <time>{callDetails.Amount}</time>
              </dd>
            </div>

            <div className="flex justify-between gap-x-4 py-3">
              <dt className="text-black">Platform Fee</dt>
              <dd className="text-gray-700">
                <time>21 Rs</time>
              </dd>
            </div>

          <div className="flex justify-between gap-x-4 py-3">
              <dt className="text-black">Total</dt>
              <dd className="text-gray-700">
                <time>1021 Rs</time>
              </dd>
          </div>

          </dl>
        </li>
    </ul>



    <div className="mt-6 flex items-center justify-end gap-x-6">
            <button
              type="button"
              onClick={()=>{handlePayment()}}
              className="inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Confirm & Pay
              <ArrowLongRightIcon
                aria-hidden="true"
                className="ml-3 size-5 text-gray-400"
              />
            </button>
          </div>
    </>



















          
         


  )
}

export default SchedulePreview