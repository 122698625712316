import React from 'react';
import { ReactComponent as ConversationIcon } from '../../public/Assets/Conversation.svg';

//Your learning journey starts with one step
const EmptyStateInfo = (props) => {
const icons = {
    Conversation: ConversationIcon,
}

  return (
    <>
    <div className="flex justify-center items-center flex-col gap-4 mt-10">
    <div className="text-center py-5 w-56 h-56">
        {React.createElement(icons[props.icon])}
    </div>
    {props.text && <div className="text-center text-gray-500">{props.text}</div>}
    {props.isBtn && <button  onClick={props.onClick} className="bg-indigo-600 text-white px-4 py-2 rounded-md">{props.btnText}</button>}
    </div>
    </>
  );
};

export default EmptyStateInfo; 