import React, { useCallback } from 'react';
import MultiSelectOption from './MultiSelectOption';
import MultiRangeSlider from './MultiRangeSlider';
import Skills from './sampleDatas/Skills';
import Companies from './sampleDatas/Companies';
import Languages from './sampleDatas/Languages';
import CountryDatas from './sampleDatas/CountryDatas';

const Filter = ({ filterObj, updateFilterObj }) => {
    const updateFilterObjVal = useCallback((key, value) => {
        const newFilterObj = {
            ...filterObj,
            [key]: value
        };
        console.log('Updating filter:', key, value);
        updateFilterObj(newFilterObj);
    }, [filterObj, updateFilterObj]);

    const handleExperienceChange = useCallback((range) => {
        const newFilterObj = {
            ...filterObj,
            Exp_minVal: range.min,
            Exp_maxVal: range.max
        };
        console.log('Updating experience:', range);
        updateFilterObj(newFilterObj);
    }, [filterObj, updateFilterObj]);

    return (
        <div className="block w-full">
            <div className="mt-2">
                <span className="font-semibold">Filter By</span>
            </div>

            <div className="flex flex-col space-y-6 py-4">
                <div className="space-y-2">
                    <div className="text-sm font-semibold text-gray-900">
                        Skills
                    </div>
                    <MultiSelectOption 
                        keyName="SkillSets"
                        isFilter={true}
                        defaultOptions={filterObj.SkillSets}
                        options={Skills}
                        value={filterObj.SkillSets}
                        filterOnchange={updateFilterObjVal}
                    />
                </div>

                <div className="space-y-2">
                    <div className="text-sm font-semibold text-gray-900">
                        Companies
                    </div>
                    <MultiSelectOption 
                        keyName="Companies"
                        isFilter={true}
                        defaultOptions={filterObj.Companies}
                        value={filterObj.Companies}
                        options={Companies}
                        filterOnchange={updateFilterObjVal}
                    />
                </div>

                <div className="space-y-2">
                    <div className="text-sm font-semibold text-gray-900">
                        Experience
                    </div>
                    <MultiRangeSlider 
                        keyName="Experience"
                        isFilter={true}
                        min={0}
                        max={20}
                        value={{
                            min: filterObj.Exp_minVal || 0,
                            max: filterObj.Exp_maxVal || 20
                        }}
                        onChange={handleExperienceChange}
                    />
                </div>

                <div className="space-y-2 pt-4">
                    <div className="text-sm font-semibold text-gray-900">
                        Country
                    </div>
                    <MultiSelectOption 
                        keyName="Countries"
                        isFilter={true}
                        defaultOptions={filterObj.Countries}
                        value={filterObj.Countries}
                        options={CountryDatas}
                        filterOnchange={updateFilterObjVal}
                    />
                </div>

                <div className="space-y-2">
                    <div className="text-sm font-semibold text-gray-900">
                        Languages
                    </div>
                    <MultiSelectOption 
                        keyName="Languages"
                        isFilter={true}
                        defaultOptions={filterObj.Languages}
                        value={filterObj.Languages}
                        options={Languages}
                        filterOnchange={updateFilterObjVal}
                    />
                </div>
            </div>
        </div>
    );
};

export default React.memo(Filter, (prevProps, nextProps) => {
    return JSON.stringify(prevProps.filterObj) === JSON.stringify(nextProps.filterObj);
});