import React from 'react';
import { ChevronRightIcon, ArrowLeftIcon } from '@heroicons/react/24/outline';

const BookingBreadcrumbs = ({ currentPage, onBack }) => {
  const steps = {
    'UserPreview': 1,
    'ScheduleSlot': 2,
    'SchedulePreview': 3,
    'BookingsUser': 4
  };

  const getStepName = (page) => {
    const names = {
      'UserPreview': 'Expert Details',
      'ScheduleSlot': 'Schedule',
      'SchedulePreview': 'Booking Review',
      'BookingsUser': 'Booking Details'
    };
    return names[page] || page;
  };

  return (
    <div className="flex items-center space-x-4 px-4 py-3 border-b">
      <button
        onClick={onBack}
        className="text-gray-600 hover:text-gray-800 flex items-center"
      >
        <ArrowLeftIcon className="h-5 w-5" />
      </button>
      <div className="flex items-center space-x-2">
        {Object.entries(steps).map(([page, step], index) => (
          <React.Fragment key={page}>
            {index > 0 && (
              <ChevronRightIcon className="h-4 w-4 text-gray-400" />
            )}
            <span
              className={`text-sm ${
                currentPage === page
                  ? 'text-blue-600 font-medium'
                  : 'text-gray-500'
              }`}
            >
              {getStepName(page)}
            </span>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default BookingBreadcrumbs; 