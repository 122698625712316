import React from 'react'
import DocumentEmpty from '../../../../public/Assets/DocumentEmpty.svg'

const SharedDocuments = () => {
  return (
    <div className="text-center py-12">
            <div className="flex justify-center">
        <img src={DocumentEmpty} alt="No documents" className="h-20 w-20 text-gray-400" />
      </div>
      <h3 className="mt-2 text-sm font-semibold text-gray-900">No shared documents</h3>
      <p className="mt-1 text-sm text-gray-500">
        Documents shared with you will appear here
      </p>
    </div>
  )
}

export default SharedDocuments