import React from 'react'

const TextInputBox = (props) => {

  const onChangeCallback = (e)=>{
    props.onChangeCallback(e.currentTarget.value)
  }
  return (
    <input id={props.id} type="text" value={props.value} onChange={(e)=>{onChangeCallback(e)}} className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"/>
  )
}

export default TextInputBox