import React,{useState} from "react";
import Select, { components } from "react-select";

const DropdownSelect = (props) => {
  
  const onChangeCallback = (currObj) =>{
    // console.log("DropdownSelect on change value is ", e.tartget.value)
    props.onChangeCallback(currObj)
  }
  

  

  return (
      <Select 
      components={{IndicatorSeparator: () => null}} 
      options={props.options}
      defaultValue={props.defaultValue || 'Select'} 
      onChange={(e)=>{onChangeCallback(e)}}
      classNames={{control: () => "shadow-sm  sm:text-sm/6"}}  />
    );

}

export default DropdownSelect
