import { useState, useRef, useEffect } from 'react'
import axios from 'axios'
import { BiSearch } from 'react-icons/bi'
import Modal from './Modal'

function SearchBar({ onSearch }) {
  const [searchValue, setSearchValue] = useState('')
  const [dropDown, setDropDown] = useState([])
  const searchResults = useRef([null])
  const dropdownRef = useRef(null)
  const [isOpen, setIsOpen] = useState(false)
  const [selectedMentor, setSelectedMentor] = useState()
  const [pageNames, setPageNames] = useState("UserPreview")

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropDown([])
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const handleChange = async (e) => {
    const value = e.target.value
    setSearchValue(value)
    
    if (!value.trim()) {
        setDropDown([])
      onSearch([])
      return
    }
    
    try {
      const response = await axios.get('/server/userActions/searchMentors', {
        params: { query: value }
      })
      searchResults.current = response.data
      setDropDown(searchResults.current.slice(0, 5))
    } catch (error) {
      console.error('Search failed:', error)
    }
  }

  const handleViewProfile = (mentor, e) => {
    e.stopPropagation()
    setSelectedMentor(mentor.UserId)
    setIsOpen(true)
  }

  return (<>
    <div className="relative flex flex-1 my-4">
      <label htmlFor="search-field" className="sr-only">
        Search
      </label>
      <div className="relative w-full group">
        <BiSearch 
          className="pointer-events-none absolute inset-y-0 left-4 h-full w-5 text-gray-400 group-hover:text-indigo-600 transition-colors duration-200"
        />
        <input 
          id="search-field" 
          name="search" 
          type="search" 
          value={searchValue}
          onChange={handleChange}
          autoComplete='off'
          placeholder="Find your perfect mentor: skills, name, or location..." 
          className="block h-14 w-full border-1 border-gray-200 rounded-2xl py-4 pl-12 pr-4 text-gray-900 placeholder:text-gray-400 
          focus:ring-1 focus:ring-indigo-600 focus:border-indigo-500 outline-none 
          shadow-[0_4px_12px_rgba(0,0,0,0.08)] hover:shadow-[0_6px_16px_rgba(0,0,0,0.12)] 
          transition-all duration-200 ease-in-out text-base
          hover:border-indigo-300"
        />
      </div>
    </div>
    {dropDown.length > 0 && (
      <div 
        ref={dropdownRef} 
        className="absolute top-full left-0 w-full bg-white shadow-2xl rounded-xl p-3 z-10 
        border border-gray-100 backdrop-blur-sm bg-white/95 
        animate-slideDown mt-2"
      >
        <ul role="list" className="divide-y divide-gray-100">
          {dropDown.map((mentor, index) => (
            <li 
              key={index} 
              className="flex items-center justify-between gap-x-6 py-4 px-2 hover:bg-gray-50 rounded-lg cursor-pointer transition-colors duration-150" 
              onClick={() => onSearch(mentor)}
            >
              <div className="flex min-w-0 gap-x-4">
                <img 
                  alt="" 
                  src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" 
                  className="size-12 flex-none rounded-full bg-gray-50 object-cover ring-2 ring-white" 
                />
                <div className="min-w-0 flex-auto">
                  <p className="text-sm font-semibold text-gray-900">{mentor.FirstName || "N/A"}</p>
                  <p className="mt-1 truncate text-xs text-gray-500">{mentor.SkillSets || "No skills available"}</p>
                </div>
              </div>
              <div
                onClick={(e) => handleViewProfile(mentor, e)}
                className="rounded-full bg-white px-3.5 py-1.5 text-xs font-semibold text-gray-900 
                shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-indigo-50 hover:text-indigo-600 
                transition-colors duration-150"
              >
                View Profile
              </div>
            </li>
          ))}
        </ul>
      </div>
    )}

    {selectedMentor && (
      <Modal
        flow="schedule"
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        setSelectedMentor={setSelectedMentor}
        selectedMentor={selectedMentor}
        pageName={pageNames}
        setPageNames={setPageNames}
      />
    )}
  </>)
}

export default SearchBar 