import React from 'react'
import { SocialIcon } from 'react-social-icons'

const ProfileCard = (props) => {
  const prof = ["Software Developer", "Mentor", "Manager", "Freelancer"]
  const companyLogos = ["htps://facebook.com", "htps://instagram.com", "htps://x.com", "htps://whatsapp.com", "htps://medium.com"]

  const onClickProfileChange = (userId) => {
    props.setSelectedMentor(userId)
    props.setPageNames("UserPreview")
    props.setIsOpen(true)
  }

  return (
    <div className="w-full">
      <ul role="list" className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {props.mentorList.map((user) => {
          let skills = user.SkillSets.split(',')
          return (
            <div className="flex justify-center">
              <li
                key={user.UserId}
                className="w-72 divide-y divide-gray-200 rounded-lg bg-white text-center shadow hover:shadow-blue-400 cursor-pointer"
                onClick={() => onClickProfileChange(user.UserId)}
              >
                <div className="flex flex-col p-6">
                  <img 
                    className="mx-auto h-28 w-28 flex-shrink-0 rounded-full"
                    src={process.env.PUBLIC_URL + "/Assets/propic" + Math.floor(Math.random() * 10) + ".jpeg"}
                    alt=""
                  />
                  <h3 className="mt-6 text-sm font-medium text-gray-900">
                    {user.FirstName + user.LastName}
                    <span className='ml-2 bg-gray-400 px-2 py-1 rounded-2xl text-black font-bold text-xs'>
                      +{user.Experience}
                    </span>
                  </h3>
                  <dl className="mt-1 flex grow flex-col justify-between">
                    <dd className="text-sm text-gray-500">{prof[user.Profession - 1]}</dd>
                    <dd className="mt-3 flex justify-center gap-2">
                      {[...Array(4)].map((_, index) => (
                        <SocialIcon
                          key={index}
                          className='w-2 h-2 p-1'
                          style={{ height: "20px", width: "20px" }}
                          url={companyLogos[Math.floor(Math.random() * 5)]}
                        />
                      ))}
                    </dd>
                    <dd className="mt-3 flex flex-wrap justify-center gap-2">
                      {skills.slice(0, 5).map((skill, index) => (
                        <span
                          key={index}
                          className="inline-flex items-center rounded-full bg-indigo-600 px-2 py-1 text-xs text-white"
                        >
                          {skill}
                        </span>
                      ))}
                      {skills.length > 5 && (
                        <span className='px-2 py-1 text-xs bg-blue-100 rounded-full'>
                          +{skills.length - 5}
                        </span>
                      )}
                    </dd>
                  </dl>
                </div>
              </li>
            </div>
          )
        })}
      </ul>
    </div>
  )
}

export default ProfileCard