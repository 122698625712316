

const Skills = [
    {value:"Java",label:"Java"},
    {value:"Python",label:"Python"},
    {value:"Sql",label:"Sql"},
    {value:"Spring",label:"Spring"},
    {value:"Spring boot",label:"Spring boot"},
    {value:"AWS",label:"AWS"},
    {value:"ReactJs",label:"ReactJs"},
    {value:"NodeJs",label:"NodeJs"},
    {value:"MongoDB",label:"MongoDB"},
    {value:"C",label:"C"},
    {value:"C++",label:"C++"},
    {value:"Angular",label:"Angular"},
    {value:"HTML",label:"HTML"},
    {value:"CSS",label:"CSS"},
    {value:"Javascript",label:"Javascript"},
    {value:"Kotlin",label:"Kotlin"},
    {value:"AI/ML",label:"AI/ML"},
    {value:"Selinium",label:"Selinium"},
    {value:"CRM",label:"CRM"},
    {value:"HRMS",label:"HRMS"},
]

export default Skills