import React, { useState, useEffect } from 'react'
import Cookies from 'js-cookie';
import { Navigate, Outlet } from 'react-router-dom';
import { Helmet } from 'react-helmet';



const ValidateRoute = (props) => {
//     const [isAuth, setAuth] = useState(undefined)

//    useEffect(() => {
//     window.catalyst.auth.isUserAuthenticated().then((result) => {
//         debugger
//         setAuth(result)
//     }).catch((err) => {
//         setAuth("Invalid_User")
//     })w
// }, []);


  
      return (
        <>
        {props.isAuth!=undefined && props.isAuth!="" && props.isAuth!="Invalid_User"?<Outlet></Outlet>: <Navigate to="/error"></Navigate>}
        </>
      )
    };








//    const [isAuth, setAuth] = useState("")
   

// //   const isAuthenticated = async () => {
// //     debugger;
// //     //The catalyst.auth.isUserAuthenticated() method allows only authenticated users, i.e., the users who are logged in, to access the pages
// //     //You can load this method in the body of your page to allow only authenticated users to access a particular page
// // await catalyst.auth
// // .isUserAuthenticated()
// // .then((result) => {
// //   //If the user is logged in, these contents of the page will be displayed to the user
// //   debugger
// //   setAuth(result)
// //   console.log("result --->",result)
// // })
// // .catch((err) => {
// //   console.log("err --->",err)

// // });
// //       debugger
// //   } 
//   return (
//     <>
//         <Helmet>
//       <script src="/__catalyst/sdk/init.js" />
//       <script src="https://static.zohocdn.com/catalyst/sdk/js/4.0.0/catalystWebSDK.js" />
//     </Helmet>
    
//     {
        
//         isAuth==undefined || isAuth==""?
//           catalyst.auth
//          .isUserAuthenticated()
//          .then((result) => {
//            //If the user is logged in, these contents of the page will be displayed to the user
//            debugger
//            console.log("result --->",result)
//          })
//          .catch((err) => {
//            console.log("err --->",err)
   
//          }):<></>
//     }
//         {isAuth!=undefined && isAuth!=""?<Outlet></Outlet>:<Navigate to="/error"></Navigate>}
//     </>
//   )


export default ValidateRoute